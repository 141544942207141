//REACT
import { useContext, createContext, Dispatch } from "react";
import { getToken } from "../service";

interface ICustomerContext {
  globalState: IGlobalState;
  globalDispatch: Dispatch<TGlobalActions>;
}

interface IGlobalState {
  userRole: "" | "admin" | "hospital";
  hospitalName?: string;
  token: string;
  remainingViewCount?: number;
}

export type TGlobalActions =
  | {
      type: "RESET";
    }
  | {
      type: "LOGIN";
      data: any;
    }
  | {
      type: "HOSPITAL_NAME";
      data: string;
    }
  | {
      type: "AUDITION_VIEW_COUNT";
      data: number;
    };

export const globalStateInit: IGlobalState = {
  userRole: "",
  token: getToken(),
};

export function globalReducer(
  state: IGlobalState = globalStateInit,
  action: TGlobalActions,
): IGlobalState {
  switch (action.type) {
    case "RESET":
      return globalStateInit;
    case "HOSPITAL_NAME":
      return {
        ...state,
        hospitalName: action.data,
      };
    case "AUDITION_VIEW_COUNT":
      return {
        ...state,
        remainingViewCount: action.data,
      };
    case "LOGIN":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}

export const GlobalContext = createContext<ICustomerContext | undefined>(
  undefined,
);

export function useGlobalState() {
  const state = useContext(GlobalContext);
  if (!state) throw new Error("CONTEXT");
  return state;
}
