import * as React from 'react';
import { Dropdown, Menu, Modal, Space, Table, Tooltip } from 'antd';
import { getTableColumnNo } from '../../../../components/Table';
import { deleteMessage } from '../../../../api';
import MessageSendModal from '../../../../components/MessageSendModal';

export default function CastingListTable(props: any) {
  const { loading, dataSource, pagination, onRequestRefresh } = props;
  const [messageModal, setMessageModal] = React.useState({
    visible: false,
    data: {
      memberId: 0,
      name: '',
    },
  });
  const handleClickName = (item: any) => {
    setMessageModal({ visible: true, data: { memberId: item.memberId, name: item.name } });
  };
  const handleClickRequestDelete = async (item: any) => {
    try {
      Modal.confirm({
        title: '메세지',
        content: '삭제하시겠습니까?',
        async onOk() {
          await deleteMessage(item.id);
          Modal.success({
            title: '메세지',
            content: '삭제되었습니다',
            onOk() {
              onRequestRefresh();
            },
          });
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Table
        size="small"
        bordered={true}
        scroll={{ x: true }}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        rowKey="id"
        columns={[
          getTableColumnNo(pagination.total, pagination.pageSize, pagination.current),
          {
            title: '#ID',
            dataIndex: 'id',
            width: 100,
            render(value, record) {
              return (
                <Tooltip placement="topLeft" title="삭제 또는 답장하실 수 있는 메뉴가 나오십니다.">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => handleClickRequestDelete(record)}>보내기</Menu.Item>
                        <Menu.Item onClick={() => handleClickRequestDelete(record)}>삭제</Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>{value}</Space>
                    </a>
                  </Dropdown>
                </Tooltip>
              );
            },
          },
          {
            title: '등록일',
            dataIndex: 'messageDate',
            width: 160,
            align: 'center',
          },
          {
            title: '받는사람',
            dataIndex: 'name',
            width: 100,
            align: 'center',
            render(value, record) {
              return <a onClick={() => handleClickName(record)}>{value}</a>;
            },
          },
          {
            title: '내용',
            dataIndex: 'content',
          },
        ]}
      />
      <MessageSendModal
        {...messageModal}
        onRequestClose={() => setMessageModal({ ...messageModal, visible: false })}
        onFinish={() => {
          setMessageModal({ ...messageModal, visible: false });
          onRequestRefresh();
        }}
      />
    </>
  );
}
