import * as React from 'react';
import { Drawer, PageHeader, Table, Tag } from 'antd';
import { readAllHospitalHireByHospital, readAllHospitalHireResumeByHospitalWithHire } from '../../../../../api';
import moment from 'moment';
import DrawerHospitalHireResumeList from '../../../../../components/DrawerHospitalHireResumeList';

export default function DrawerHireListByHospital(props: any) {
  const { visible, data, onRequestClose } = props;
  const [drawerResumeList, setDrawerResumeList] = React.useState<any>({
    visible: false,
    dataSource: null,
  });
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any>([]);
  const handleBack = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const handleClickResumeCount = async (item: any) => {
    try {
      const res = await readAllHospitalHireResumeByHospitalWithHire(item.hospitalId, item.id);
      setDrawerResumeList({
        visible: true,
        dataSource: res,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const bootstrap = async () => {
    try {
      setLoading(true);
      const res = await readAllHospitalHireByHospital(data, { state: 'ING' });
      setItems(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible) {
      bootstrap();
    }
  }, [visible]);

  return (
    <>
      <Drawer visible={visible} getContainer={false} width={'100%'} closable={false} style={{ position: 'absolute' }}>
        <PageHeader ghost={false} onBack={handleBack} title={`진행중인 공고목록`}>
          <Table
            size="small"
            bordered={true}
            dataSource={items}
            loading={loading}
            pagination={false}
            columns={[
              {
                title: '등록일',
                dataIndex: 'createDate',
                width: 160,
                align: 'center',
              },
              {
                title: '공고기간',
                width: 80,
                align: 'center',
                render(_, record) {
                  if (record.recruitId === '2') {
                    return '상시모집';
                  } else if (record.recruitId === '3') {
                    return '채용시';
                  }

                  return null;
                },
              },
              {
                title: '시작일',
                dataIndex: 'startDate',
                key: 'startDate',
                width: 100,
                align: 'center',
                render(date, record) {
                  if (record.recruitId !== '1') {
                    return null;
                  }
                  if (date === '0000-00-00') {
                    return null;
                  } else {
                    return moment(date).format('YYYY-MM-DD');
                  }
                },
              },
              {
                title: '종료일',
                dataIndex: 'endDate',
                key: 'endDate',
                width: 100,
                align: 'center',
                render(date, record) {
                  if (record.recruitId !== '1') {
                    return null;
                  }
                  if (date === '0000-00-00') {
                    return '';
                  } else {
                    return moment(date).format('YYYY-MM-DD');
                  }
                },
              },
              {
                title: '공고제목',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: '모집직종',
                dataIndex: 'occupationNames',
                key: 'occupationNames',
                render: (value, record) => {
                  if (value === null || value === '') {
                    return null;
                  }
                  const names = value.split(',');
                  return (
                    <>
                      {names.map((name: string, idx: number) => (
                        <Tag key={`tag-${record.id}-${idx}`} color="blue">
                          {name}
                        </Tag>
                      ))}
                    </>
                  );
                },
              },
              {
                title: '경력',
                align: 'center',
                render(_, record) {
                  if (record.careerMarkId === '1') {
                    return '신입';
                  } else if (record.careerMarkId === '2') {
                    return '경력무관';
                  } else {
                    return record.career;
                  }
                },
              },
              {
                title: '연봉',
                dataIndex: 'salary',
                key: 'salary',
                render(_, record) {
                  if (record.salaryNegoId === '1') {
                    return record.salary;
                  } else if (record.salaryNegoId === '2') {
                    return '추후협의';
                  } else {
                    return null;
                  }
                },
              },
              {
                title: '광고사용여부',
                dataIndex: 'useAd',
                key: 'useAd',
                align: 'center',
                render(value: string) {
                  return value === 'Y' ? <Tag color="green">사용</Tag> : <Tag color="red">미사용</Tag>;
                },
              },
              {
                title: '게시글 프리미엄 광고 사용여부',
                dataIndex: 'useTalkPremiumAd',
                key: 'useTalkPremiumAd',
                align: 'center',
                render(value: string) {
                  return value === 'Y' ? <Tag color="green">사용</Tag> : <Tag color="red">미사용</Tag>;
                },
              },
              {
                title: '노출수',
                dataIndex: 'viewCount',
                key: 'viewCount',
                align: 'center',
              },
              {
                title: '클릭수',
                dataIndex: 'hitCount',
                key: 'hitCount',
                align: 'center',
              },
              {
                title: '받은 이력서',
                dataIndex: 'resumeCount',
                key: 'resumeCount',
                align: 'center',
                render(resumeCount: number, item) {
                  if (Number(resumeCount) > 0) {
                    return <a onClick={() => handleClickResumeCount(item)}>{resumeCount}</a>;
                  }

                  return null;
                },
              },
            ]}
          />
        </PageHeader>
      </Drawer>
      <DrawerHospitalHireResumeList {...drawerResumeList} onRequestClose={() => setDrawerResumeList({ visible: false, dataSource: null })} />
    </>
  );
}
