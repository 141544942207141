import React, { useState } from 'react';
import { Button, Drawer, PageHeader, Table, Tag } from 'antd';
import MessageSendModal from '../../../../components/MessageSendModal';

export default function DrawerMessageList(props: any) {
  const { visible, data, dataList, onRequestClose } = props;
  const [messageModal , setMessageModal] = useState({
    visible : false , 
    data : {
      memberId : 0 , 
      name : ''
    }
  });
  const handleBack = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const handleShowMessageSendModal = () => {
    setMessageModal({
      visible : true , 
      data : data
    })
  }
  return (
    <>
    <Drawer visible={visible} getContainer={false} width={'100%'} closable={false} style={{ position: 'absolute' }}>
      <PageHeader ghost={false} onBack={handleBack} title={`쪽지 내역`}extra={[
        <Button key="1" type="primary" onClick={handleShowMessageSendModal}>
          보내기
        </Button>,
      ]}>
        <Table
          size="small"
          bordered={true}
          dataSource={dataList}
          pagination={false}
          rowKey='id'
          columns={[
            {
              title: '날짜',
              dataIndex: 'messageDate',
              width : 150
            },
            {
              title: '형식',
              dataIndex: 'typeId',
              width : 80,
              render(_ , record: any){
                if(record.typeId === 1){
                  return <Tag color='success'>받음</Tag>;
                }else if(record.typeId === 2){
                  return <Tag color='blue'>보냄</Tag>;
                }
                return '';
              }
            },
            {
              title: '이름',
              dataIndex: 'name',
              width : 100
            },
            {
              title: '내용',
              dataIndex: 'content',
            }
          ]}
        />
      </PageHeader>
    </Drawer>
    <MessageSendModal {...messageModal} onRequestClose={() => setMessageModal({...messageModal , visible : false})} onFinish={() => {}}/>
    </>
  );
}
