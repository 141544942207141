import instance from '../../service/api';

export const readAllScout = (params: any) => instance.get('/auditions', { params });
export const readHospitalAuditionViewCount = (): Promise<any> => instance.get('/hospitals/audition-view-count');
export const readHospital = () => instance.get('/hospitals');
export const updateHospital = (data: any) => instance.put('/hospitals', data);
export const updateHospitalHireActiveByPK = (value: any, id: number) => instance.patch(`/hospitals/hires/${id}/isActive/${value}`);

export const readAllMessageReceive = (params: any) => instance.get('/hospitals/messages/receive', { params });
export const readAllMessagesend = (params: any) => instance.get('/hospitals/messages/send', { params });
export const sendMessage = (data: any) => instance.post('/hospitals/messages', data);
export const readAllHospitalMessageByMember = (memberId: number) => instance.get('/members/' + memberId + '/messages');

export const createOffer = (data: any, memberId: number) => instance.post(`/members/${memberId}/resume/offer`, data);
export const deleteMessage = (id: number) => instance.delete(`/hospitals/messages/${id}`);
export const selectAllMessageByMember = (memberId: number) => instance.get(`/hospitals/messages/member/${memberId}`);
