import * as React from 'react';
import { Space, DatePicker, Input, Button, Form } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';

const { RangePicker } = DatePicker;

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
  onRequestCreate: () => void;
}
export default function HospitalUserListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch, onRequestCreate } = props;
  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };
  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          hospitalName: '',
          name: '',
          email: '',
          dates: ['', ''],
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="dates">
          <RangePicker locale={locale} allowClear />
        </Form.Item>
        <Form.Item name="hospitalName">
          <Input placeholder="병원명" allowClear />
        </Form.Item>
        <Form.Item name="name">
          <Input placeholder="이름" allowClear />
        </Form.Item>
        <Form.Item name="email">
          <Input placeholder="계정명" allowClear />
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={onRequestCreate}>
            계정생성
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
