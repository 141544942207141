import * as React from 'react';
import Routes from './routes';
import { Layout } from './components';
import { GlobalContext, globalReducer, globalStateInit } from './contexts';
import 'antd/dist/antd.css';
import './app.css';

export default function App() {
  const [state, dispatch] = React.useReducer(globalReducer, globalStateInit);
  return (
    <GlobalContext.Provider value={{ globalState: state, globalDispatch: dispatch }}>
      <Layout>
        <Routes />
      </Layout>
    </GlobalContext.Provider>
  );
}
