//CORE
import React from "react";

//STYLE
import styled from "styled-components";

//COMPONENTS
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Form, Input, Checkbox, Button, message } from "antd";

//ASSETS
import bg from "../../../assets/images/login_background.jpeg";
import logo from "../../../assets/images/white_logo.png";

//HTTP
import { useMutation } from "react-query";
import { login } from "../../../api";

//TYPE
import type {
  ILoginPayload,
  ILoginResponse,
  IJWT,
} from "../../../service/type";
import axios, { AxiosError } from "axios";
import { STORAGE_KEYS } from "../../../service";

//HOOKS
import { useNavigate } from "react-router";

//HELPER
import jwt_decode from "jwt-decode";

//STATE
import { useGlobalState } from "../../../contexts";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { globalDispatch: dispatch } = useGlobalState();

  const { mutate } = useMutation<ILoginResponse, AxiosError, ILoginPayload>(
    (payload) => login(payload),
  );

  const handleSubmit = (data: ILoginPayload) => {
    mutate(data, {
      async onSuccess({ token }, { remember }) {
        localStorage.clear();
        sessionStorage.clear();
        if (remember) {
          localStorage.setItem(STORAGE_KEYS.TOKEN, token);
        } else {
          sessionStorage.setItem(STORAGE_KEYS.TOKEN, token);
        }

        axios.defaults.headers.common["Authorization"] = `bearer ${token}`;

        const { role }: IJWT = jwt_decode(token);
        dispatch({
          type: "LOGIN",
          data: {
            token,
            userRole: role,
          },
        });

        navigate("/");
      },
      onError(error) {
        message.warn(error.message);
      },
    });
  };

  return (
    <Contaienr>
      <LoginWrap>
        <Title>
          <Logo src={logo} alt="언니네병원 로고" title="언니네병원" />
        </Title>
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "아이디를 입력하세요." }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="아이디"
            />
          </Form.Item>

          <Form.Item
            name="passwd"
            rules={[{ required: true, message: "비밀번호를 입력하세요" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="비밀번호"
            />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{ span: 16 }}
          >
            <Checkbox style={{ color: "#fff" }}>로그인 유지하기</Checkbox>
          </Form.Item>
          <Form.Item>
            <SubmitButton type="primary" htmlType="submit">
              로그인
            </SubmitButton>
          </Form.Item>
        </Form>
      </LoginWrap>
    </Contaienr>
  );
};

export default Login;

const Contaienr = styled.section`
  height: 100vh;
  background-image: url(${bg});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginWrap = styled.div`
  padding: 50px;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

const Title = styled.h1`
  margin-bottom: 50px;
`;

const SubmitButton = styled(Button)`
  height: 50px;
  width: 100%;
  border-radius: 4px;
`;

const Logo = styled.img`
  width: 200px;
`;
