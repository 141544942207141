import * as React from 'react';
import { Form } from 'antd';
import moment from 'moment';
import Page from '../../../components/Page';
import HospitalUserListTable from './HospitalUserListTable';
import HospitalUserListForm from './HospitalUserListForm';
import HospitalUserFormModal from './HospitalUserFormModal';
import { readAllHospitalUser } from '../../../api';
import HospitalUserPasswordFormModal from './HospitalUserPasswordFormModal';

export default function HospitalUserListPage() {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<any>({
    page: 1,
    total: 0,
    items: [],
  });

  const [hospitalFormModal, setHospitalFormModal] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [passwordFormModal, setPasswordFormModal] = React.useState<any>({
    visible: false,
    data: {
      hospitalId: 0,
      id: 0,
    },
  });
  const onSearch = async (nextPage: number) => {
    try {
      setLoading(true);
      const nextFormValues = form.getFieldsValue();
      const startDate = moment(nextFormValues.dates[0]).format('YYYY-MM-DD');
      const endDate = moment(nextFormValues.dates[1]).format('YYYY-MM-DD');
      delete nextFormValues.dates;
      const nextResponse: any = await readAllHospitalUser({
        ...nextFormValues,
        startDate: startDate === 'Invalid date' ? '' : startDate,
        endDate: endDate === 'Invalid date' ? '' : endDate,
        page: nextPage,
      });
      setResponse({
        page: nextPage,
        total: nextResponse.total,
        items: nextResponse.items,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    onSearch(1);
  }, []);
  return (
    <Page title="병원계정관리" subTitle="병원계정관리 리스트">
      <HospitalUserListForm form={form} searchLoading={loading} onRequestSearch={() => onSearch(response.page)} onRequestCreate={() => setHospitalFormModal({ visible: true, data: { hospitalId: 0, id: 0 } })} />
      <HospitalUserListTable
        loading={loading}
        dataSource={response.items}
        pagination={{
          position: ['bottomCenter'],
          current: response.page,
          total: response.total,
          pageSize: 20,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: onSearch,
        }}
        onRequestRefresh={() => onSearch(response.page)}
        onRequestUpdate={(item: any) => setHospitalFormModal({ visible: true, data: { hospitalId: item.hospitalId, id: item.id } })}
        handleClickRequestChangePasswod={(item: any) => setPasswordFormModal({ visible: true, data: { hospitalId: item.hospitalId, id: item.id } })}
      />
      <HospitalUserFormModal {...hospitalFormModal} onRequestClose={() => setHospitalFormModal({ visible: false, data: { hospitalId: 0, id: 0 } })} onFinish={() => onSearch(response.page)} />
      <HospitalUserPasswordFormModal {...passwordFormModal} onRequestClose={() => setPasswordFormModal({ visible: false, data: { hospitalId: 0, id: 0 } })} onFinish={() => onSearch(response.page)} />
    </Page>
  );
}
