//CORE
import React, { useState, useEffect, useReducer } from 'react';

//COMPONENTS
import { Radio, DatePicker, Space, Tag, Select, Slider, Button, Table, PageHeader, Checkbox, Pagination, RadioChangeEvent, Modal } from 'antd';
import AuditionDetailModal from './AuditionDetailModal';
//API
import { useQuery } from 'react-query';
import { getAudtionList, readAllHospitalHireOccupation } from '../../../api';

//STYLES
import styled from 'styled-components';

//TYPE
import { ColumnsType } from 'antd/lib/table';
import type { IAudition, IAuditionParams, IAuditionConditionItem, IAuditionRangeComponent } from '../../../service/type';
import { careerDateFormat } from '../../../service';

//HELPER
import _ from 'lodash';
import Page from '../../../components/Page';

type TRange = {
  [key: string | number]: string;
};
type TRangeComponent = {
  min: number;
  max: number;
  range: TRange;
};

type TModal = {
  id: number;
  visible: boolean;
};
const { Option } = Select;
const { CheckableTag } = Tag;

const ConditionItem: React.FC<IAuditionConditionItem> = ({ label, item }) => {
  return (
    <div>
      <ConditionLabel>{label}</ConditionLabel>
      <ConditionItemWrap>{item}</ConditionItemWrap>
    </div>
  );
};

const Audition: React.FC = () => {
  const initialParams: IAuditionParams = {
    page: 1,
    careerTypeId: 1,
    salaryNegoId: 1,
  };

  const paramsReducer = (state: IAuditionParams, data: object): IAuditionParams => ({
    ...state,
    ...data,
  });
  const [params, setParams] = useReducer(paramsReducer, initialParams);
  const [modal, setModal] = useState<TModal>({
    visible: false,
    id: -1,
  });
  const [hireCategories, setHireCategories] = useState<{ label: string; value: number }[]>([]);
  const [range] = useState<IAuditionRangeComponent<TRangeComponent, TRangeComponent>>({
    salary: {
      min: 1000,
      max: 10000,
      range: _.range(10).reduce((acc, num) => {
        const item = {
          [(num + 1) * 1000]: `${(num + 1) * 1000}만원`,
        };
        acc = {
          ...acc,
          ...item,
        };
        return acc;
      }, {}),
    },
    career: {
      min: 1,
      max: 10,
      range: _.range(10).reduce((acc, num) => {
        const item = {
          [num + 1]: `${num + 1}년`,
        };
        acc = {
          ...acc,
          ...item,
        };
        return acc;
      }, {}),
    },
  });
  const { data, isLoading, refetch } = useQuery(['getAudtionList', params.page], () => getAudtionList(params), {
    retryDelay: 1000,
    //enabled: fetchEnable,
  });

  const columns: ColumnsType<IAudition> = [
    {
      title: '번호',
      dataIndex: 'num',
      key: 'num',
      render: (_, item: IAudition, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: '이름',
      dataIndex: 'nickName',
      key: 'nickName',
    },
    {
      title: '경력',
      dataIndex: 'careerDay',
      key: 'careerDay',
      render: (_, item: IAudition) => {
        return <span>{careerDateFormat(item.careerDay)}</span>;
      },
    },
    {
      title: '희망연봉',
      dataIndex: 'hopeSalary',
      key: 'hopeSalary',
      render: (_, item: IAudition) => {
        return <span>{item.hopeSalary + '만원'}</span>;
      },
    },
    {
      title: '재직유무',
      dataIndex: 'jobStatusName',
      key: 'jobStatusName',
    },
    {
      title: '관심직종',
      dataIndex: 'departmentNames',
      key: 'departmentNames',
      render: (departmentNames: string, item: IAudition) => {
        const names = departmentNames.split(',');
        return (
          <>
            {names.map((name: string, idx: number) => (
              <Tag key={`tag-${item.id}-${idx}`} color="blue">
                {name}
              </Tag>
            ))}
          </>
        );
      },
    },
    {
      title: '지원서 열람',
      dataIndex: 'auditionViewCheck',
      key: 'auditionViewCheck',
      render: (check: 'Y' | 'N', item: IAudition) => {
        return (
          <Button type={check === 'N' ? 'primary' : 'default'} onClick={() => handleReadResume(check, item)}>
            {check === 'Y' ? '다시보기' : '상세보기'}
          </Button>
        );
      },
    },
    {
      title: '열람가능 기간',
      dataIndex: 'auditionViewRemainingPeriod',
      key: 'auditionViewRemainingPeriod',
      render: (period: number, item: IAudition) => {
        if (period) {
          return `D-${period}`;
        }
      },
    },
  ];

  const handleChangeRadio = (e: RadioChangeEvent) => {
    let {
      target: { value, name },
    } = e;

    name = name as string;

    setParams({
      [name]: value,
    });
  };

  const handleReadResume = (check: 'Y' | 'N', item: IAudition) => {
    if (check === 'N') {
      Modal.confirm({
        title: '열람권을 사용하여 열람하시겠습니까?',
        onOk: () =>
          setModal({
            visible: true,
            id: item.id,
          }),
      });
    } else {
      setModal({
        visible: true,
        id: item.id,
      });
    }
  };
  const handleSearch = () => {
    setParams({
      page: 1,
    });
    refetch();
  };

  useEffect(() => {
    (async () => {
      const response = await readAllHospitalHireOccupation();
      setHireCategories(response.map((item) => ({ label: item.name, value: item.id })));
    })();
  }, []);

  return (
    <>
      <Page title="스카웃" subTitle="스카웃 리스트">
        <ConditionSpace style={{ width: '100%', marginBottom: 15 }} align="start">
          <ConditionItem
            label="모집직종"
            item={
              <Checkbox.Group
                options={hireCategories}
                onChange={(checked) =>
                  setParams({
                    occupationIds: checked.join(','),
                  })
                }
              />
            }
          />
          <ConditionItem
            label="경력여부"
            item={
              <>
                <Slider
                  style={{ width: '300px' }}
                  range
                  min={range.career.min}
                  max={range.career.max}
                  step={1}
                  defaultValue={[range.career.min, range.career.max]}
                  tipFormatter={(value): string => (value && +value < 10 ? `${value}년` : '10년 이상')}
                  disabled={params.careerTypeId !== 1}
                />
                <Radio.Group name="careerTypeId" style={{ display: 'flex' }} onChange={handleChangeRadio} value={params.careerTypeId}>
                  <Radio value={1} checked={params.careerTypeId === 1}>
                    날짜 선택
                  </Radio>
                  <Radio value={2} checked={params.careerTypeId === 2}>
                    신입
                  </Radio>
                  <Radio value={3} checked={params.careerTypeId === 3}>
                    경력무관
                  </Radio>
                </Radio.Group>
              </>
            }
          />
          <ConditionItem
            label="연봉"
            item={
              <>
                <Slider
                  style={{ width: '300px' }}
                  range
                  min={range.salary.min}
                  max={range.salary.max}
                  defaultValue={[range.salary.min, range.salary.max]}
                  disabled={params.salaryNegoId !== 1}
                  tipFormatter={(value): string => (value && +value < 10000 ? `${value}만원` : '1억')}
                  step={1000}
                />
                <Radio.Group name="salaryNegoId" style={{ display: 'flex' }} onChange={handleChangeRadio} value={params.salaryNegoId}>
                  <Radio value={1} checked={params.salaryNegoId === 1}>
                    연봉선택
                  </Radio>
                  <Radio value={2} checked={params.salaryNegoId === 2}>
                    추후 협의
                  </Radio>
                </Radio.Group>
              </>
            }
          />
          <ConditionItem
            label="열람"
            item={
              <Select
                placeholder="열람여부"
                style={{ width: 120 }}
                onChange={(isAuditionOpen) => {
                  setParams({
                    isAuditionOpen,
                  });
                }}
              >
                <Option value="">전체</Option>
                <Option value="N">미열람</Option>
                <Option value="Y">열람</Option>
              </Select>
            }
          />
          <Button type="primary" onClick={handleSearch} style={{ alignSelf: 'center' }}>
            검색
          </Button>
        </ConditionSpace>
        <Space style={{ display: 'block' }}>
          <Table dataSource={data?.items} columns={columns} style={{ width: '100%' }} pagination={false} rowKey={(audition) => `audition-item-${audition.id}`} />
          <Pagination
            current={params.page}
            defaultCurrent={1}
            total={data?.total}
            pageSize={20}
            onChange={(page) =>
              setParams({
                page,
              })
            }
            hideOnSinglePage
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: 20,
            }}
          />
        </Space>
      </Page>
      <AuditionDetailModal {...modal} onClose={() => setModal({ visible: false, id: -1 })} />
    </>
  );
};

const ConditionLabel = styled.span`
  font-size: 14px;
  color: #999;
`;

const ConditionItemWrap = styled.div`
  paddingtop: 10px;
`;

const ConditionSpace = styled(Space)`
  .ant-space-item {
    &:nth-last-of-type(1) {
      display: flex;
      align-self: stretch;
      align-items: center;
    }
  }
`;
export default Audition;
