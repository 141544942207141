import * as React from 'react';
import { Modal, Table, Tag } from 'antd';
import { getTableColumnNo } from '../../../../components/Table';
import DrawerHireList from './DrawerHireList';
import { readAllMemberResumeCareer } from '../../../../api';

export default function MemberResumeListTable(props: any) {
  const { loading, dataSource, pagination } = props;
  const [drawerHireList, setDrawerHireList] = React.useState({
    visible: false,
    data: null,
  });

  const handleClickCareer = async (item: any) => {
    try {
      const res: any = await readAllMemberResumeCareer(item.id);
      Modal.info({
        width: 600,
        title: `${item.resumeName}님의 경력`,
        content: (
          <Table
            size="small"
            dataSource={res}
            pagination={false}
            columns={[
              {
                title: '입사일',
                dataIndex: 'startDate',
              },
              {
                title: '퇴사일',
                dataIndex: 'endDate',
              },
              {
                title: '회사명',
                dataIndex: 'companyName',
              },
              {
                title: '업무',
                dataIndex: 'task',
              },
              {
                title: '연봉',
                dataIndex: 'salary',
              },
            ]}
          />
        ),
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div style={{ width: '100%' }}>
      <Table
        size="small"
        bordered={true}
        scroll={{ x: true }}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        rowKey="id"
        columns={[
          getTableColumnNo(pagination.total, 20, pagination.current),
          {
            title: '이름',
            dataIndex: 'name',
            ellipsis: true,
          },
          {
            title: '연락처',
            dataIndex: 'phone',
            ellipsis: true,
          },
          {
            title: '생년월일',
            dataIndex: 'birthday',
            width: 100,
            align: 'center',
          },
          {
            title: '구직/재직',
            dataIndex: 'jobStatusId',
            width: 80,
            align: 'center',
            render(value) {
              if (value === 1) {
                return '구직중';
              } else if (value === 2) {
                return '재직중';
              } else {
                return null;
              }
            },
          },
          {
            title: '희망연봉',
            dataIndex: 'hopeSalary',
            render(_, record) {
              if (record.salaryNegoId === 1) {
                return record.hopeSalary + '만원';
              } else if (record.salaryNegoId === 2) {
                return '추후협의';
              } else {
                return null;
              }
            },
          },
          {
            title: '경력',
            dataIndex: 'careerDay',
            render(value, record) {
              let str = '';
              if (Number(value) > 0) {
                let careerMonth = Number(value) / 30;
                if (careerMonth / 12 >= 1) {
                  str += `${(Number(careerMonth) / 12).toFixed(0)}년`;
                }

                if (careerMonth % 12 >= 1) {
                  str += `${(Number(careerMonth) % 12).toFixed(0)}개월`;
                }
              } else {
                return '';
              }
              return <a onClick={() => handleClickCareer(record)}>{str}</a>;
            },
          },
          {
            title: '자기소개',
            dataIndex: 'introduce',
            width: 100,

            render(value) {
              return (
                <a
                  onClick={() => {
                    Modal.info({
                      title: '자기소개',
                      content: value,
                    });
                  }}
                >
                  보기
                </a>
              );
            },
          },
          {
            title: '스카웃',
            dataIndex: 'useAudition',
            render(value) {
              if (value === 'Y') {
                return <Tag color="success">사용</Tag>;
              } else if (value === 'N') {
                return <Tag color="red">미사용</Tag>;
              } else {
                return null;
              }
            },
          },
          {
            title: '지원과',
            dataIndex: 'departmentNames',
            ellipsis: true,
            render(value, record) {
              if (record.useAudition === 'N') {
                return null;
              }

              return value;
            },
          },
          {
            title: '모집직종',
            dataIndex: 'occupationNames',
            ellipsis: true,
            render(value, record) {
              if (record.useAudition === 'N') {
                return null;
              }

              return value;
            },
          },
          {
            title: '구직 지원 수',
            dataIndex: 'hireApplyCount',
            key: 'hireApplyCount',
            render(value, record) {
              return <a onClick={() => setDrawerHireList({ visible: true, data: record.id })}>{value}</a>;
            },
          },
        ]}
      />
      <DrawerHireList {...drawerHireList} onRequestClose={() => setDrawerHireList({ visible: false, data: null })} />
    </div>
  );
}
