import * as React from 'react';
import { Button, Drawer, Modal, PageHeader, Table } from 'antd';
import { readAllYoutubeReportByYoutubeWithComment, updateYoutubeCommentStatus } from '../../api';

export default function DrawerReportListByYoutubeWithComment(props: any) {
  const { visible, data, onRequestClose } = props;
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any>([]);
  const handleBack = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const handleClickBlind = async () => {
    try {
      await updateYoutubeCommentStatus(data.youtubeId, data.commentId, '1');
      handleBack();
    } catch (e) {
      console.log(e);
    }
  };
  const bootstrap = async () => {
    try {
      setLoading(true);
      const res = await readAllYoutubeReportByYoutubeWithComment(data.youtubeId, data.commentId);
      setItems(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible) {
      bootstrap();
    }
  }, [visible]);

  return (
    <Drawer visible={visible} getContainer={false} width={'100%'} closable={false} style={{ position: 'absolute' }}>
      <PageHeader
        ghost={false}
        onBack={handleBack}
        title={`게시글의 신고 목록`}
        extra={[
          <Button key="1" danger onClick={handleClickBlind}>
            블라인드
          </Button>,
        ]}
      >
        <Table
          size="small"
          bordered={true}
          dataSource={items}
          loading={loading}
          pagination={false}
          columns={[
            {
              title: '신고일자',
              dataIndex: 'reportDate',
            },
            {
              title: '신고자',
              dataIndex: 'nickName',
            },
            {
              title: '신고사유',
              dataIndex: 'reasonName',
            },
            {
              title: '내용',
              dataIndex: 'contents',
              ellipsis: true,
              width: 200,
              render(value, record) {
                return (
                  <a
                    onClick={() => {
                      Modal.info({
                        title: record.reasonName,
                        content: value,
                      });
                    }}
                  >
                    {value}
                  </a>
                );
              },
            },
          ]}
        />
      </PageHeader>
    </Drawer>
  );
}
