import * as React from 'react';
import { Drawer, PageHeader, Table, Tag } from 'antd';
import { readAllCompanyAdByCompany } from '../../../../../api';

export default function DrawerAdListByCompany(props: any) {
  const { visible, data, onRequestClose } = props;

  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any>([]);
  const handleBack = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const bootstrap = async () => {
    try {
      setLoading(true);
      const res = await readAllCompanyAdByCompany(data);
      setItems(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible) {
      bootstrap();
    }
  }, [visible]);

  return (
    <Drawer visible={visible} getContainer={false} width={'100%'} closable={false} style={{ position: 'absolute' }}>
      <PageHeader ghost={false} onBack={handleBack} title={`광고목록`}>
        <Table
          size="small"
          bordered={true}
          dataSource={items}
          loading={loading}
          pagination={false}
          columns={[
            {
              title: '등록일',
              dataIndex: 'createDate',
            },
            {
              title: '상태',
              dataIndex: 'isActive',
              key: 'isActive',
              render(value) {
                if (value === 'Y') {
                  return <Tag color={'success'}>노출</Tag>;
                } else {
                  return <Tag color="red">비노출</Tag>;
                }
              },
            },
            {
              title: '시작일',
              dataIndex: 'startDateTime',
              key: 'startDateTime',
            },
            {
              title: '종료일',
              dataIndex: 'endDateTime',
              key: 'endDateTime',
            },
            {
              title: '광고제목',
              dataIndex: 'title',
              key: 'title',
            },
            {
              title: '노출위치',
              dataIndex: 'positionName',
              key: 'positionName',
            },
            {
              title: '노출수',
              dataIndex: 'viewCount',
              key: 'viewCount',
            },
            {
              title: '클릭수',
              dataIndex: 'hitCount',
              key: 'hitCount',
            },
            {
              title: '클릭율',
              dataIndex: 'clickPercentage',
              key: 'clickPercentage',
              render: (_, item) => {
                let { viewCount, hitCount } = item;
                const _viewCount = Number(viewCount);
                const _hitCount = Number(hitCount);

                if (_hitCount === 0) {
                  return '0%';
                } else {
                  return `${((_hitCount / _viewCount) * 100).toFixed(2)}%`;
                }
              },
            },
          ]}
        />
      </PageHeader>
    </Drawer>
  );
}
