import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tag, Descriptions, Table, Typography, Modal, Button, Input, InputNumber, Alert } from 'antd';
import { createOffer, getAuditionDetail } from '../../../../api';

import { ModalInputItem as InputItem } from '../../../../components';
import MessageSendModal from '../../../../components/MessageSendModal';

const { Title } = Typography;
const getJobStatusName = (value: number) => {
  if (value == 1) {
    return '재직중';
  } else {
    return '구직중';
  }
};

const ResumeViewModal: React.FC<any> = ({ visible, onClose, id }) => {
  const [messageModal, setMessageModal] = useState<any>({
    visible: false,
    data: {
      memberId: 0,
      name: '',
    },
  });
  const [item, setItem] = useState({
    resume: {
      name: '',
      phone: '',
      hopeSalary: '',
      introduce: '',
      jobStatusId: 0,
    },
    careers: [],
    departments: [],
    occupations: [],
    offer: {
      interviewFee: '',
      salaryFee: '',
      comment: '',
    },
  });

  // const { data, isLoading, refetch } = useQuery<any, TError>(['getAuditionDetail', id], () => getAuditionDetail(id), {
  //   enabled: visible,
  //   retry: 0,
  //   onSuccess: async () => {
  //     const { auditionViewCount } = await readHospitalAuditionViewCount();
  //     queryClient.invalidateQueries('getAuditionList');
  //     dispatch({
  //       type: 'AUDITION_VIEW_COUNT',
  //       data: auditionViewCount,
  //     });
  //   },
  //   onError: (error) => {
  //     const { statusCode } = error;
  //     if (statusCode === 403) {
  //       message.warn('사용 가능한 열람권이 없어요.');
  //       onClose();
  //     }
  //   },
  // });
  const handleClickOffer = async () => {
    try {
      if (!item.offer.interviewFee || Number(item.offer.interviewFee) < 1) {
        Modal.warning({
          title: '제안하기',
          content: '면접비용을 입력해주세요',
        });
        return;
      }

      if (!item.offer.salaryFee || Number(item.offer.salaryFee) < 1) {
        Modal.warning({
          title: '제안하기',
          content: '연봉제안을 입력해주세요',
        });
        return;
      }

      if (!item.offer.comment || item.offer.comment === '') {
        Modal.warning({
          title: '제안하기',
          content: '3줄제안내용을 입력해주세요',
        });
        return;
      }

      const res = await createOffer(
        {
          interviewFee: item.offer.interviewFee,
          salaryFee: item.offer.salaryFee,
          comment: item.offer.comment.trim(),
        },
        id,
      );
      Modal.success({
        title: '제안하기 완료',
        content: '회원에게 제안사항을 전송하였습니다',
      });
    } catch (e: any) {
      Modal.error({
        title: '제안하기 실패',
        content: e.message,
      });
      console.log(e);
    }
  };
  const onMessageSend = () => {
    setMessageModal({ visible: true, data: { memberId: id, name: item.resume.name } });
  };

  const handleMessageFinish = () => {};
  const bootstrap = async () => {
    try {
      const res = await getAuditionDetail(id);
      setItem(res);
    } catch (e: any) {
      onClose();
      Modal.error({
        title: '이력서보기',
        content: e.message,
      });
      console.log(e);
    }
  };

  useEffect(() => {
    if (visible) {
      bootstrap();
    }
  }, [visible]);

  const { resume, careers, occupations, departments, offer } = item;

  return (
    <Modal
      visible={visible}
      closable={false}
      destroyOnClose={true}
      width={800}
      footer={[
        <Button key="message" type="primary" onClick={handleClickOffer}>
          제안하기
        </Button>,
        <Button key="message" onClick={onMessageSend}>
          쪽지
        </Button>,
        <Button key="back" onClick={onClose}>
          닫기
        </Button>,
      ]}
    >
      <Title level={5}>인적 사항</Title>
      <Descriptions size={'small'} column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} bordered>
        <Descriptions.Item label="이름">{resume.name}</Descriptions.Item>
        <Descriptions.Item label="연락처">{resume.phone}</Descriptions.Item>
        <Descriptions.Item label="관심직종">
          {occupations.map((item: any) => (
            <Tag color="blue" key={`detail-category-${item.id}-${item.occupation.name}`}>
              {item.occupation.name}
            </Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="진료과목">
          {departments.map((item: any) => (
            <Tag color="magenta" key={item.id}>
              {item.department.name}
            </Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="희망연봉">{resume.hopeSalary}</Descriptions.Item>
        <Descriptions.Item label="재직유무">{getJobStatusName(resume.jobStatusId)}</Descriptions.Item>
        <Descriptions.Item label="자기소개" span={4}>
          {resume.introduce}
        </Descriptions.Item>
      </Descriptions>
      <Title level={5}>경력 사항</Title>
      <Table
        size={'small'}
        bordered
        dataSource={careers}
        columns={[
          {
            title: '회사명',
            dataIndex: 'companyName',
          },
          {
            title: '재직기간',
            render(_, record: any) {
              return record.startDate + '~' + record.endDate;
            },
          },
          {
            title: '주요직무',
            dataIndex: 'task',
          },
          {
            title: '연봉',
            dataIndex: 'salary',
          },
        ]}
      />
      <Title level={5}>제안 정보</Title>
      <Descriptions size={'small'} column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} bordered>
        <Descriptions.Item label="면접비 제안">
          <InputNumber
            controls={false}
            addonAfter={'만원'}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            // parser={value => value!.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setItem({ ...item, offer: { ...item.offer, interviewFee: value } })}
            value={offer.interviewFee}
            style={{ width: '100%' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="연봉 제안">
          <InputNumber
            controls={false}
            addonAfter={'만원'}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            // parser={value => value!.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => setItem({ ...item, offer: { ...item.offer, salaryFee: value } })}
            value={offer.salaryFee}
            style={{ width: '100%' }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="3줄 제안 내용">
          <Input.TextArea rows={3} bordered={false} value={offer.comment} onChange={(e) => setItem({ ...item, offer: { ...item.offer, comment: e.target.value } })} />
        </Descriptions.Item>
      </Descriptions>
      <MessageSendModal {...messageModal} onRequestClose={() => setMessageModal({ ...messageModal, visible: false })} onFinish={handleMessageFinish} />
    </Modal>
  );
};

const CompanyName = styled.h3`
  font-size: 17px;
  margin-right: 10px;
`;
const Career = styled.span`
  font-size: 13px;
  color: #777;
  letter-spacing: -1px;
`;
export default ResumeViewModal;
