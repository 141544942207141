//CORE
import React, { useState } from 'react';

import { Input, Modal, Table, message } from 'antd';

//API
import { readAllHospital } from '../../../../../api';

const { Search } = Input;

export default function SelectHospitalModal({ visible, onSelect, onClose }: any) {
  const [data, setData] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const handleOk = () => {
    if (selectedRowKeys.length < 1) {
      message.error('병원을 선택해주세요');
      return;
    }

    onSelect(data[data.findIndex((v: any) => v.id === selectedRowKeys[0])]);
  };
  const handleSearch = async (keyword: string) => {
    if (keyword.trim().length >= 1) {
      try {
        const response = await readAllHospital({
          name: keyword,
        });

        setData(response);
      } catch (e) {
        console.log('SEARCH ERROR', e);
      }
    }
  };

  return (
    <Modal visible={visible} onCancel={onClose} title="병원검색" width={686} onOk={handleOk} okText="선택완료" cancelText="닫기">
      <Search placeholder="병원명" onSearch={handleSearch} enterButton />
      <Table
        onRow={(record) => {
          return {
            onClick: () => setSelectedRowKeys([record.id]),
          };
        }}
        rowSelection={{
          selections: true,
          type: 'radio',
          selectedRowKeys: selectedRowKeys,
          onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
        pagination={{
          position: ['bottomCenter'],
        }}
        columns={[
          {
            title: '이름',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '지하철',
            dataIndex: 'subway',
            key: 'subway',
          },
          {
            title: '주소',
            dataIndex: 'address',
            key: 'address',
            render(_, item) {
              return `${item.addressSidoName} ${item.addressSgguName} ${item.addressEmdongName} ${item.addressDetail}`;
            },
          },
        ]}
        dataSource={data}
        rowKey={'id'}
      />
    </Modal>
  );
}
