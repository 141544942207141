import React, { useEffect } from 'react';
import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { readAllAddressSidoSgguEmdongBySidoWithSggu, readAllAddressSidoSgguBySido, readAllAddressSido, readAllHospitalDepartment, readAllSubway } from '../../../../../api';

const { Option } = Select;

interface IProps {
  form: FormInstance;
  dataSource: any;
}

export default function HospitalForm(props: IProps) {
  const { form, dataSource } = props;
  const [isFormReady, formReady] = React.useState(false);
  const [fields, setFields] = React.useState<any>({
    subway: [],
    department: [],
    sido: [],
    sggu: [],
    emdong: [],
  });

  const handleChangeSido = async (sido: number) => {
    try {
      const sggu = await readAllAddressSidoSgguBySido(sido);
      setFields({
        ...fields,
        sggu,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handleChangeSggu = async (sggu: number) => {
    try {
      const emdong = await readAllAddressSidoSgguEmdongBySidoWithSggu(form.getFieldsValue().sidoId, sggu);
      setFields({
        ...fields,
        emdong,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const bootstrap = async () => {
      let newDepartmentIds: any = [];
      if (dataSource.departments) {
        newDepartmentIds = dataSource.departments.map((item: any) => {
          return item.id.toString();
        });
      }
      try {
        let sggu: any = [];
        let emdong: any = [];
        if (Number(dataSource.sidoId) > 0) {
          sggu = await readAllAddressSidoSgguBySido(dataSource.sidoId);
          if (Number(dataSource.sgguId) > 0) {
            emdong = await readAllAddressSidoSgguEmdongBySidoWithSggu(dataSource.sidoId, dataSource.sgguId);
          }
        }
        setFields({ ...fields, sggu, emdong });
      } catch (e) {
        console.log(e);
      } finally {
        form.setFieldsValue({
          name: dataSource.name,
          tel: dataSource.tel,
          departmentIds: newDepartmentIds,
          subwayId: dataSource.subwayId,
          sidoId: dataSource.sidoId,
          sgguId: dataSource.sgguId,
          addressId: dataSource.emdongId,
          addressDetail: dataSource.addressDetail,
        });
      }
    };
    if (isFormReady) {
      bootstrap();
    }
  }, [isFormReady, dataSource]);
  useEffect(() => {
    const bootstrap = async () => {
      try {
        formReady(false);
        const subway = await readAllSubway();
        const department = await readAllHospitalDepartment();
        const sido = await readAllAddressSido();
        setFields({ subway, department, sido, sggu: [], emdong: [] });
      } catch (e) {
        console.log(e);
      } finally {
        formReady(true);
      }
    };

    bootstrap();
  }, []);

  return (
    <Form form={form} layout="vertical" name="form_brand_modal">
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="name" label="병원명" rules={[{ required: true, message: '병원명을 입력해주세요.' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="tel" label="연락처" rules={[{ required: true, message: '연락처를 입력해주세요.' }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="departmentIds" label="진료과목" rules={[{ required: true, message: '진료과목을 선택해주세요.' }]}>
        <Select mode="multiple">
          {fields.department.map((item: any) => {
            return <Option key={item.id.toString()}>{item.name}</Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name="subwayId" label="지하철역" rules={[{ required: true, message: '지하철역을 선택해주세요.' }]}>
        <Select
          showSearch={true}
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
          options={[...fields.subway.map((item: any) => ({ value: item.id.toString(), label: item.name }))]}
        />
      </Form.Item>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="sidoId" label="시/도" rules={[{ required: true, message: '시/도를 선택해주세요.' }]}>
            <Select onChange={handleChangeSido}>
              {fields.sido.map((item: any) => {
                return <Option key={item.id.toString()}>{item.name}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="sgguId" label="시/군/구" rules={[{ required: true, message: '시/군/구를 선택해주세요.' }]}>
            <Select onChange={handleChangeSggu}>
              {fields.sggu.map((item: any) => {
                return <Option key={item.id.toString()}>{item.name}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="addressId" label="읍/면/동" rules={[{ required: true, message: '읍/면/동을 선택해주세요.' }]}>
            <Select>
              {fields.emdong.map((item: any) => {
                return <Option key={item.id.toString()}>{item.name}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="addressDetail" label="상세주소" rules={[{ required: true, message: '상세주소를 입력해주세요.' }]}>
        <Input />
      </Form.Item>
      {dataSource.id < 1 && (
        <>
          <Form.Item name="accountEmail" label="이메일" rules={[{ required: true, message: '이메일을 입력해주세요.' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="newAccountPasswd" label="패스워드" rules={[{ required: true, message: '패스워드를 입력해주세요.' }]}>
            <Input type="password" />
          </Form.Item>
          <Form.Item name="reAccountPasswd" label="패스워드확인" rules={[{ required: true, message: '패스워드확인을 입력해주세요.' }]}>
            <Input type="password" />
          </Form.Item>
        </>
      )}
    </Form>
  );
}
