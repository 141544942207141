//CORE
import React, { useState, useReducer, useEffect } from 'react';

//STYLE
import styled from 'styled-components';

//COMPONENTS
import { Modal, ModalInputItem as InputItem } from '../../../components';
import { Input, message, Image, Button } from 'antd';

//TYPE
import type { IRegisterNoticePayload, IRegisterNoticeModal } from '../../../service/type';
import { useMutation } from 'react-query';

//API
import { registerNotice, modifyNotice } from '../../../api';
import moment from 'moment';

const { TextArea } = Input;

const RegisterModal: React.FC<IRegisterNoticeModal> = ({ visible, onClose, data, onRefetch }) => {
  const isModify = data !== null;

  const [payload, setPayload] = useReducer((state: IRegisterNoticePayload, data: any) => ({ ...state, ...data }), {
    title: '',
    contents: '',
    images: null,
    categoryId: 0,
  });

  const [registerMutation, modifyMutaion] = [
    useMutation((data: any) => registerNotice(data), {
      onError: (e) => {
        message.error('등록 실패 :(');
      },
      onSuccess() {
        message.success('등록 완료 !');
        onRefetch();
        onClose();
      },
    }),
    useMutation(({ id, data }: any) => modifyNotice(id, data), {
      onError: (e) => {
        message.error('수정 실패 :(');
      },
      onSuccess() {
        message.success('수정 완료 !');
        onRefetch();
        onClose();
      },
    }),
  ];

  const handleChangeTextInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value, name },
    } = e;
    setPayload({
      [name]: value,
    });
  };

  const submit = async () => {
    if (payload.title.trim().length === 0) {
      message.warn('공지제목을 입력해주세요.');
      return false;
    }

    if (payload.contents.trim().length === 0) {
      message.warn('공지내용을 입력해주세요.');
      return false;
    }

    const formData = new FormData();

    Object.keys(payload).forEach((key: string): void => {
      formData.append(key, payload[key]);
    });

    if (isModify) {
      modifyMutaion.mutate({
        id: data.id,
        data: formData,
      });
    } else {
      registerMutation.mutate(formData);
    }
  };

  useEffect(() => {
    if (data && visible) {
      setPayload({
        ...data,
        deletedImages: [],
      });
    }
    return () => {
      setPayload({
        title: '',
        contents: '',
        images: null,
        categoryId: 0,
      });
    };
  }, [data, visible]);
  return (
    <Modal
      title="공지등록"
      onClose={onClose}
      visible={visible}
      buttons={[
        { label: '닫기', onClick: onClose },
        { label: isModify ? '수정' : '등록', onClick: submit, type: 'primary' },
      ]}
    >
      <InputItem label="공고제목" item={<Input placeholder="공지사항 제목을 입력해주세요." name="title" value={payload.title} onChange={handleChangeTextInput} />} />
      <InputItem label="내용" item={<TextArea placeholder="공지사항 내용을 입력해주세요." name="contents" style={{ resize: 'none', height: 300 }} value={payload.contents} onChange={handleChangeTextInput} />} />
      <InputItem
        label="이미지"
        item={
          <input
            type="file"
            multiple
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPayload({
                images: e.currentTarget.files ? e.currentTarget.files[0] : null,
              })
            }
          />
        }
      />
      {isModify && payload.deletedImages && (
        <InputItem
          label="등록된 이미지"
          item={
            <ImageWrap>
              {data.images &&
                data.images.map((image) => {
                  const isDeleted = payload.deletedImages.filter((deleteId: number) => image.id === deleteId).length >= 1;
                  if (isDeleted) {
                    return false;
                  }
                  return (
                    <ImageItem key={`attached-notice-image-${image.id}`}>
                      <Image width={100} height={100} style={{ marginRight: 4 }} src={`${process.env.REACT_APP_API_BASE_IMAGE_URL}${image.filePath}`} />
                      <Button
                        type="primary"
                        danger
                        onClick={() =>
                          setPayload({
                            deletedImages: [...payload.deletedImages, image.id],
                          })
                        }
                      >
                        삭제
                      </Button>
                    </ImageItem>
                  );
                })}
            </ImageWrap>
          }
        />
      )}
    </Modal>
  );
};

const ImageWrap = styled.div`
  display: flex;
  padding-top: 8px;
`;

const ImageItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4px;
`;
export default RegisterModal;
