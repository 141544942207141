import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Row, Select, message } from 'antd';
import { readAllAddressSidoSgguEmdongBySidoWithSggu, readAllAddressSidoSgguBySido, readAllAddressSido, readAllHospitalDepartment, readAllSubway, updateHospital } from '../../../api';
import { readHospital } from '../../../api';
import Page from '../../../components/Page';

const { Option } = Select;

export default function HospitalPage() {
  const [form] = Form.useForm();
  const [isFormReady, formReady] = React.useState(false);
  const [fields, setFields] = React.useState<any>({
    subway: [],
    department: [],
    sido: [],
    sggu: [],
    emdong: [],
  });

  const handleFinish = async () => {
    try {
      const values = await form.validateFields();
      await updateHospital(values);
      message.info('수정 되었습니다.');
    } catch (e: any) {
      console.log(e);
    }
  };
  const handleChangeSido = async (sido: number) => {
    try {
      const sggu = await readAllAddressSidoSgguBySido(sido);
      setFields({
        ...fields,
        sggu,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handleChangeSggu = async (sggu: number) => {
    try {
      const emdong = await readAllAddressSidoSgguEmdongBySidoWithSggu(form.getFieldsValue().sidoId, sggu);
      setFields({
        ...fields,
        emdong,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const bootstrap = async () => {
      let newDepartmentIds: any = [];

      const hospital: any = await readHospital();
      try {
        let sggu: any = [];
        let emdong: any = [];
        if (Number(hospital.sidoId) > 0) {
          sggu = await readAllAddressSidoSgguBySido(hospital.sidoId);
          if (Number(hospital.sgguId) > 0) {
            emdong = await readAllAddressSidoSgguEmdongBySidoWithSggu(hospital.sidoId, hospital.sgguId);
          }
        }
        setFields({ ...fields, sggu, emdong });
      } catch (e) {
        console.log(e);
      } finally {
        if (hospital.departments) {
          newDepartmentIds = hospital.departments.map((item: any) => {
            return item.id.toString();
          });
        }
        form.setFieldsValue({
          name: hospital.name,
          tel: hospital.tel,
          departmentIds: newDepartmentIds,
          subwayId: hospital.subwayId,
          sidoId: hospital.sidoId,
          sgguId: hospital.sgguId,
          addressId: hospital.emdongId,
          addressDetail: hospital.addressDetail,
        });
      }
    };
    if (isFormReady) {
      bootstrap();
    }
  }, [isFormReady]);
  useEffect(() => {
    const bootstrap = async () => {
      try {
        formReady(false);
        const subway = await readAllSubway();
        const department = await readAllHospitalDepartment();
        const sido = await readAllAddressSido();
        setFields({ subway, department, sido, sggu: [], emdong: [] });
      } catch (e) {
        console.log(e);
      } finally {
        formReady(true);
      }
    };

    bootstrap();
  }, []);

  return (
    <Page title="병원정보">
      <Form form={form} layout="vertical" name="form_hospital_modal" onFinish={handleFinish}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="name" label="병원명" rules={[{ required: true, message: '병원명을 입력해주세요.' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="tel" label="연락처" rules={[{ required: true, message: '연락처를 입력해주세요.' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="departmentIds" label="진료과목" rules={[{ required: true, message: '진료과목을 선택해주세요.' }]}>
          <Select mode="multiple">
            {fields.department.map((item: any) => {
              return <Option key={item.id.toString()}>{item.name}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item name="subwayId" label="지하철역" rules={[{ required: true, message: '지하철역을 선택해주세요.' }]}>
          <Select>
            {fields.subway.map((item: any) => {
              return <Option key={item.id.toString()}>{item.name}</Option>;
            })}
          </Select>
        </Form.Item>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item name="sidoId" label="시/도" rules={[{ required: true, message: '시/도를 선택해주세요.' }]}>
              <Select onChange={handleChangeSido}>
                {fields.sido.map((item: any) => {
                  return <Option key={item.id.toString()}>{item.name}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="sgguId" label="시/군/구" rules={[{ required: true, message: '시/군/구를 선택해주세요.' }]}>
              <Select onChange={handleChangeSggu}>
                {fields.sggu.map((item: any) => {
                  return <Option key={item.id.toString()}>{item.name}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="addressId" label="읍/면/동" rules={[{ required: true, message: '읍/면/동을 선택해주세요.' }]}>
              <Select>
                {fields.emdong.map((item: any) => {
                  return <Option key={item.id.toString()}>{item.name}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="addressDetail" label="상세주소" rules={[{ required: true, message: '상세주소를 입력해주세요.' }]}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            저장
          </Button>
        </Form.Item>
      </Form>
    </Page>
  );
}
