import React from 'react';
import { Space, DatePicker, Input, Select, Button, Form } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
const { RangePicker } = DatePicker;

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
  onRequestCreate: () => void;
}
export default function TalkListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch, onRequestCreate } = props;

  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };
  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          title: '',
          dates: ['', ''],
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="dates">
          <RangePicker locale={locale} />
        </Form.Item>
        <Form.Item name="keyword">
          <Input placeholder="제목" allowClear />
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
          <Button type="primary" onClick={onRequestCreate}>
            영상등록
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
