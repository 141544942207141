import * as React from 'react';
import { Drawer, PageHeader, Table } from 'antd';
import { readAllTalkCommentReplyByTalkWithComment } from '../../api';
import TalkStatusSelect from '../TalkStatusSelect';
import DrawerReportListByTalkWithCommentWithReply from '../DrawerReportListByTalkWithCommentWithReply';

export default function DrawerReplyListByTalkWithComment(props: any) {
  const { visible, data, onRequestClose } = props;
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any>([]);
  const [report, setReport] = React.useState<any>({
    visible: false,
    data: null,
  });
  const handleBack = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const bootstrap = async () => {
    try {
      setLoading(true);
      const res = await readAllTalkCommentReplyByTalkWithComment(data.talkId, data.commentId);
      setItems(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible) {
      bootstrap();
    }
  }, [visible]);

  return (
    <>
      <Drawer visible={visible} getContainer={false} width={'100%'} closable={false} style={{ position: 'absolute' }}>
        <PageHeader ghost={false} onBack={handleBack} title={`댓글별 답글 목록`}>
          <Table
            size="small"
            bordered={true}
            dataSource={items}
            loading={loading}
            pagination={false}
            columns={[
              {
                title: 'No',
                render(_, __, index: number) {
                  return items.length - index;
                },
              },
              {
                title: '작성일자',
                dataIndex: 'createDate',
              },
              {
                title: '작성자',
                dataIndex: 'nickName',
              },
              {
                title: '내용',
                dataIndex: 'contents',
                ellipsis: true,
                width: 200,
              },
              {
                title: '신고',
                dataIndex: 'reportCount',
                render(value, record) {
                  if (value < 1) {
                    return 0;
                  }

                  return (
                    <a
                      onClick={() => {
                        setReport({
                          visible: true,
                          data: {
                            talkId: record.talkId,
                            commentId: record.commentId,
                            replyId: record.id,
                          },
                        });
                      }}
                    >
                      {value}
                    </a>
                  );
                },
              },
              {
                title: '블라인드',
                render(_, record) {
                  return (
                    <TalkStatusSelect
                      type="reply"
                      dataSource={{
                        talkId: record.talkId,
                        commentId: record.commentId,
                        replyId: record.id,
                        value: record.statusId,
                      }}
                      reportCount={record.reportCount}
                      onFinish={bootstrap}
                    />
                  );
                },
              },
            ]}
          />
        </PageHeader>
      </Drawer>
      <DrawerReportListByTalkWithCommentWithReply
        {...report}
        onRequestClose={() => {
          setReport({ visible: false, data: null });
          bootstrap();
        }}
      />
    </>
  );
}
