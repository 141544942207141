import * as React from 'react';
import { Button } from 'antd';
//STYLE
import styled from 'styled-components';

//API
import { readMemberAuthByMemberWithPK, approveMemberAuth, rejectMemberAuth } from '../../../../../api';
import { useMutation, useQuery, useQueryClient } from 'react-query';

//COMPONENTS
import { Switch, message, Image } from 'antd';
import { Modal, ModalInputItem as InputItem } from '../../../../../components';

//HELPER
import { imageUrl } from '../../../../../service';

//TYPE
import type { IMemberAuthModal } from '../../../../../service/type';

export default function ModalAuthRequest(props: IMemberAuthModal) {
  const { visible, onClose, memberId, authId } = props;

  const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useQuery(['getMemberAuthDetail', memberId], () => readMemberAuthByMemberWithPK(memberId, authId), {
    enabled: visible,
  });
  const [{ mutate: approve }, { mutate: reject }] = [
    useMutation(() => approveMemberAuth(memberId, authId), {
      onError: (e) => {
        message.error('인증 승인 실패 :(');
      },
      onSuccess() {
        message.success('인증 승인 완료!');
        refetch();
        setTimeout(() => {
          queryClient.invalidateQueries('readAllMember');
        }, 200);
      },
    }),
    useMutation(() => rejectMemberAuth(memberId, authId), {
      onError: (e) => {
        message.error('인증 반려 실패 :(');
      },
      onSuccess() {
        message.success('인증 반려 완료!');
        refetch();
        setTimeout(() => {
          queryClient.invalidateQueries('readAllMember');
        }, 200);
      },
    }),
  ];

  const handleToggleAuth = (state: boolean) => {
    if (state) {
      approve();
    } else {
      reject();
    }
  };

  if (!data || isLoading) {
    return null;
  }

  return (
    <Modal
      title="인증회원 관리"
      onClose={onClose}
      visible={visible}
      bodyStyle={{ width: '800px' }}
      customBottom={
        <BottomWrap>
          <Switch onChange={handleToggleAuth} defaultChecked={data.isAuth === 'Y'} />
          <Button onClick={onClose}>닫기</Button>
        </BottomWrap>
      }
    >
      <InputItem label="ID" item={<span>{data.email}</span>} />
      <InputItem label="닉네임" item={<span>{data.nickName}</span>} />
      <InputItem label="신청일" item={<span>{data.requestDate}</span>} />
      <InputItem label="인증/거절일" item={<span>{data.lastAuthDate}</span>} />
      <Attached src={imageUrl(data.authImageURL)} alt="첨부된 이미지" />
    </Modal>
  );
}

const BottomWrap = styled.div`
  border-top: 1px solid #ddd;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Attached = styled(Image)`
  width: 100%;
`;
