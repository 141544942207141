import * as React from 'react';
import { Form } from 'antd';
import Page from '../../../components/Page';
import MemberListTable from './MemberResumeListTable';
import MemberListForm from './MemberResumeListForm';
import { readAllMemberResume } from '../../../api';

export default function MemberResumeListPage() {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<any>({
    page: 1,
    total: 0,
    items: [],
  });

  const onSearch = async (nextPage: number) => {
    try {
      setLoading(true);
      const nextFormValues = form.getFieldsValue();
      delete nextFormValues.dates;
      const nextResponse: any = await readAllMemberResume({
        ...nextFormValues,
        page: nextPage,
      });
      setResponse({
        page: nextPage,
        total: nextResponse.total,
        items: nextResponse.items,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    onSearch(1);
  }, []);

  return (
    <Page title="회원관리" subTitle="이력서정보 리스트">
      <MemberListForm form={form} searchLoading={loading} onRequestSearch={onSearch} />
      <MemberListTable
        loading={loading}
        dataSource={response.items}
        pagination={{
          position: ['bottomCenter'],
          current: response.page,
          total: response.total,
          pageSize: 20,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: onSearch,
        }}
      />
    </Page>
  );
}
