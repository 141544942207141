import * as React from 'react';
import { Space, DatePicker, Input, Button, Form, Select, Tag } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { getCastingCategories } from '../../../../api';
const { RangePicker } = DatePicker;

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
  onRequestCreate: () => void;
}
export default function CastingListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch, onRequestCreate } = props;
  const formRecruitId = Form.useWatch('recruitId', form);
  const [fields, setFields] = React.useState<any>({
    occupations: [],
  });
  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };

  React.useEffect(() => {
    const bootstrap = async () => {
      try {
        const occupations = await getCastingCategories();
        setFields({ occupations });
      } catch (e) {
        console.log(e);
      }
    };

    bootstrap();
  }, []);
  return (
    <Space size={1}>
      <Form form={form} layout="inline" onFinish={handleFinish} initialValues={{ recruitId: null, dates: ['', ''], name: '', categoryId: null, inspectId: null }}>
        <Form.Item name="recruitId">
          <Select placeholder="기간방식" allowClear>
            <Select.Option value={2}>상시모집</Select.Option>
            <Select.Option value={3}>채용시</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="dates" hidden={formRecruitId > 1}>
          <RangePicker locale={locale} allowClear />
        </Form.Item>
        <Form.Item name="hospitalName">
          <Input placeholder="병원명" allowClear />
        </Form.Item>
        <Form.Item name="occupationId">
          <Select placeholder="모집직종" allowClear style={{ width: 100 }}>
            {fields.occupations.map((item: any) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="inspectId">
          <Select placeholder="검수상태" allowClear>
            <Select.Option value={0}>
              <Tag color="processing">검수대기</Tag>
            </Select.Option>
            <Select.Option value={1}>
              <Tag color="success">검수승인</Tag>
            </Select.Option>
            <Select.Option value={2}>
              <Tag color="error">검수반려</Tag>
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="useAd">
          <Select placeholder="광고사용" allowClear>
            <Select.Option value={'Y'}>
              <Tag color="success">사용함</Tag>
            </Select.Option>
            <Select.Option value={'N'}>
              <Tag color="error">사용안함</Tag>
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="useTalkPremiumAd">
          <Select placeholder="게시글 프리미엄 광고 사용" allowClear>
            <Select.Option value={'Y'}>
              <Tag color="success">사용함</Tag>
            </Select.Option>
            <Select.Option value={'N'}>
              <Tag color="error">사용안함</Tag>
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="isActive">
          <Select placeholder="활성/비활성" allowClear>
            <Select.Option value={'Y'}>
              <Tag color="success">활성</Tag>
            </Select.Option>
            <Select.Option value={'N'}>
              <Tag color="error">비활성</Tag>
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={onRequestCreate}>
            공고등록
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
