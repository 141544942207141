import React, { useEffect } from 'react';
import { Col, Form, FormInstance, Input, Radio, Row, Select, Upload, message, DatePicker } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { readAllAdPosition, readAllCompany } from '../../../../../api';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';

const { Option } = Select;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt20M = file.size / 1024 / 1024 < 20;
  if (!isLt20M) {
    message.error('Image must smaller than 20MB!');
  }
  return isJpgOrPng && isLt20M;
};

interface IProps {
  form: FormInstance;
  dataSource: any;
}

export default function AdForm(props: IProps) {
  const { form, dataSource } = props;
  const [mainUrl, setMainUrl] = React.useState<any>(null);
  const [landingUrl, setLandingUrl] = React.useState<any>(null);
  const [loading] = React.useState(false);
  const [isFormReady, formReady] = React.useState(false);
  const [fields, setFields] = React.useState<any>({
    company: [],
    position: [],
  });

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 100);
  };
  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const handleChangeMain: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    console.log(info.file);
    if (info.file.status === 'uploading') {
      //setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        // setLoading(false);
        setMainUrl(url);
      });
    }
  };

  const handleChangeLanding: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    console.log(info.file);
    if (info.file.status === 'uploading') {
      //setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        // setLoading(false);
        setLandingUrl(url);
      });
    }
  };

  const renderCompany = () => {
    if (dataSource.id > 0) {
      return (
        <Form.Item label="광고업체">
          <Input defaultValue={dataSource.companyName} readOnly />
        </Form.Item>
      );
    }

    return (
      <Form.Item name="companyId" label="광고업체" rules={[{ required: true, message: '광고업체를 선택해주세요.' }]}>
        <Select>
          {fields.company.map((item: any, index: number) => {
            return (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  };
  useEffect(() => {
    const bootstrap = async () => {
      try {
        //
      } catch (e) {
        console.log(e);
      } finally {
        if (dataSource.id > 0) {
          let startDateTime = dataSource.startDateTime;
          let endDateTime = dataSource.endDateTime;
          startDateTime = startDateTime !== '0000-00-00 00:00:00' ? moment(dataSource.startDateTime) : null;
          endDateTime = endDateTime !== '0000-00-00 00:00:00' ? moment(dataSource.endDateTime) : null;
          console.log(dataSource)
          form.setFieldsValue({
            id: dataSource.id,
            positionId: dataSource.positionId,
            companyId: dataSource.companyId,
            title: dataSource.title,
            dates: [startDateTime, endDateTime],
            linkURL: dataSource.linkURL,
          });
        }

        if (dataSource.id > 0) {
          setMainUrl(process.env.REACT_APP_API_BASE_IMAGE_URL + dataSource.mainImageURL);
          setLandingUrl(process.env.REACT_APP_API_BASE_IMAGE_URL + dataSource.landingImageURL);
        } else {
          setMainUrl('');
          setLandingUrl('');
        }
      }
    };
    if (isFormReady) {
      bootstrap();
    }
  }, [isFormReady, dataSource]);
  useEffect(() => {
    const bootstrap = async () => {
      try {
        formReady(false);
        const company = await readAllCompany(null);
        const position = await readAllAdPosition();
        setFields({ company, position });
      } catch (e) {
        console.log(e);
      } finally {
        formReady(true);
      }
    };

    bootstrap();
  }, []);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Form
      form={form}
      layout="vertical"
      name="form_casting_modal"
      initialValues={{
        positionId: 1,
        companyId: '',
        title: '',
        occupationIds: [],
        dates: [null, null],
        recruitId: 1,
        career: '',
        careerMarkId: 1,
        salary: '',
        salaryNegoId: 1,
        useAd: 'N',
      }}
    >
      {renderCompany()}
      <Form.Item name="positionId" label="노출위치" rules={[{ required: true, message: '노출위치를 선택해주세요.' }]}>
        <Select>
          {fields.position.map((item: any) => {
            return (
              <Option key={item.id.toString()} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="title" label="공고제목" rules={[{ required: true, message: '광고제목을 입력해주세요.' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="dates" label="공고기간" rules={[{ required: true, message: '광고기간을 입력해주세요.' }]}>
        <DatePicker.RangePicker showTime={true} />
      </Form.Item>

      <Form.Item name="linkURL" label="도착URL" rules={[{ required: false, message: '랜딩화면에서 클릭시 노출하실 URL을 입력해주세요' }]}>
        <Input />
      </Form.Item>

      <Form.Item
        name="mainImage"
        label="로고"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: dataSource.id < 1 ? true : false,
            message: '로고를 등록해주세요',
          },
        ]}
      >
        <Upload
          name="mainImage"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChangeMain}
          maxCount={1}
          customRequest={dummyRequest}
          onPreview={function handlePreview(file: UploadFile) {}}
        >
          {mainUrl ? <img src={mainUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
      </Form.Item>
      <Form.Item
        name="landingImage"
        label="상세(랜딩)이미지"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: dataSource.id < 1 ? true : false,
            message: '랜딩이미지를 등록해주세요',
          },
        ]}
      >
        <Upload
          name="landingImage"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChangeLanding}
          maxCount={1}
          customRequest={dummyRequest}
          onPreview={function handlePreview(file: UploadFile) {}}
        >
          {landingUrl ? <img src={landingUrl} alt="avatar" style={{ width: '100%', maxHeight: 104 }} /> : uploadButton}
        </Upload>
      </Form.Item>
    </Form>
  );
}
