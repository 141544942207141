import * as React from 'react';
import { Dropdown, Menu, Modal, Space, Table, Tooltip } from 'antd';
import { getTableColumnNo } from '../../../../components/Table';
import { deleteHospitalUserByHospitalWithPK } from '../../../../api';

export default function HospitalUserListTable(props: any) {
  const { loading, dataSource, pagination, onRequestUpdate, onRequestRefresh, handleClickRequestChangePasswod } = props;
  const handleClickRequestUpdate = (item: any) => {
    onRequestUpdate(item);
  };

  const handleClickRequestDelete = async (item: any) => {
    try {
      Modal.confirm({
        title: '계정 삭제 안내',
        content: item.hospitalName + '의' + item.name + '님의 계정을 삭제하시겠습니까?',
        async onOk() {
          await deleteHospitalUserByHospitalWithPK(item.hospitalId, item.id);
          onRequestRefresh();
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Table
        size="small"
        bordered={true}
        scroll={{ x: true }}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        rowKey="id"
        columns={[
          getTableColumnNo(pagination.total, 20, pagination.current),
          {
            title: '계정ID',
            dataIndex: 'id',
            render(value: number, record: any) {
              return (
                <Tooltip placement="topLeft" title="삭제 또는 수정하실 수 있는 메뉴가 나오십니다.">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="update" onClick={() => handleClickRequestUpdate(record)}>
                          계정 수정
                        </Menu.Item>
                        <Menu.Item key="change" onClick={() => handleClickRequestChangePasswod(record)}>
                          비밀번호 변경
                        </Menu.Item>
                        <Menu.Item key="delete" onClick={() => handleClickRequestDelete(record)}>
                          계정 삭제
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>{value}</Space>
                    </a>
                  </Dropdown>
                </Tooltip>
              );
            },
          },
          {
            title: '생성일',
            dataIndex: 'createDate',
          },
          {
            title: '병원명',
            dataIndex: 'hospitalName',
          },
          {
            title: '이름',
            dataIndex: 'name',
          },
          {
            title: '계정명',
            dataIndex: 'email',
          },
        ]}
      />
    </>
  );
}
