import { Select } from "antd";
import * as React from "react";
import {
  updateTalkCommentReplyStatus,
  updateTalkCommentStatus,
  updateTalkStatus,
} from "../../api";

interface IProps {
  type: "talk" | "comment" | "reply";
  dataSource: {
    talkId: number;
    commentId?: number;
    replyId?: number;
    value: string;
  };
  reportCount: number;
  onFinish: () => void;
}
export default function TalkStatusSelect(props: IProps) {
  const { type, reportCount, dataSource, onFinish } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const handleChange = async (value: string) => {
    try {
      setLoading(true);
      switch (type) {
        case "talk":
          await updateTalkStatus(dataSource.talkId, value);
          break;
        case "comment":
          await updateTalkCommentStatus(
            dataSource.talkId,
            dataSource?.commentId || 0,
            value
          );
          break;
        case "reply":
          await updateTalkCommentReplyStatus(
            dataSource.talkId,
            dataSource?.commentId || 0,
            dataSource?.replyId || 0,
            value
          );
          break;
      }
      onFinish();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const selectProps = {
    loading: loading,
    value: dataSource.value,
    bordered: false,
    onChange: handleChange,
  };
  return (
    <Select {...selectProps}>
      <Select.Option value="0">미처리</Select.Option>
      {reportCount > 0 && <Select.Option value="1">신고처리</Select.Option>}
      <Select.Option value="2">직접처리</Select.Option>
    </Select>
  );
}
