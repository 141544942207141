import React from "react";
import { Result, Button } from "antd";

const Fobidden: React.FC = () => {
  return (
    <section style={{ flex: 1 }}>
      <Result
        status="403"
        title="403"
        subTitle="권한이 없어요."
        extra={<Button type="primary">돌아가기</Button>}
      />
    </section>
  );
};

export default Fobidden;
