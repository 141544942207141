import React from 'react';
import { Drawer, Modal, PageHeader, Table } from 'antd';
import { readHospitalHireResumeByPK } from '../../api';

export default function DrawerHospitalHireResumeList(props: any) {
  const { visible, dataSource, onRequestClose } = props;
  const handleBack = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const handleClickCareer = async (item: any) => {
    try {
      const res = await readHospitalHireResumeByPK(item.id);
      Modal.info({
        width: 600,
        title: `${item.resumeName}님의 경력`,
        content: (
          <Table
            size="small"
            dataSource={res.careers}
            pagination={false}
            columns={[
              {
                title: '입사일',
                dataIndex: 'startDate',
              },
              {
                title: '퇴사일',
                dataIndex: 'endDate',
              },
              {
                title: '회사명',
                dataIndex: 'companyName',
              },
              {
                title: '업무',
                dataIndex: 'task',
              },
              {
                title: '연봉',
                dataIndex: 'salary',
              },
            ]}
          />
        ),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickIntroduce = (item: any) => {
    Modal.info({
      title: `${item.resumeName}님의 자기소개`,
      content: <p>{item.resumeIntroduce}</p>,
    });
  };

  return (
    <Drawer visible={visible} getContainer={false} width={'100%'} closable={false} style={{ position: 'absolute' }}>
      <PageHeader ghost={false} onBack={handleBack} title={`받은 이력서 목록`}>
        <Table
          size="small"
          bordered={true}
          dataSource={dataSource}
          pagination={false}
          columns={[
            {
              title: '받은날짜',
              dataIndex: 'createDate',
            },
            {
              title: '직종',
              dataIndex: 'categoryName',
            },
            {
              title: '이름',
              dataIndex: 'resumeName',
            },
            {
              title: '연락처',
              dataIndex: 'resumePhone',
            },
            {
              title: '희망연봉',
              render(_, record) {
                if (record.resumeSalaryNegoId === 1) {
                  return record.resumeHopeSalary + '만원';
                } else if (record.resumeSalaryNegoId === 2) {
                  return '추후협의';
                } else {
                  return null;
                }
              },
            },
            {
              title: '재직여부',
              dataIndex: 'resumeJobStatusId',
              render(value) {
                if (value === 1) {
                  return '구직중';
                } else if (value === 2) {
                  return '재직중';
                } else {
                  return null;
                }
              },
            },
            {
              title: '경력',
              dataIndex: 'careerDay',
              render(value, record) {
                let str = '';
                if (Number(value) > 0) {
                  let careerMonth = Number(value) / 30;
                  if (careerMonth / 12 >= 1) {
                    str += `${(Number(careerMonth) / 12).toFixed(0)}년`;
                  }

                  if (careerMonth % 12 >= 1) {
                    str += `${(Number(careerMonth) % 12).toFixed(0)}개월`;
                  }
                } else {
                  return null;
                }
                return <a onClick={() => handleClickCareer(record)}>{str}</a>;
              },
            },
            {
              title: '자기소개',
              dataIndex: 'resumeIntroduce',
              render(value, item: any) {
                if (!value || value === null || value === '') {
                  return null;
                }
                return <a onClick={() => handleClickIntroduce(item)}>보기</a>;
              },
            },
          ]}
        />
      </PageHeader>
    </Drawer>
  );
}
