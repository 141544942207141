import * as React from 'react';
import Page from '../../../components/Page';
import TalkListTable from './features/TalkListTable';
import TalkListForm from './features/TalkListForm';
import { deleteNotice, readAllTalk } from '../../../api';
import { Form } from 'antd';
import moment from 'moment';
import RegisterModal from './RegisterModal';

interface IResponse {
  page: number;
  total: number;
  items: [];
}
export default function TalkNoticePage() {
  const [form] = Form.useForm();
  const [registerModalState, setRegisterModalState] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [notice, setNotice] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<IResponse>({
    page: 1,
    total: 0,
    items: [],
  });

  const onSearch = async (nextPage: number) => {
    try {
      setLoading(true);
      const nextFormValues = form.getFieldsValue();
      const startDate = moment(nextFormValues.dates[0]).format('YYYY-MM-DD');
      const endDate = moment(nextFormValues.dates[1]).format('YYYY-MM-DD');
      delete nextFormValues.dates;
      const nextResponse: any = await readAllTalk({
        ...nextFormValues,
        isNotice: 'Y',
        startDate: startDate === 'Invalid date' ? '' : startDate,
        endDate: endDate === 'Invalid date' ? '' : endDate,
        page: nextPage,
      });
      setResponse({
        page: nextPage,
        total: nextResponse.total,
        items: nextResponse.items,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    onSearch(1);
  }, []);

  return (
    <>
      <Page title="게시글 관리" subTitle="전체 공지사항 목록">
        <TalkListForm form={form} searchLoading={loading} onRequestSearch={onSearch} onRequestCreate={() => setRegisterModalState({ ...registerModalState, visible: true })} />
        <TalkListTable
          loading={loading}
          dataSource={response.items}
          pagination={{
            current: response.page,
            total: response.total,
            pageSize: 20,
            hideOnSinglePage: true,
            showSizeChanger: false,
            onChange: onSearch,
          }}
          onRequestRefresh={() => onSearch(response.page)}
          onRequestUpdate={(data) => setRegisterModalState({ visible: true, data: data })}
          onRequestDelete={async (data) => {
            try {
              await await deleteNotice(data.id);
            } catch (e) {
              console.log(e);
            } finally {
              onSearch(response.page);
            }
          }}
        />
      </Page>
      <RegisterModal
        {...registerModalState}
        onClose={() =>
          setRegisterModalState({
            data: null,
            visible: false,
          })
        }
        onRefetch={() => onSearch(response.page)}
      />
    </>
  );
}
