import React from 'react';
import { Form, Modal, message } from 'antd';
import AdForm from './AdForm';
import { createCompanyAd, createHospitalHireByHospital, readCompanyAdByCompanyWithPK, readHospitalHireByHospitalWithPK, updateCompanyAdByCompanyWithPK, updateHospitalHireByHospitalWithPK } from '../../../../api';

export type FormType = {
  id?: number;
  hospitalId: number;
  startDate: string;
  endDate: string;
  occupationIds: [];
  salary: string;
  salaryNegoId: number;
  logo: string;
  detail: string;
  useAd: string;
};
interface IProps {
  visible: boolean;
  data: any;
  onFinish: () => void;
  onRequestClose: () => void;
}

const initialValues = {
  id: 0,
  hospitalId: 0,
  startDate: '',
  endDate: '',
  occupationIds: [],
  salary: '',
  recruitId: 1,
  salaryNegoId: 1,
  careerMarkId: 1,
  logo: '',
  detail: '',
  useAd: 'N',
} as FormType;
export default function AdFormModal(props: IProps) {
  const { visible, data, onRequestClose, onFinish } = props;
  const isUpdated = data ? true : false;
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [dataSource, setDataSource] = React.useState<FormType>(initialValues);
  const [form] = Form.useForm();

  const resetFormValue = () => {
    setDataSource(initialValues);
    form.resetFields();
  };
  const handleLoad = async (data: any) => {
    try {
      setConfirmLoading(true);
      const res: any = await readCompanyAdByCompanyWithPK(data.companyId, data.id);
      setDataSource(res);
    } catch (e) {
      console.log(e);
    } finally {
      setConfirmLoading(false);
    }
  };
  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('positionId', values.positionId);
      formData.append('startDateTime', values.dates[0]);
      formData.append('endDateTime', values.dates[1]);
      if (values.linkURL && values.linkURL !== '') {
        formData.append('linkURL', values.linkURL);
      } else {
        formData.append('linkURL', '');
      }

      if (values.mainImage && values.mainImage[0]) {
        formData.append('mainImage', values.mainImage[0].originFileObj);
      }
      if (values.landingImage && values.landingImage[0]) {
        formData.append('landingImage', values.landingImage[0].originFileObj);
      }
      if (data) {
        await updateCompanyAdByCompanyWithPK(formData, data.companyId, data.id);
      } else {
        await createCompanyAd(formData, values.companyId);
      }
      setConfirmLoading(false);
      message.info(`${isUpdated ? '수정' : '등록'} 되었습니다.`);
      onRequestClose();
      onFinish();
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleCancel = () => {
    onRequestClose();
    resetFormValue();
  };

  React.useEffect(() => {
    if (visible) {
      form.resetFields();
      if (data) {
        handleLoad(data);
      } else {
        resetFormValue();
      }
    }
  }, [visible, data]);

  return (
    <Modal visible={visible} confirmLoading={confirmLoading} title={`광고 정보 ${isUpdated ? '수정' : '등록'}`} okText={isUpdated ? '수정' : '등록'} cancelText="취소" onOk={handleOk} onCancel={handleCancel} width={686}>
      {visible && <AdForm form={form} dataSource={dataSource} />}
    </Modal>
  );
}
