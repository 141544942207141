import * as React from 'react';
import { Dropdown, Image, Menu, Modal, Select, Space, Table, Tag, Tooltip, message, Switch } from 'antd';
import { getTableColumnNo } from '../../../../components/Table';
import DrawerHospitalHireResumeList from '../../../../components/DrawerHospitalHireResumeList';
import { castingInspectByAdmin, changeHospitalHireIsActive, deleteCasting, readAllHospitalHireResumeByHospitalWithHire } from '../../../../api';
import moment from 'moment';

export default function CastingListTable(props: any) {
  const { loading, dataSource, pagination, onRequestUpdate, onRequestRefresh } = props;
  const [drawerResumeList, setDrawerResumeList] = React.useState<any>({
    visible: false,
    dataSource: null,
  });
  const [imagePreview, setImagePreview] = React.useState({
    visible: false,
    url: '',
  });
  const handleClickResumeCount = async (item: any) => {
    try {
      const res = await readAllHospitalHireResumeByHospitalWithHire(item.hospitalId, item.id);
      setDrawerResumeList({
        visible: true,
        dataSource: res,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handleClickRequestUpdate = (item: any) => {
    if (onRequestUpdate) {
      onRequestUpdate(item.hospitalId, item.id);
    }
  };
  const handleClickRequestDelete = (item: any) => {
    Modal.confirm({
      content: `선택하신 "${item.title}" 공고를 삭제하시겠어요?`,
      type: 'info',
      onOk: async () => {
        try {
          await deleteCasting(item.hospitalId, item.id);
          onRequestRefresh();
          message.success(`삭제 완료!`);
        } catch {
          message.error(`삭제 실패 :(`);
        } finally {
          console.log('LOADING END');
        }
      },
    });
  };
  const handleChangeActive = async (hospitalId: number, id: number, value: string) => {
    try {
      await changeHospitalHireIsActive(hospitalId, id, value);
      onRequestRefresh();
    } catch (e: any) {
      message.error(e.message);
      console.log(e);
    }
  };
  const handleChangeInspect = async (hospitalId: number, hireId: number, inspectId: number) => {
    try {
      message.loading({ content: '검수상태 변경중...', key: 'changeInspect' });
      await castingInspectByAdmin(hospitalId, hireId, inspectId);
      message.success({ content: '검수상태 변경완료', key: 'changeInspect' });
      onRequestRefresh();
    } catch (e) {
      console.log(e);
    }
  };

  const handleRequestPreviewImage = (url: string) => {
    setImagePreview({ visible: true, url: url });
  };

  return (
    <>
      <Table
        size="small"
        bordered={true}
        scroll={{ x: 2400 }}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        rowKey="id"
        columns={[
          getTableColumnNo(pagination.total, pagination.pageSize, pagination.current),
          {
            title: '공고ID',
            dataIndex: 'id',
            render(value: number, record: any) {
              return (
                <Tooltip placement="topLeft" title="삭제 또는 수정하실 수 있는 메뉴가 나오십니다.">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => handleClickRequestUpdate(record)}>수정</Menu.Item>
                        <Menu.Item onClick={() => handleClickRequestDelete(record)}>삭제</Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>{value}</Space>
                    </a>
                  </Dropdown>
                </Tooltip>
              );
            },
          },
          {
            title: '등록일',
            dataIndex: 'createDate',
            width: 160,
            align: 'center',
          },
          {
            title: '활성상태',
            dataIndex: 'isActive',
            align: 'center',
            width: 80,
            render(value, record) {
              if (record.id == 132) {
                console.log(value);
              }
              return (
                <Switch
                  size="small"
                  checked={value === 'Y' ? true : false}
                  onChange={(checked) => {
                    handleChangeActive(record.hospitalId, record.id, value === 'Y' ? 'N' : 'Y');
                  }}
                />
              );
            },
          },
          {
            title: '검수상태',
            dataIndex: 'inspectId',
            key: 'inspectId',
            align: 'center',
            render(value: number, record) {
              return (
                <Select bordered={false} onChange={(inspectId) => handleChangeInspect(record.hospitalId, record.id, inspectId)} value={value}>
                  <Select.Option value={0}>
                    <Tag color="processing">검수대기</Tag>
                  </Select.Option>
                  <Select.Option value={1}>
                    <Tag color="success">검수승인</Tag>
                  </Select.Option>
                  <Select.Option value={2}>
                    <Tag color="error">검수반려</Tag>
                  </Select.Option>
                </Select>
              );
            },
          },
          {
            title: '공고기간',
            width: 80,
            align: 'center',
            render(_, record) {
              if (record.recruitId === '2') {
                return '상시모집';
              } else if (record.recruitId === '3') {
                return '채용시';
              }

              return null;
            },
          },
          {
            title: '시작일',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 100,
            align: 'center',
            render(date: string, record) {
              if (record.recruitId !== '1') {
                return null;
              }
              if (date === '0000-00-00') {
                return null;
              } else {
                return moment(date).format('YYYY-MM-DD');
              }
            },
          },
          {
            title: '종료일',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 100,
            align: 'center',
            render(date, record) {
              if (record.recruitId !== '1') {
                return null;
              }
              if (date === '0000-00-00') {
                return '';
              } else {
                return moment(date).format('YYYY-MM-DD');
              }
            },
          },
          {
            title: '병원명',
            dataIndex: 'hospitalName',
            key: 'hospitalName',
          },
          {
            title: '공고제목',
            dataIndex: 'title',
            key: 'title',
          },
          {
            title: '모집직종',
            dataIndex: 'occupationNames',
            key: 'occupationNames',
            render: (value: string, record) => {
              if (value === null || value === '') {
                return null;
              }
              const names = value.split(',');
              return (
                <>
                  {names.map((name: string, idx: number) => (
                    <Tag key={`tag-${record.id}-${idx}`} color="blue">
                      {name}
                    </Tag>
                  ))}
                </>
              );
            },
          },
          {
            title: '경력',
            align: 'center',
            render(_, record) {
              if (record.careerMarkId === '1') {
                return '신입';
              } else if (record.careerMarkId === '2') {
                return '경력무관';
              } else {
                return record.career;
              }
            },
          },
          {
            title: '연봉',
            dataIndex: 'salary',
            key: 'salary',
            render(_, record) {
              if (record.salaryNegoId === '1') {
                return record.salary;
              } else if (record.salaryNegoId === '2') {
                return '추후협의';
              } else {
                return null;
              }
            },
          },
          {
            title: '로고',
            dataIndex: 'mainImageURL',
            align: 'center',
            render(value) {
              if (value === '') {
                return null;
              }
              return <a onClick={() => handleRequestPreviewImage(value)}>미리보기</a>;
            },
          },
          {
            title: '랜딩',
            dataIndex: 'detailImageURL',
            align: 'center',
            render(value) {
              if (value === '') {
                return null;
              }
              return <a onClick={() => handleRequestPreviewImage(value)}>미리보기</a>;
            },
          },
          {
            title: '광고사용여부',
            dataIndex: 'useAd',
            key: 'useAd',
            align: 'center',
            render(value: string) {
              return value === 'Y' ? <Tag color="green">사용</Tag> : <Tag color="red">미사용</Tag>;
            },
          },
          {
            title: '게시글 프리미엄 광고 사용여부',
            dataIndex: 'useTalkPremiumAd',
            key: 'useTalkPremiumAd',
            align: 'center',
            render(value: string) {
              return value === 'Y' ? <Tag color="green">사용</Tag> : <Tag color="red">미사용</Tag>;
            },
          },
          {
            title: '광고이미지',
            dataIndex: 'adImageURL',
            align: 'center',
            render(value, record: any) {
              if (record.useAd === 'N') {
                return null;
              }
              return <a onClick={() => handleRequestPreviewImage(value)}>미리보기</a>;
            },
          },
          {
            title: '노출수',
            dataIndex: 'viewCount',
            key: 'viewCount',
            align: 'center',
          },
          {
            title: '클릭수',
            dataIndex: 'hitCount',
            key: 'hitCount',
            align: 'center',
          },
          {
            title: '받은 이력서',
            dataIndex: 'resumeCount',
            key: 'resumeCount',
            align: 'center',
            render(resumeCount, item) {
              if (Number(resumeCount) > 0) {
                return <a onClick={() => handleClickResumeCount(item)}>{resumeCount}</a>;
              }

              return null;
            },
          },
        ]}
      />
      <Image
        width={200}
        style={{ display: 'none' }}
        src={process.env.REACT_APP_API_BASE_IMAGE_URL + imagePreview.url}
        preview={{
          visible: imagePreview.visible,
          src: process.env.REACT_APP_API_BASE_IMAGE_URL + imagePreview.url,
          onVisibleChange: (value) => {
            setImagePreview({ visible: false, url: '' });
          },
        }}
      />
      <DrawerHospitalHireResumeList {...drawerResumeList} onRequestClose={() => setDrawerResumeList({ visible: false, dataSource: null })} />
    </>
  );
}
