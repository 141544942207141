import * as React from 'react';
import { Modal, Table } from 'antd';
import { getTableColumnNo } from '../../../../components/Table';
import { readHospitalHireResumeByPK } from '../../../../api';
import moment from 'moment';

export default function ListTable(props: any) {
  const { loading, dataSource, pagination } = props;
  const handleClickCareer = async (item: any) => {
    try {
      const res = await readHospitalHireResumeByPK(item.id);
      Modal.info({
        width: 600,
        title: `${item.resumeName}님의 경력`,
        content: (
          <Table
            size="small"
            dataSource={res.careers}
            pagination={false}
            columns={[
              {
                title: '입사일',
                dataIndex: 'startDate',
              },
              {
                title: '퇴사일',
                dataIndex: 'endDate',
              },
              {
                title: '회사명',
                dataIndex: 'companyName',
              },
              {
                title: '업무',
                dataIndex: 'task',
              },
              {
                title: '연봉',
                dataIndex: 'salary',
              },
            ]}
          />
        ),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickIntroduce = (item: any) => {
    Modal.info({
      title: `${item.resumeName}님의 자기소개`,
      content: <p>{item.resumeIntroduce}</p>,
    });
  };

  return (
    <>
      <Table
        size="small"
        bordered={true}
        scroll={{ x: 1600 }}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        rowKey="id"
        columns={[
          getTableColumnNo(pagination.total, pagination.pageSize, pagination.current),
          {
            title: '받은날짜',
            dataIndex: 'createDate',
          },
          {
            title: '병원명',
            dataIndex: 'hospitalName',
            key: 'hospitalName',
          },
          {
            title: '공고제목',
            dataIndex: 'hireTitle',
          },
          {
            title: '경력',
            align: 'center',
            render(_, record) {
              if (record.careerMarkId === 1) {
                return '신입';
              } else if (record.careerMarkId === 2) {
                return '경력무관';
              } else {
                return record.career;
              }
            },
          },
          {
            title: '연봉',
            dataIndex: 'salary',
            key: 'salary',
            render(_, record) {
              if (record.salaryNegoId === 1) {
                return record.salary;
              } else if (record.salaryNegoId === 2) {
                return '추후협의';
              } else {
                return null;
              }
            },
          },
          {
            title: '직종',
            dataIndex: 'occupationName',
          },
          {
            title: '이름',
            dataIndex: 'resumeName',
          },
          {
            title: '연락처',
            dataIndex: 'resumePhone',
          },
          {
            title: '희망연봉',
            render(_, record) {
              if (record.resumeSalaryNegoId === 1) {
                return record.resumeHopeSalary + '만원';
              } else if (record.resumeSalaryNegoId === 2) {
                return '추후협의';
              } else {
                return null;
              }
            },
          },
          {
            title: '재직여부',
            dataIndex: 'resumeJobStatusId',
            render(value) {
              if (value === 1) {
                return '구직중';
              } else if (value === 2) {
                return '재직중';
              } else {
                return null;
              }
            },
          },
          {
            title: '경력',
            dataIndex: 'careerDay',
            render(value, record) {
              let str = '';
              if (Number(value) > 0) {
                let careerMonth = Number(value) / 30;
                if (careerMonth / 12 >= 1) {
                  str += `${(Number(careerMonth) / 12).toFixed(0)}년`;
                }

                if (careerMonth % 12 >= 1) {
                  str += `${(Number(careerMonth) % 12).toFixed(0)}개월`;
                }
              } else {
                return null;
              }
              return <a onClick={() => handleClickCareer(record)}>{str}</a>;
            },
          },
          {
            title: '자기소개',
            dataIndex: 'resumeIntroduce',
            render(value, item: any) {
              if (!value || value === null || value === '') {
                return null;
              }
              return <a onClick={() => handleClickIntroduce(item)}>보기</a>;
            },
          },
        ]}
      />
    </>
  );
}
