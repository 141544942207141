import * as React from 'react';
import { Drawer, PageHeader, Table, Tag } from 'antd';
import { readAllMemberHospitalHireByMember } from '../../../../../api';

export default function DrawerHireList(props: any) {
  const { visible, data, onRequestClose } = props;
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any>([]);
  const handleBack = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const bootstrap = async () => {
    try {
      setLoading(true);
      const res = await readAllMemberHospitalHireByMember(data);
      setItems(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible) {
      bootstrap();
    }
  }, [visible]);

  return (
    <Drawer visible={visible} getContainer={false} width={'100%'} closable={false} style={{ position: 'absolute' }}>
      <PageHeader ghost={false} onBack={handleBack} title={`공고 지원 내역`}>
        <Table
          size="small"
          bordered={true}
          dataSource={items}
          loading={loading}
          pagination={false}
          columns={[
            {
              title: '지원일자',
              dataIndex: 'createDate',
              width: 160,
              align: 'center',
            },
            {
              title: '병원명',
              dataIndex: 'hospitalName',
              key: 'hospitalName',
            },
            {
              title: '공고제목',
              dataIndex: 'title',
              key: 'title',
            },
            {
              title: '지원직종',
              dataIndex: 'categoryName',
            },
            {
              title: '경력',
              align: 'center',
              render(_, record) {
                if (record.careerMarkId === '1') {
                  return '신입';
                } else if (record.careerMarkId === '2') {
                  return '경력무관';
                } else {
                  return record.career;
                }
              },
            },
            {
              title: '연봉',
              dataIndex: 'salary',
              key: 'salary',
              render(_, record) {
                if (record.salaryNegoId === '1') {
                  return record.salary;
                } else if (record.salaryNegoId === '2') {
                  return '추후협의';
                } else {
                  return null;
                }
              },
            },
            {
              title: '광고사용여부',
              dataIndex: 'useAd',
              key: 'useAd',
              align: 'center',
              render(value: string) {
                return value === 'Y' ? <Tag color="green">사용</Tag> : <Tag color="red">미사용</Tag>;
              },
            },
            {
              title: '게시글 프리미엄 광고 사용여부',
              dataIndex: 'useTalkPremiumAd',
              key: 'useTalkPremiumAd',
              align: 'center',
              render(value: string) {
                return value === 'Y' ? <Tag color="green">사용</Tag> : <Tag color="red">미사용</Tag>;
              },
            },
          ]}
        />
      </PageHeader>
    </Drawer>
  );
}
