import * as React from 'react';
import { Button, Modal, Table, Tag } from 'antd';
import DrawerHireList from './DrawerHireList';
import ModalAuthRequest from './ModalAuthRequest';
import { readAllMemberResumeCareer } from '../../../../../api';

const MEMBER_AUTH_TYPE = {
  APPROVED: 'Y',
  REJECTED: 'N',
  APPLY: 'R',
  NOT_APPLIED: '',
} as const;

export default function MemberListTable(props: any) {
  const { loading, dataSource, pagination } = props;
  const [drawerHireList, setDrawerHireList] = React.useState({
    visible: false,
    data: null,
  });
  const [modalAuthRequest, setModalAuthRequest] = React.useState({
    visible: false,
    authId: '0',
    memberId: -1,
  });

  const handleClickCareer = async (item: any) => {
    try {
      const res: any = await readAllMemberResumeCareer(item.id);
      Modal.info({
        width: 600,
        title: `${item.resumeName}님의 경력`,
        content: (
          <Table
            size="small"
            dataSource={res}
            pagination={false}
            columns={[
              {
                title: '입사일',
                dataIndex: 'startDate'
              },
              {
                title: '퇴사일',
                dataIndex: 'endDate',
              },
              {
                title: '회사명',
                dataIndex: 'companyName',
              },
              {
                title: '업무',
                dataIndex: 'task',
              },
              {
                title: '연봉',
                dataIndex: 'salary',
              },
            ]}
          />
        ),
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Table
        size="small"
        bordered={true}
        scroll={{ x: true }}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        rowKey="id"
        columns={[
          {
            title: 'No.',
            width: 50,
            rowSpan: 2,
            render: (_: any, __: any, index: number) => {
              return pagination.total - 20 * (pagination.current - 1) - index;
            },
          },
          {
            title: '기본정보',
            children: [
              {
                title: '등록일',
                dataIndex: 'createDate',
                width: 160,
              },
              {
                title: '탈퇴일',
                dataIndex: 'deleteDate',
                width: 160,
              },
              {
                title: '닉네임',
                dataIndex: 'nickName',
                width: 160,
              },
              {
                title: '연락처',
                dataIndex: 'phone',
                width: 160,
              },
              {
                title: '이메일',
                dataIndex: 'email',
                width: 200,
              },
              {
                title: '마케팅동의/거부날짜',
                dataIndex: 'termMarketingDate',
                width: 160,
              },
              {
                title: '앱푸쉬',
                dataIndex: 'isAppPush',
                width: 80,
                align: 'center',
                render(value) {
                  return value === 'Y' ? <Tag color="success">동의</Tag> : <Tag color="red">미동의</Tag>;
                },
              },
              {
                title: '글작성갯수',
                dataIndex: 'talkCount',
                width: 100,
                key: 'talkCount',
              },
              {
                title: '댓글작성갯수',
                dataIndex: 'talkCommentCount',
                key: 'talkCommentCount',
                width: 100,
              },
              {
                title: '인증신청',
                dataIndex: 'authStatus',
                key: 'authStatus',
                width: 100,
                render: (value, record) => {
                  const label = (type: any) => {
                    switch (type) {
                      case MEMBER_AUTH_TYPE.APPLY:
                        return '신청';
                      case MEMBER_AUTH_TYPE.REJECTED:
                        return '거절';
                      case MEMBER_AUTH_TYPE.APPROVED:
                        return '승인';
                      case MEMBER_AUTH_TYPE.NOT_APPLIED:
                        return '미신청';
                    }
                  };

                  if (value === MEMBER_AUTH_TYPE.NOT_APPLIED) {
                    return <span>{label(value)}</span>;
                  }

                  return (
                    <Button
                      type="text"
                      style={{ color: '#1890ff' }}
                      onClick={() =>
                        setModalAuthRequest({
                          visible: true,
                          authId: record.authId,
                          memberId: record.id,
                        })
                      }
                    >
                      {label(value)}
                    </Button>
                  );
                },
              },
            ],
          },
          {
            title: '이력서 정보',
            children: [
              {
                title: '이름',
                dataIndex: 'resumeName',
                ellipsis: true,
                width: 100,
              },
              {
                title: '연락처',
                dataIndex: 'resumePhone',
                ellipsis: true,
                width: 100,
              },
              {
                title: '생년월일',
                dataIndex: 'resumeBirthday',
                width: 100,
                align: 'center',
              },
              {
                title: '구직/재직',
                dataIndex: 'resumeJobStatusId',
                width: 80,
                align: 'center',
                render(value) {
                  if (value === 1) {
                    return '구직중';
                  } else if (value === 2) {
                    return '재직중';
                  } else {
                    return null;
                  }
                },
              },
              {
                title: '희망연봉',
                dataIndex: 'resumeHopeSalary',
                width: 100,
                render(_, record) {
                  if (record.resumeSalaryNegoId === 1) {
                    return record.resumeHopeSalary + '만원';
                  } else if (record.resumeSalaryNegoId === 2) {
                    return '추후협의';
                  } else {
                    return null;
                  }
                },
              },
              {
                title: '경력',
                dataIndex: 'resumeCareerDay',
                width: 100,
                render(value, record) {
                  let str = '';
                  if (Number(value) > 0) {
                    let careerMonth = Number(value) / 30;
                    if (careerMonth / 12 >= 1) {
                      str += `${(Number(careerMonth) / 12).toFixed(0)}년`;
                    }

                    if (careerMonth % 12 >= 1) {
                      str += `${(Number(careerMonth) % 12).toFixed(0)}개월`;
                    }
                  } else {
                    return '';
                  }
                  return <a onClick={() => handleClickCareer(record)}>{str}</a>;
                },
              },
              {
                title: '자기소개',
                dataIndex: 'resumeIntroduce',
                width: 100,

                render(value) {
                  return (
                    <a
                      onClick={() => {
                        Modal.info({
                          title: '자기소개',
                          content: value,
                        });
                      }}
                    >
                      보기
                    </a>
                  );
                },
              },
              {
                title: '스카웃',
                dataIndex: 'resumeUseAudition',
                width: 100,
                render(value) {
                  if (value === 'Y') {
                    return <Tag color="success">사용</Tag>;
                  } else if (value === 'N') {
                    return <Tag color="red">미사용</Tag>;
                  } else {
                    return null;
                  }
                },
              },
              {
                title: '지원과',
                dataIndex: 'resumeDepartmentNames',
                ellipsis: true,
                width: 100,
                render(value, record) {
                  if (record.useAudition === 'N') {
                    return null;
                  }

                  return value;
                },
              },
              {
                title: '모집직종',
                dataIndex: 'resumeOccupationNames',
                ellipsis: true,
                width: 100,
                render(value, record) {
                  if (record.useAudition === 'N') {
                    return null;
                  }

                  return value;
                },
              },
              {
                title: '구직 지원 수',
                dataIndex: 'resumeHireApplyCount',
                key: 'resumehireApplyCount',
                width: 100,
                render(value, record) {
                  return <a onClick={() => setDrawerHireList({ visible: true, data: record.id })}>{value}</a>;
                },
              },
            ],
          },
        ]}
      />
      <DrawerHireList {...drawerHireList} onRequestClose={() => setDrawerHireList({ visible: false, data: null })} />
      <ModalAuthRequest
        {...modalAuthRequest}
        onClose={() =>
          setModalAuthRequest({
            visible: false,
            authId: '0',
            memberId: -1,
          })
        }
      />
    </>
  );
}
