import * as React from 'react';
import { Space, DatePicker, Input, Button, Form, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { readAllAddressSidoSgguEmdongBySidoWithSggu, readAllAddressSidoSgguBySido, readAllAddressSido, readAllHospitalDepartment } from '../../../../api';
const { RangePicker } = DatePicker;

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
  onRequestCreate: () => void;
}
export default function CompanyListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch, onRequestCreate } = props;
  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };

  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          name: '',
          brn: '',
          dates: [null, null],
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="dates">
          <RangePicker locale={locale} />
        </Form.Item>
        <Form.Item name="name">
          <Input placeholder="업체명" />
        </Form.Item>
        <Form.Item name="brn">
          <Input placeholder="사업자번호" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={onRequestCreate}>
            업체등록
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
