import * as React from 'react';
import { Space, Button, Form, Select, Input, InputNumber } from 'antd';
import { readAllAppHospitalHireOccupation } from '../../../../api';

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
}
export default function MemberResumeListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch } = props;
  const [fields, setFields] = React.useState<any>({
    occupation: [],
  });
  const useAudition = Form.useWatch('useAudition', form);
  const salaryNegoId = Form.useWatch('salaryNegoId', form);
  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };
  React.useEffect(() => {
    const bootstrap = async () => {
      try {
        const occupation = await readAllAppHospitalHireOccupation();
        setFields({
          occupation,
        });
      } catch (e) {
        console.log(e);
      }
    };

    bootstrap();
  }, []);
  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          occupationIds: [],
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="name">
          <Input placeholder="이름" allowClear style={{ width: 100 }} />
        </Form.Item>
        <Form.Item name="occupationIds">
          <Select mode="multiple" placeholder="모집분야" allowClear style={{ minWidth: 100 }}>
            {fields.occupation.map((item: any) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="isScoutOpen">
          <Select placeholder="열람/미열람" allowClear>
            <Select.Option value="N">미열람</Select.Option>
            <Select.Option value="Y">열람중</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="jobStatusId">
          <Select placeholder="구직/재직" allowClear>
            <Select.Option value="1">구직중</Select.Option>
            <Select.Option value="2">재직중</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="careerDay">
          <Select placeholder="경력여부" allowClear>
            <Select.Option value="N">신입</Select.Option>
            <Select.Option value="1">1년이상</Select.Option>
            <Select.Option value="2">2년이상</Select.Option>
            <Select.Option value="3">3년이상</Select.Option>
            <Select.Option value="4">4년이상</Select.Option>
            <Select.Option value="5">5년이상</Select.Option>
            <Select.Option value="6">6년이상</Select.Option>
            <Select.Option value="7">7년이상</Select.Option>
            <Select.Option value="8">8년이상</Select.Option>
            <Select.Option value="9">9년이상</Select.Option>
            <Select.Option value="10">10년이상</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="salaryNegoId">
          <Select placeholder="연봉방식" allowClear>
            <Select.Option value="1">직접입력</Select.Option>
            <Select.Option value="2">추후협의</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="hopeSalaryStart" hidden={salaryNegoId != 1} style={{ marginRight: 0 }}>
          <InputNumber placeholder="만원" step={100} />~
        </Form.Item>
        <Form.Item name="hopeSalaryEnd" hidden={salaryNegoId != 1}>
          <InputNumber placeholder="만원" step={100} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
