import * as React from "react";
import { Space, DatePicker, Input, Select, Button, Form } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
}
export default function TalkListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch } = props;

  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };
  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          title: "",
          dates: ["", ""],
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="dates">
          <RangePicker locale={locale} />
        </Form.Item>
        <Form.Item name="nickName">
          <Input placeholder="작성자" />
        </Form.Item>
        <Form.Item name="contents">
          <Input placeholder="내용" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
