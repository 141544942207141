import React, { useEffect } from 'react';
import { Form, FormInstance, Input, Select } from 'antd';

interface IProps {
  form: FormInstance;
  dataSource: any;
  onRequestHospitalSelect: () => void;
}

export default function HospitalUserForm(props: IProps) {
  const { form, dataSource, onRequestHospitalSelect } = props;
  const [isFormReady, formReady] = React.useState(false);

  const renderHospital = () => {
    if (dataSource.id > 0) {
      return (
        <Form.Item name="hospitalName" label="병원명">
          <Input defaultValue={dataSource.hospitalName} readOnly />
        </Form.Item>
      );
    }

    return (
      <Form.Item name="hospitalName" label="병원명">
        <Input onClick={onRequestHospitalSelect} readOnly />
      </Form.Item>
    );
  };

  useEffect(() => {
    const bootstrap = async () => {
      form.setFieldsValue({
        hospitalId: dataSource.hospitalId,
        hospitalName: dataSource.hospitalName,
        name: dataSource.name,
        email: dataSource.email,
        phone: dataSource.phone,
      });
    };
    if (isFormReady) {
      bootstrap();
    }
  }, [isFormReady, dataSource]);
  useEffect(() => {
    const bootstrap = async () => {
      try {
        formReady(false);
      } catch (e) {
        console.log(e);
      } finally {
        formReady(true);
      }
    };

    bootstrap();
  }, []);

  return (
    <Form form={form} layout="vertical" name="form_hospital_user_modal">
      <Form.Item name="hospitalId" hidden={true}>
        <Input />
      </Form.Item>
      {renderHospital()}
      <Form.Item name="name" label="이름" rules={[{ required: true, message: '이름을 입력해주세요.' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="phone" label="연락처" rules={[{ required: true, message: '연락처를 입력해주세요.' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="email" label="이메일" rules={[{ required: true, message: '이메일을 입력해주세요.' }]}>
        <Input />
      </Form.Item>
      {dataSource.id < 1 && (
        <>
          <Form.Item name="newAccountPasswd" label="패스워드" rules={[{ required: true, message: '패스워드를 입력해주세요.' }]}>
            <Input type="password" />
          </Form.Item>
          <Form.Item name="reAccountPasswd" label="패스워드확인" rules={[{ required: true, message: '패스워드확인을 입력해주세요.' }]}>
            <Input type="password" />
          </Form.Item>
        </>
      )}
    </Form>
  );
}
