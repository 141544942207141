export const getTableColumnNo = (
  total: number,
  pageSize: number,
  current: number
) => {
  return {
    title: "No.",
    width : 50,
    render: (_: any, __: any, index: number) => {
      return total - pageSize * (current - 1) - index;
    },
  };
};

