import React from 'react';
import { Form, Modal, message } from 'antd';
import CastingForm from './CastingForm';
import { createHospitalHire, createHospitalHireByHospital, readHospitalHireByHospitalWithPK, updateHospital, updateHospitalHireByHospitalWithPK } from '../../../../api';
import SelectHospitalModal from './SelectHospitalModal';

export type FormType = {
  id?: number;
  hospitalId: number;
  startDate: string;
  endDate: string;
  occupationIds: [];
  salary: string;
  salaryNegoId: number;
  logo: string;
  detail: string;
  useAd: string;
  useTalkPremiumAd: string;
};
interface IProps {
  visible: boolean;
  data: any;
  onFinish: () => void;
  onRequestClose: () => void;
}

const initialValues = {
  id: 0,
  hospitalId: 0,
  startDate: '',
  endDate: '',
  occupationIds: [],
  salary: '',
  recruitId: 1,
  salaryNegoId: 1,
  careerMarkId: 1,
  logo: '',
  detail: '',
  useAd: 'N',
  useTalkPremiumAd: 'N'
} as FormType;
export default function CastingFormModal(props: IProps) {
  const { visible, data, onRequestClose, onFinish } = props;
  const isUpdated = data ? true : false;
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [hospitalModal, setHospitalModal] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<FormType>(initialValues);
  const [form] = Form.useForm();

  const resetFormValue = () => {
    setDataSource(initialValues);
    form.resetFields();
  };
  const handleLoad = async (data: any) => {
    try {
      setConfirmLoading(true);
      const res: any = await readHospitalHireByHospitalWithPK(data.hospitalId, data.id);
      setDataSource(res);
    } catch (e) {
      console.log(e);
    } finally {
      setConfirmLoading(false);
    }
  };
  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('recruitId', values.recruitId);
      for (let i = 0; i < values.occupationIds.length; i++) {
        formData.append('occupationIds', values.occupationIds[i]);
      }

      if (values.recruitId === 1) {
        formData.append('startDate', values.dates[0].format('YYYY-MM-DD'));
        formData.append('endDate', values.dates[1].format('YYYY-MM-DD'));
      }
      formData.append('careerMarkId', values.careerMarkId);
      if (values.careerMarkId === 1) {
        formData.append('career', values.career);
      }

      formData.append('salaryNegoId', values.salaryNegoId);
      if (values.salaryNegoId === 1) {
        formData.append('salary', values.salary);
      }

      if (values.mainImage && values.mainImage[0]) {
        formData.append('mainImage', values.mainImage[0].originFileObj);
      }
      if (values.detailImage && values.detailImage[0]) {
        formData.append('detailImage', values.detailImage[0].originFileObj);
      }

      formData.append('useAd', values.useAd);
      formData.append('useTalkPremiumAd', values.useTalkPremiumAd);
      if (values.useAd === 'Y' || values.useTalkPremiumAd === 'Y') {
        if (values.adImage && values.adImage[0]) {
          formData.append('adImage', values.adImage[0].originFileObj);
        } else {
          formData.append('useAd', 'N');
          formData.append('useTalkPremiumAd', 'N');
        }
      }
      if (data) {
        await updateHospitalHireByHospitalWithPK(formData, data.hospitalId, data.id);
      } else {
        await createHospitalHireByHospital(formData, values.hospitalId);
      }
      setConfirmLoading(false);
      message.info(`${isUpdated ? '수정' : '등록'} 되었습니다.`);
      onRequestClose();
      onFinish();
    } catch (e: any) {
      message.error(e.message);
    }
  };

  const handleCancel = () => {
    onRequestClose();
    resetFormValue();
  };

  React.useEffect(() => {
    if (visible) {
      form.resetFields();
      if (data) {
        handleLoad(data);
      } else {
        resetFormValue();
        setHospitalModal(true);
      }
    }
  }, [visible, data]);

  return (
    <>
      <SelectHospitalModal
        visible={hospitalModal}
        onSelect={(record: any) => {
          console.log(record);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            hospitalName: record.name,
            hospitalId: record.id,
          });
          setHospitalModal(false);
        }}
        onClose={() => setHospitalModal(false)}
      />
      <Modal visible={visible} confirmLoading={confirmLoading} title={`병원 정보 ${isUpdated ? '수정' : '등록'}`} okText={isUpdated ? '수정' : '등록'} cancelText="취소" onOk={handleOk} onCancel={handleCancel} width={686}>
        {visible && <CastingForm form={form} dataSource={dataSource} onRequestHospitalSelect={() => setHospitalModal(true)} />}
      </Modal>
    </>
  );
}
