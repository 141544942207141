import * as React from 'react';
import { Dropdown, Menu, Space, Table, Tooltip } from 'antd';
import { getTableColumnNo } from '../../../../components/Table';
import DrawerResumeListByHospital from './DrawerResumeListByHospital';
import DrawerHireListByHospital from './DrawerHireListByHospital';
import { deleteHospitalByPK } from '../../../../api';
import ModalAudition from './ModalAudition';

export default function HospitalListTable(props: any) {
  const { loading, dataSource, pagination, onRequestUpdate, onRequestRefresh } = props;
  const [drawerResumeList, setDrawerResumeList] = React.useState({
    visible: false,
    data: null,
  });
  const [drawerHireList, setDrawerHireList] = React.useState({
    visible: false,
    data: null,
  });
  const [modalAudition, setModalAudition] = React.useState({
    visible: false,
    hospitalId: null,
    adView: 0,
  });

  const handleClickRequestUpdate = (id: any) => {
    onRequestUpdate(id);
  };

  const handleClickRequestDelete = async (id: any) => {
    try {
      await deleteHospitalByPK(id);
      onRequestRefresh();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Table
        size="small"
        bordered={true}
        scroll={{ x: true }}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        rowKey="id"
        columns={[
          getTableColumnNo(pagination.total, 20, pagination.current),
          {
            title: '병원ID',
            dataIndex: 'id',
            render(value: number, record: any) {
              return (
                <Tooltip placement="topLeft" title="삭제 또는 수정하실 수 있는 메뉴가 나오십니다.">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="update" onClick={() => handleClickRequestUpdate(record.id)}>
                          수정
                        </Menu.Item>
                        <Menu.Item key="delete" onClick={() => handleClickRequestDelete(record.id)}>
                          삭제
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>{value}</Space>
                    </a>
                  </Dropdown>
                </Tooltip>
              );
            },
          },
          {
            title: '병원명',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '진료과목',
            dataIndex: 'departmentNames',
            key: 'departmentNames',
          },
          {
            title: '가입일',
            dataIndex: 'createDate',
            key: 'createDate',
          },
          {
            title: '연락처',
            dataIndex: 'tel',
            key: 'tel',
          },
          {
            title: '시/도',
            dataIndex: 'addressSidoName',
            key: 'addressSidoName',
          },
          {
            title: '군/구',
            dataIndex: 'addressSgguName',
            key: 'addressSgguName',
          },
          {
            title: '읍/면/동',
            dataIndex: 'addressEmdongName',
            key: 'addressEmdongName',
          },
          {
            title: '상세주소',
            dataIndex: 'addressDetail',
            key: 'addressDetail',
          },
          {
            title: '지하철역',
            dataIndex: 'subwayName',
            key: 'subwayName',
          },
          {
            title: '병원계정',
            dataIndex: 'hospitalUserEmail',
          },
          {
            title: '받은 이력서',
            dataIndex: 'hireResumeCount',
            key: 'hireResumeCount',
            render(value, item: any) {
              if (value < 1) {
                return 0;
              }
              return <a onClick={() => setDrawerResumeList({ visible: true, data: item.id })}>{value}</a>;
            },
          },
          {
            title: '진행중인 공고',
            dataIndex: 'hireCount',
            key: 'hireCount',
            render(value, item: any) {
              if (value < 1) {
                return 0;
              }
              return <a onClick={() => setDrawerHireList({ visible: true, data: item.id })}>{value}</a>;
            },
          },
          {
            title: '열람권',
            dataIndex: 'auditionViewCount',
            render(value, item: any) {
              return <a onClick={() => setModalAudition({ visible: true, hospitalId: item.id, adView: item.auditionViewCount })}>{value}</a>;
            },
          },
        ]}
      />
      <DrawerResumeListByHospital {...drawerResumeList} onRequestClose={() => setDrawerResumeList({ visible: false, data: null })} />
      <DrawerHireListByHospital {...drawerHireList} onRequestClose={() => setDrawerHireList({ visible: false, data: null })} />
      <ModalAudition {...modalAudition} onRefetch={onRequestRefresh} onClose={() => setModalAudition({ ...modalAudition, visible: false })} />
    </>
  );
}
