import * as React from 'react';
import { Table, Modal, Image, Typography, Tooltip, Dropdown, Menu, Space } from 'antd';
import { getTableColumnNo } from '../../../../../components/Table';

import DrawerCommentListByTalk from './DrawerCommentListByTalk';
import DrawerReplyListByTalk from './DrawerReplyListByTalk';
import DrawerReportListByTalk from '../../../../../components/DrawerReportListByTalk';
import ActiveSwitch from '../../components/ActiveSwitch';

const { Link } = Typography;
interface IProps {
  loading: boolean;
  dataSource: any;
  pagination: {
    showSizeChanger: boolean;
    hideOnSinglePage: boolean;
    total: number;
    pageSize: number;
    current: number;
    onChange: (page: number) => void;
  };
  onRequestRefresh: () => void;
  onRequestUpdate: (item: any) => void;
  onRequestDelete: (item: any) => void;
}
export default function TalkListTable(props: IProps) {
  const { loading, dataSource, pagination, onRequestRefresh, onRequestUpdate, onRequestDelete } = props;

  const [drawerCommentList, setDrawerCommentList] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [drawerReplyList, setDrawerReplyList] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [drawerReportList, setDrawerReportList] = React.useState<any>({
    visible: false,
    data: null,
  });
  const handleClickContent = (item: any) => {
    Modal.info({
      title: item.title,
      content: item.contents,
    });
  };

  const handleClickRequestUpdate = (item: any) => {
    if (onRequestUpdate) {
      onRequestUpdate(item);
    }
  };

  const handleClickRequestDelete = (item: any) => {
    if (onRequestDelete) {
      onRequestDelete(item);
    }
  };
  return (
    <>
      <Table
        rowKey={'id'}
        size="small"
        loading={loading}
        bordered={true}
        dataSource={dataSource}
        pagination={pagination}
        columns={[
          getTableColumnNo(pagination.total, 20, pagination.current),
          {
            title: '공지ID',
            dataIndex: 'id',
            render(value: number, record: any) {
              return (
                <Tooltip placement="topLeft" title="삭제 또는 수정하실 수 있는 메뉴가 나오십니다.">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => handleClickRequestUpdate(record)}>수정</Menu.Item>
                        <Menu.Item onClick={() => handleClickRequestDelete(record)}>삭제</Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>{value}</Space>
                    </a>
                  </Dropdown>
                </Tooltip>
              );
            },
          },
          {
            title: '작성일',
            dataIndex: 'createDate',
            width: 160,
            align: 'center',
          },
          {
            title: '제목/내용',
            width: 200,
            ellipsis: true,
            render(_, record) {
              return <a onClick={() => handleClickContent(record)}>{record.title}</a>;
            },
          },
          {
            title: '이미지',
            dataIndex: 'images',
            render(value) {
              if (value.length < 1) {
                return null;
              }

              return (
                <Image.PreviewGroup>
                  {value.map((image: any, index: number) => {
                    return <Image key={index.toString()} src={`${process.env.REACT_APP_API_BASE_IMAGE_URL}${image.filePath}`} style={{ width: 20, height: 20 }} />;
                  })}
                </Image.PreviewGroup>
              );
            },
          },
          {
            title: '노출여부',
            align: 'center',
            render(_, record, index) {
              return <ActiveSwitch data={record} onChange={onRequestRefresh} />;
            },
          },
          {
            title: '조회',
            dataIndex: 'hitCount',
            align: 'center',
          },
          {
            title: '댓글',
            dataIndex: 'commentCount',
            align: 'center',
            render(value, record) {
              if (value < 1) {
                return 0;
              }
              return <Link onClick={() => setDrawerCommentList({ visible: true, data: record.id })}>{value}</Link>;
            },
          },
          {
            title: '답글',
            dataIndex: 'replyCount',
            align: 'center',
            render(value, record) {
              if (value < 1) {
                return 0;
              }
              return <Link onClick={() => setDrawerReplyList({ visible: true, data: record.id })}>{value}</Link>;
            },
          },
        ]}
      />
      <DrawerCommentListByTalk {...drawerCommentList} onRequestClose={() => setDrawerCommentList({ visible: false, data: null })} />
      <DrawerReplyListByTalk {...drawerReplyList} onRequestClose={() => setDrawerReplyList({ visible: false, data: null })} />
      <DrawerReportListByTalk
        {...drawerReportList}
        onRequestClose={() => {
          setDrawerReportList({ visible: false, data: null });
          onRequestRefresh();
        }}
      />
    </>
  );
}
