import * as React from 'react';
import { Dropdown, Menu, Space, Table, Tooltip } from 'antd';
import { getTableColumnNo } from '../../../../components/Table';
import { deleteCompanyByPK } from '../../../../api';
import DrawerAdListByCompany from './DrawerAdListByCompany';

export default function HospitalListTable(props: any) {
  const { loading, dataSource, pagination, onRequestUpdate, onRequestRefresh } = props;
  const [adListDrawer, setAdListDrawer] = React.useState({ visible: false, data: null });
  const handleClickRequestUpdate = (id: any) => {
    onRequestUpdate(id);
  };

  const handleClickRequestDelete = async (id: any) => {
    try {
      await deleteCompanyByPK(id);
      onRequestRefresh();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Table
        size="small"
        bordered={true}
        scroll={{ x: true }}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        rowKey="id"
        columns={[
          getTableColumnNo(pagination.total, 20, pagination.current),
          {
            title: '업체ID',
            dataIndex: 'id',
            render(value: number, record: any) {
              return (
                <Tooltip placement="topLeft" title="삭제 또는 수정하실 수 있는 메뉴가 나오십니다.">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="update" onClick={() => handleClickRequestUpdate(record.id)}>
                          수정
                        </Menu.Item>
                        <Menu.Item key="delete" onClick={() => handleClickRequestDelete(record.id)}>
                          삭제
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>{value}</Space>
                    </a>
                  </Dropdown>
                </Tooltip>
              );
            },
          },
          {
            title: '업체명',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '사업자번호',
            dataIndex: 'brn',
          },
          {
            title: '등록일',
            dataIndex: 'createDate',
          },
          {
            title: '광고수',
            dataIndex: 'adCount',
            render(value, item: any) {
              if (value < 1) {
                return 0;
              }
              return <a onClick={() => setAdListDrawer({ visible: true, data: item.id })}>{value}</a>;
            },
          },
        ]}
      />
      <DrawerAdListByCompany {...adListDrawer} onRequestClose={() => setAdListDrawer({ visible: false, data: null })} />
    </>
  );
}
