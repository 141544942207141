import api from '../../service/api';
import { IAd, IMemberAuthResponse, INotice, IPagingResponse } from '../../service/type';

export const readAllMember = (params: any) => api.get(`/members`, { params });
export const readAllMemberResume = (params: any) => api.get(`/members/resumes`, { params });
export const readAllMemberResumeByMember = (memberId: number) => api.get(`/members/${memberId}resumes`);
export const readAllMemberResumeCareer = (memberId: number) => api.get(`/members/${memberId}/resumes/careers`);
export const readAllMemberHospitalHireByMember = (memberId: number) => api.get(`/members/${memberId}/hospitals/hires`);
export const readAllMemberHireByMember = (memberId: number) => api.get(`members/${memberId}/hires`);

export const readAllHospital = (params: any) => api.get('/hospitals', { params });
export const readAllHospitalUser = (params: any) => api.get('/hospitals/users', { params });
export const readAllHospitalHire = (params: any) => api.get(`/hospitals/hires`, { params });
export const readAllHospitalHireByHospital = (hospitalId: any, params: any) => api.get(`/hospitals/${hospitalId}/hires`, { params });
export const readAllHospitalHireResume = (params: any) => api.get('/hospitals/hires/resumes', { params });
export const readAllHospitalHireResumeByHospital = (hospitalId: any) => api.get(`/hospitals/${hospitalId}/hires/resumes`);
export const readAllHospitalHireResumeByHospitalWithHire = (hospitalId: number, hospitalHireId: number) => api.get(`/hospitals/${hospitalId}/hires/${hospitalHireId}/resumes`);

export const readAllTalk = (params: any) => api.get('/talks', { params });
export const readAllTalkComment = (params: any) => api.get('/talks/comments', { params });
export const readAllTalkCommentReply = (params: any) => api.get('/talks/comments/replies', { params });
export const readAllTalkCommentByTalk = (talkId: number) => api.get(`talks/${talkId}/comments`);
export const readAllTalkCommentReplyByTalk = (talkId: number) => api.get(`talks/${talkId}/comments/replies`);
export const readAllTalkCommentReplyByTalkWithComment = (a: number, b: number) => api.get(`talks/${a}/comments/${b}/replies`);
export const readAllTalkReportByTalk = (a: number) => api.get(`talks/${a}/reports`);
export const readAllTalkReportByTalkWithComment = (a: number, b: number) => api.get(`talks/${a}/comments/${b}/reports`);
export const readAllTalkReportByTalkWithCommentWithReply = (a: number, b: number, c: number) => api.get(`talks/${a}/comments/${b}/replies/${c}/reports`);
export const readAllTalkNotice = (params: any): Promise<IPagingResponse<INotice[]>> => api.get('/talks/notices', { params });

export const readAllQuestion = (params: any) => api.get('/questions', { params });
export const readAllQuestionComment = (params: any) => api.get('/questions/comments', { params });
export const readAllQuestionCommentReply = (params: any) => api.get('/questions/comments/replies', { params });
export const readAllQuestionCommentByQuestion = (questionId: number) => api.get(`questions/${questionId}/comments`);
export const readAllQuestionCommentReplyByQuestion = (questionId: number) => api.get(`questions/${questionId}/comments/replies`);
export const readAllQuestionCommentReplyByQuestionWithComment = (a: number, b: number) => api.get(`questions/${a}/comments/${b}/replies`);
export const readAllQuestionReportByQuestion = (a: number) => api.get(`questions/${a}/reports`);
export const readAllQuestionReportByQuestionWithComment = (a: number, b: number) => api.get(`questions/${a}/comments/${b}/reports`);
export const readAllQuestionReportByQuestionWithCommentWithReply = (a: number, b: number, c: number) => api.get(`questions/${a}/comments/${b}/replies/${c}/reports`);

export const readAllYoutube = (params: any) => api.get('/youtubes', { params });
export const readAllYoutubeComment = (params: any) => api.get('/youtubes/comments', { params });
export const readAllYoutubeCommentReply = (params: any) => api.get('/youtubes/comments/replies', { params });
export const readAllYoutubeCommentByYoutube = (talkId: number) => api.get(`youtubes/${talkId}/comments`);
export const readAllYoutubeCommentReplyByYoutube = (talkId: number) => api.get(`youtubes/${talkId}/comments/replies`);
export const readAllYoutubeCommentReplyByYoutubeWithComment = (a: number, b: number) => api.get(`youtubes/${a}/comments/${b}/replies`);
export const readAllYoutubeReportByYoutube = (a: number) => api.get(`youtubes/${a}/reports`);
export const readAllYoutubeReportByYoutubeWithComment = (a: number, b: number) => api.get(`youtubes/${a}/comments/${b}/reports`);
export const readAllYoutubeReportByYoutubeWithCommentWithReply = (a: number, b: number, c: number) => api.get(`youtubes/${a}/comments/${b}/replies/${c}/reports`);

export const readAllCompany = (params: any) => api.get('/companies', { params });
export const readAllCompanyAd = (params: any): Promise<IPagingResponse<IAd[]>> => api.get('/companies/ads', { params });
export const readAllCompanyAdByCompany = (companyId: number) => api.get(`/companies/${companyId}/ads`);

export const readAllAdPosition = () => api.get('/app/ads/positions');
export const readYoutube = (id: number) => api.get(`/youtubes/${id}`);
export const getCastingCategories = () => api.get('/app/hospitals/hires/occupations');

export const readMemberAuthByMemberWithPK = (memberId: number, authId: string): Promise<IMemberAuthResponse> => api.get(`/members/${memberId}/auths/${authId}`);

export const readHospitalByPK = (hospitalId: number) => api.get(`/hospitals/${hospitalId}`);
export const readHospitalUserByHospitalWithPK = (hospitalId: number, id: number) => api.get(`/hospitals/${hospitalId}/users/${id}`);
export const readHospitalHireResumeByPK = (id: number): Promise<any> => api.get(`/hospitals/hires/resumes/${id}`);
export const readHospitalHireByPK = (id: number) => api.get(`/hospitals/hires/${id}`);
export const readHospitalHireByHospitalWithPK = (hospitalId: number, id: number) => api.get(`/hospitals/${hospitalId}/hires/${id}`);

export const readCompanyAdByCompanyWithPK = (companyId: number, adId: number) => api.get(`/companies/${companyId}/ads/${adId}`);

export const readCompanyByPK = (id: number) => api.get(`/companies/${id}`);

export const updateCompanyByPK = (data: any, id: number) => api.put(`/companies/${id}`, data);

export const createCompany = (data: any) => api.post('/companies', data);
export const createHospital = (data: any) => api.post('/hospitals', data);
export const createHospitalUserByHospital = (data: any, hospitalId: number) => api.post(`/hospitals/${hospitalId}/users`, data);
export const createHospitalHire = (data: any) =>
  api.post('/hospitals/hires', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const createHospitalHireByHospital = (data: any, hospitalId: number) =>
  api.post(`/hospitals/${hospitalId}/hires`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const createCompanyAd = (data: any, companyId: number) =>
  api.post(`/companies/${companyId}/ads`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const createHospitalAuditionViewChargingByHospital = (hospitalId: number, params: any) => api.post(`/hospitals/${hospitalId}/audition-view-charing`, params);

export const createYoutube = (data: any) => api.post('/youtubes', data);
export const updateYoutube = (data: any, id: number) => api.put(`/youtubes/${id}`, data);
export const deleteYoutube = (id: number) => api.delete(`/youtubes/${id}`);

export const updateCompanyAdByCompanyWithPK = (data: any, companyId: number, id: number) =>
  api.put(`/companies/${companyId}/ads/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const changeCompanyAdActiveByCompanyWithPK = (companyId: number, id: number, value: string) => api.patch(`/companies/${companyId}/ads/${id}/isActive/${value}`);

export const updateHospitalByPK = (data: any, hospitalId: number) => api.put(`/hospitals/${hospitalId}`, data);
export const updateHospitalUserByHospitalWithPK = (data: any, hospitalId: number, id: number) => api.put(`/hospitals/${hospitalId}/users/${id}`, data);
export const updateHospitalUserPasswordByHospitalWithPK = (data: any, hospitalId: number, id: number) => api.patch(`/hospitals/${hospitalId}/users/${id}/password`, data);

export const updateHospitalHireByPK = (data: any, id: number) =>
  api.put(`/hospitals/hires/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const updateHospitalHireByHospitalWithPK = (data: any, hospitalId: number, id: number) =>
  api.put(`/hospitals/${hospitalId}/hires/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const updateTalkStatus = (a: number, b: string) => api.patch(`talks/${a}/status/${b}`);
export const updateQuestionStatus = (a: number, b: string) => api.patch(`questions/${a}/status/${b}`);

export const updateTalkHot = (id: number, state: 'Y' | 'N'): Promise<any> => api.patch(`talks/${id}/hots/${state}`);
export const updateQuestionHot = (id: number, state: 'Y' | 'N'): Promise<any> => api.patch(`questions/${id}/hots/${state}`);

export const updateTalkCommentStatus = (a: number, b: number, c: string) => api.patch(`talks/${a}/comments/${b}/status/${c}`);
export const updateTalkCommentReplyStatus = (talkId: number, commentId: number, replyId: number, status: string) => api.patch(`talks/${talkId}/comments/${commentId}/replies/${replyId}/status/${status}`);
export const updateQuestionCommentStatus = (a: number, b: number, c: string) => api.patch(`questions/${a}/comments/${b}/status/${c}`);
export const updateQuestionCommentReplyStatus = (talkId: number, commentId: number, replyId: number, status: string) => api.patch(`questions/${talkId}/comments/${commentId}/replies/${replyId}/status/${status}`);
export const updateYoutubeCommentStatus = (a: number, b: number, c: string) => api.patch(`youtubes/${a}/comments/${b}/status/${c}`);
export const updateYoutubeCommentReplyStatus = (talkId: number, commentId: number, replyId: number, status: string) => api.patch(`youtubes/${talkId}/comments/${commentId}/replies/${replyId}/status/${status}`);

export const approveMemberAuth = (memberId: number, authId: string): Promise<any> => api.patch(`/members/${memberId}/auths/${authId}/status/approved`);
export const rejectMemberAuth = (memberId: number, authId: string): Promise<any> => api.patch(`/members/${memberId}/auths/${authId}/status/reject`);
export const modifyNoticeActiveState = (noticeId: number, isActive: 'Y' | 'N'): Promise<any> => api.patch(`/talks/notices/${noticeId}/isActive/${isActive}`);

export const registerNotice = (formData: FormData): Promise<any> =>
  api.post('/talks/notices', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const modifyNotice = (noticeId: number, formData: FormData): Promise<any> =>
  api.put(`/talks/notices/${noticeId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const blindTalkCommentByAdmin = (talkId: number, commentId: number): Promise<any> => api.post(`talks/${talkId}/comments/${commentId}/blind`);
export const blindTalkCommentReplyByAdmin = (talkId: number, commentId: number, replyId: number): Promise<any> => api.post(`talks/${talkId}/comments/${commentId}/replies/${replyId}/blind`);

export const blindQuestionCommentByAdmin = (talkId: number, commentId: number): Promise<any> => api.post(`questions/${talkId}/comments/${commentId}/blind`);
export const blindQuestionCommentReplyByAdmin = (talkId: number, commentId: number, replyId: number): Promise<any> => api.post(`questions/${talkId}/comments/${commentId}/replies/${replyId}/blind`);

export const blindYoutubeCommentByAdmin = (talkId: number, commentId: number): Promise<any> => api.post(`youtubes/${talkId}/comments/${commentId}/blind`);
export const blindYoutubeCommentReplyByAdmin = (talkId: number, commentId: number, replyId: number): Promise<any> => api.post(`youtubes/${talkId}/comments/${commentId}/replies/${replyId}/blind`);

export const registerCompany = (data: any): Promise<any> => api.post('/companies', data);
export const registerAd = (id: number, data: FormData) =>
  api.post(`/companies/${id}/ads`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const modifyAd = (companyId: number, adId: number, data: FormData) =>
  api.put(`/companies/${companyId}/ads/${adId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const modifyAdActiveState = (companyId: number, adId: number, isActive: string) => api.patch(`/companies/${companyId}/ads/${adId}/isActive/${isActive}`);

export const changeHospitalHireIsActive = (hospitalId: number, hireId: number, value: string) => api.patch(`/hospitals/${hospitalId}/hires/${hireId}/isActive/${value}`);

export const registerCasting = (id: string, data: FormData): Promise<any> =>
  api.post(`/hospitals/${id}/hires`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const registerCastingByHospital = (data: FormData): Promise<any> =>
  api.post(`/hospitals/hires`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const modifyCasting = (hospitalId: number, hireId: number, data: FormData): Promise<any> =>
  api.put(`/hospitals/${hospitalId}/hires/${hireId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const modifyCastingByHospital = (hireId: number, data: FormData): Promise<any> =>
  api.put(`/hospitals/hires/${hireId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const modifyCastingStatus = (hospitalId: number, hireId: number, value: 'Y' | 'N'): Promise<any> => api.patch(`/hospitals/${hospitalId}/hires/${hireId}/isActive/${value}`);

export const castingInspectByAdmin = (hospitalId: number, hireId: number, inspect: number): Promise<any> => api.patch(`/hospitals/${hospitalId}/hires/${hireId}/inspect/${inspect}`);

export const deleteCompanyAdByCompanyWithPK = (companyId: number, id: number) => api.delete(`/companies/${companyId}/ads/${id}`);
export const deleteCompanyByPK = (id: number) => api.delete(`/companies/${id}`);
export const deleteHospitalHireByPK = (hospitalId: number, id: number) => api.delete(`/hospitals/${hospitalId}/hires/${id}`);
export const deleteAd = (companyId: number, adId: number) => api.delete(`/companies/${companyId}/ads/${adId}`);
export const deleteCasting = (hospitalId: string | number, hireId: string | number) => api.delete(`/hospitals/${hospitalId}/hires/${hireId}`);
export const deleteNotice = (noticeId: number): Promise<any> => api.delete(`/talks/notices/${noticeId}`);
export const deleteHospitalByPK = (hospitalId: number) => api.delete(`/hospitals/${hospitalId}`);
export const deleteHospitalHireByHospitalWithPK = (hospitalId: number, id: number) => api.delete(`/hospitals/${hospitalId}/hires/${id}`);
export const deleteHospitalUserByHospitalWithPK = (hospitalId: number, id: number) => api.delete(`/hospitals/${hospitalId}/users/${id}`);