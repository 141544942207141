import * as React from 'react';
import { Form } from 'antd';
import moment from 'moment';
import Page from '../../../components/Page';
import HospitalListTable from './HospitalListTable';
import HospitalListForm from './HospitalListForm';
import HospitalFormModal from './HospitalFormModal';
import { readAllHospital } from '../../../api';

export default function HospitalPage() {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<any>({
    page: 1,
    total: 0,
    items: [],
  });

  const [hospitalFormModal, setHospitalFormModal] = React.useState<any>({
    visible: false,
    data: null,
  });
  const onSearch = async (nextPage: number) => {
    try {
      setLoading(true);
      const nextFormValues = form.getFieldsValue();
      const startDate = moment(nextFormValues.dates[0]).format('YYYY-MM-DD');
      const endDate = moment(nextFormValues.dates[1]).format('YYYY-MM-DD');
      delete nextFormValues.dates;
      const nextResponse: any = await readAllHospital({
        ...nextFormValues,
        startDate: startDate === 'Invalid date' ? '' : startDate,
        endDate: endDate === 'Invalid date' ? '' : endDate,
        page: nextPage,
      });
      setResponse({
        page: nextPage,
        total: nextResponse.total,
        items: nextResponse.items,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    onSearch(1);
  }, []);
  return (
    <Page title="병원관리" subTitle="병원관리 리스트">
      <HospitalListForm form={form} searchLoading={loading} onRequestSearch={() => onSearch(response.page)} onRequestCreate={() => setHospitalFormModal({ visible: true, data: null })} />
      <HospitalListTable
        loading={loading}
        dataSource={response.items}
        pagination={{
          position: ['bottomCenter'],
          current: response.page,
          total: response.total,
          pageSize: 20,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: onSearch,
        }}
        onRequestRefresh={() => onSearch(response.page)}
        onRequestUpdate={(hospitalId: number) => setHospitalFormModal({ visible: true, data: hospitalId })}
      />
      <HospitalFormModal {...hospitalFormModal} onRequestClose={() => setHospitalFormModal({ visible: false, data: null })} onFinish={() => onSearch(response.page)} />
    </Page>
  );
}
