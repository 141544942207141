import React, { useEffect } from 'react';
import { Form, FormInstance, Input, Select } from 'antd';

interface IProps {
  form: FormInstance;
  dataSource: any;
}

export default function CompanyForm(props: IProps) {
  const { form, dataSource } = props;
  const [isFormReady, formReady] = React.useState(false);

  useEffect(() => {
    const bootstrap = async () => {
      try {
        //
      } catch (e) {
        console.log(e);
      } finally {
        form.setFieldsValue({
          name: dataSource.name,
          brn: dataSource.brn,
        });
      }
    };
    if (isFormReady) {
      bootstrap();
    }
  }, [isFormReady, dataSource]);
  useEffect(() => {
    const bootstrap = async () => {
      try {
        formReady(false);
      } catch (e) {
        console.log(e);
      } finally {
        formReady(true);
      }
    };

    bootstrap();
  }, []);

  return (
    <Form form={form} layout="vertical" name="form_brand_modal">
      <Form.Item name="name" label="업체명" rules={[{ required: true, message: '업체명을 입력해주세요.' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="brn" label="사업자번호" rules={[{ required: true, message: '사업자번호를 입력해주세요.' }]}>
        <Input />
      </Form.Item>
    </Form>
  );
}
