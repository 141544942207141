import * as React from "react";
import { Table, Modal, Typography } from "antd";
import { getTableColumnNo } from "../../../../../components/Table";
import TalkStatusSelect from "../../../../../components/TalkStatusSelect";
import DrawerReplyListByTalkWithComment from "../../../../../components/DrawerReplyListByTalkWithComment";
import DrawerReportListByTalkWithComment from "../../../../../components/DrawerReportListByTalkWithComment";

const { Link } = Typography;
interface IProps {
  loading: boolean;
  dataSource: any;
  pagination: {
    showSizeChanger: boolean;
    hideOnSinglePage: boolean;
    total: number;
    pageSize: number;
    current: number;
    onChange: (page: number) => void;
  };
  onRequestRefresh: () => void;
}
export default function TalkListTable(props: IProps) {
  const { loading, dataSource, pagination, onRequestRefresh } = props;

  const [drawerCommentList, setDrawerCommentList] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [drawerReplyList, setDrawerReplyList] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [drawerReportList, setDrawerReportList] = React.useState<any>({
    visible: false,
    data: null,
  });
  const handleClickContent = (item: any) => {
    Modal.info({
      title: "댓글내용",
      content: item.contents,
    });
  };

  return (
    <>
      <Table
        rowKey={"id"}
        size="small"
        loading={loading}
        bordered={true}
        dataSource={dataSource}
        pagination={pagination}
        columns={[
          getTableColumnNo(pagination.total, 20, pagination.current),
          {
            title: "작성일",
            dataIndex: "createDate",
            width: 160,
            align: "center",
          },
          {
            title: "내용",
            width: 200,
            ellipsis: true,
            render(_, record) {
              return (
                <a onClick={() => handleClickContent(record)}>
                  {record.contents}
                </a>
              );
            },
          },
          {
            title: "작성자",
            render(_, item) {
              return `${item.nickName}(${item.email})`;
            },
          },
          {
            title: "신고",
            dataIndex: "reportCount",
            align: "center",
            render(value, record) {
              if (value < 1) {
                return 0;
              }
              return (
                <Link
                  onClick={() =>
                    setDrawerReportList({
                      visible: true,
                      data: { talkId: record.talkId, commentId: record.id },
                    })
                  }
                >
                  {value}
                </Link>
              );
            },
          },
          {
            title: "블라인드",
            width: 120,
            align: "center",
            render(_, record) {
              return (
                <TalkStatusSelect
                  type="comment"
                  dataSource={{
                    talkId: record.talkId,
                    commentId: record.id,
                    value: record.statusId,
                  }}
                  reportCount={record.reportCount}
                  onFinish={onRequestRefresh}
                />
              );
            },
          },
          {
            title: "답글",
            dataIndex: "replyCount",
            align: "center",
            render(value, record) {
              if (value < 1) {
                return 0;
              }
              return (
                <Link
                  onClick={() =>
                    setDrawerReplyList({
                      visible: true,
                      data: { talkId: record.talkId, commentId: record.id },
                    })
                  }
                >
                  {value}
                </Link>
              );
            },
          },
        ]}
      />
      <DrawerReplyListByTalkWithComment
        {...drawerReplyList}
        onRequestClose={() =>
          setDrawerReplyList({ visible: false, data: null })
        }
      />
      <DrawerReportListByTalkWithComment
        {...drawerReportList}
        onRequestClose={() => {
          setDrawerReportList({ visible: false, data: null });
          onRequestRefresh();
        }}
      />
    </>
  );
}
