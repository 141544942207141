//REACT
import React, { useState } from 'react';

//COMPONENTS
import { Modal } from '../../../../../components';

//STYLE
import styled from 'styled-components';
import { Input } from 'antd';

//API
import { createHospitalAuditionViewChargingByHospital } from '../../../../../api';

//REACT-QUERY
import { useMutation } from 'react-query';

interface IInputItem {
  label: string;
  item: React.ReactElement;
}

const InputItem: React.FC<IInputItem> = ({ label, item }) => {
  return (
    <ItemWrap>
      <ItemLabel>{label}</ItemLabel>
      {item}
    </ItemWrap>
  );
};

const ModalAudition: React.FC<any> = ({ visible, onClose, hospitalId, onRefetch, adView }) => {
  const [editAuditionCnt, setEditAuditionCnt] = useState<number>(0);

  const adViewMutation = useMutation(({ id, value }: any) => createHospitalAuditionViewChargingByHospital(id, { value: value }));
  const handleSubmit = () => {
    adViewMutation.mutate({ id: hospitalId, value: editAuditionCnt });
    setEditAuditionCnt(0);
    setTimeout(onRefetch, 300);
    onClose();
  };

  return (
    <Modal
      title={'열람권 수정'}
      onClose={onClose}
      visible={visible}
      buttons={[
        { label: '닫기', onClick: onClose },
        { label: '등록', onClick: handleSubmit, type: 'primary' },
      ]}
    >
      <InputItem label="현재 열람권" item={<Input placeholder="열람권" value={adView} type="number" disabled style={{ width: '100%', background: 'white', color: 'black' }} />} />
      <InputItem label="열람권 추가 갯수" item={<Input placeholder="열람권" value={editAuditionCnt} type="number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditAuditionCnt(Number(e.target.value))} style={{ width: '100%' }} />} />
    </Modal>
  );
};

export default ModalAudition;

const ItemWrap = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const ItemLabel = styled.span`
  flex: 0 0 100px;
`;
