import * as React from 'react';
import { Space, DatePicker, Input, Button, Form, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { readAllAddressSidoSgguEmdongBySidoWithSggu, readAllAddressSidoSgguBySido, readAllAddressSido, readAllHospitalDepartment } from '../../../../api';
const { RangePicker } = DatePicker;

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
  onRequestCreate: () => void;
}
export default function HospitalListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch, onRequestCreate } = props;
  const [fields, setFields] = React.useState<any>({
    department: [],
    sido: [],
    sggu: [],
    emdong: [],
  });
  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };
  const handleChangeSido = async (sido: number) => {
    try {
      const sggu = await readAllAddressSidoSgguBySido(sido);
      setFields({
        ...fields,
        sggu,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handleChangeSggu = async (sggu: number) => {
    try {
      const emdong = await readAllAddressSidoSgguEmdongBySidoWithSggu(form.getFieldsValue().sidoId, sggu);
      setFields({
        ...fields,
        emdong,
      });
    } catch (e) {
      console.log(e);
    }
  };
  React.useEffect(() => {
    const bootstrap = async () => {
      try {
        const department = await readAllHospitalDepartment();
        const sido = await readAllAddressSido();
        setFields({ ...fields, department, sido });
      } catch (e) {
        console.log(e);
      }
    };

    bootstrap();
  }, []);
  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          nickName: '',
          email: '',
          phone: '',
          dates: ['', ''],
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="dates">
          <RangePicker locale={locale} />
        </Form.Item>
        <Form.Item name="name">
          <Input placeholder="병원명" />
        </Form.Item>
        <Form.Item name="tel">
          <Input placeholder="연락처" />
        </Form.Item>
        <Form.Item name="departmentId">
          <Select placeholder="진료과목" style={{ width: 180 }}>
            {fields.department.map((item: any, index: number) => (
              <Select.Option key={index.toString()} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="sidoId">
          <Select placeholder="주소(시/도)" onChange={handleChangeSido}>
            {fields.sido.map((item: any) => (
              <Select.Option key={`sido-${item.id}`} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="sgguId">
          <Select placeholder="주소(군/구)" onChange={handleChangeSggu}>
            {fields.sggu.map((item: any) => (
              <Select.Option key={`sggu-${item.id}`} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="emdongId">
          <Select placeholder="주소(읍/면/동)">
            {fields.emdong.map((item: any) => (
              <Select.Option key={`emdong-${item.id}`} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={onRequestCreate}>
            병원등록
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
