import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ButtonType } from "antd/lib/button";

interface IButton {
  label: string;
  onClick: () => any;
  onClose?: () => any;
  type?: ButtonType;
  [key: string]: any;
}

export interface IModal {
  visible: boolean;
  title?: string;
  children: any;
  bodyStyle?: React.CSSProperties;
  onClose: () => void;
  buttons?: IButton[];
  customBottom?: React.ReactChild;
}

const Modal: React.FC<IModal> = ({
  visible,
  children,
  bodyStyle,
  title,
  onClose,
  buttons,
  customBottom,
}) => {
  if (!visible) {
    return <></>;
  }

  return (
    <ModalWrap onClick={onClose}>
      <ModalBody
        style={bodyStyle && bodyStyle}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <TitleWrap>
          <Title>{title}</Title>
        </TitleWrap>
        <ContentWrap>{children}</ContentWrap>
        {customBottom && customBottom}
        {buttons && (
          <ButtonWrap>
            {buttons.map(
              ({ label, onClick, type = "default", ...others }, idx) => (
                <Button
                  style={{ marginLeft: 5 }}
                  key={`modal-button-${idx}`}
                  type={type}
                  onClick={onClick}
                  {...others}
                >
                  {label}
                </Button>
              ),
            )}
          </ButtonWrap>
        )}
      </ModalBody>
    </ModalWrap>
  );
};

const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalBody = styled.div`
  width: 560px;
  background-color: #fff;
  border-radius: 5px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
`;

const TitleWrap = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 10px 16px;
`;

const ContentWrap = styled.div`
  padding: 16px;
  overflow-y: auto;
`;
const Title = styled.h3`
  margin-bottom: 0;
`;

const ButtonWrap = styled.div`
  border-top: 1px solid #ddd;
  padding: 10px 16px;
  display: flex;
  justify-content: flex-end;
`;

export default Modal;
