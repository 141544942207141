import * as React from 'react';
import { Space, DatePicker, Button, Form } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
const { RangePicker } = DatePicker;

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
}
export default function MessageSendListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch } = props;


  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };

  return (
    <Space size={1}>
      <Form form={form} layout="inline" onFinish={handleFinish} initialValues={{ dates: ['', '']}}>
        <Form.Item name="dates" >
          <RangePicker locale={locale} allowClear />
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
