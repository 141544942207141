import React from 'react';
import { Form, Modal, message } from 'antd';
import HospitalUserForm from './HospitalUserForm';
import { readHospitalUserByHospitalWithPK, createHospitalUserByHospital, updateHospitalUserByHospitalWithPK } from '../../../../api';
import SelectHospitalModal from './SelectHospitalModal';

export type FormType = {
  id?: number;
  name: string;
  hospitalId?: number;
  hospitalName: string;
  email: string;
  newPassword: string;
  rePassword: string;
};
interface IProps {
  visible: boolean;
  data: {
    hospitalId: number;
    id: number;
  };
  onFinish: () => void;
  onRequestClose: () => void;
}

const initialValues = {
  id: 0,
  name: '',
  hospitalId: 0,
  hospitalName: '',
  email: '',
  newPassword: '',
  rePassword: '',
} as FormType;
export default function HospitalUserFormModal(props: IProps) {
  const { visible, data, onRequestClose, onFinish } = props;
  const isUpdated = data && data.id > 0 ? true : false;
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [hospitalModal, setHospitalModal] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<FormType>(initialValues);
  const [form] = Form.useForm();

  const resetFormValue = () => {
    setDataSource(initialValues);
    form.resetFields();
  };
  const handleLoad = async (data: any) => {
    try {
      setConfirmLoading(true);
      const res: any = await readHospitalUserByHospitalWithPK(data.hospitalId, data.id);
      setDataSource(res);
    } catch (e) {
      console.log(e);
    } finally {
      setConfirmLoading(false);
    }
  };
  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (data.id > 0) {
        await updateHospitalUserByHospitalWithPK(values, data.hospitalId, data.id);
      } else {
        await createHospitalUserByHospital(values, values.hospitalId);
      }
      setConfirmLoading(false);
      message.info(`${isUpdated ? '수정' : '등록'} 되었습니다.`);
      onRequestClose();
      onFinish();
    } catch (e: any) {
      message.error(e.message);
    }
  };

  const handleCancel = () => {
    onRequestClose();
    resetFormValue();
  };

  React.useEffect(() => {
    if (visible) {
      form.resetFields();
      if (data.id > 0) {
        handleLoad(data);
      } else {
        resetFormValue();
      }
    }
  }, [visible, data]);

  return (
    <>
      <SelectHospitalModal
        visible={hospitalModal}
        onSelect={(record: any) => {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            hospitalName: record.name,
            hospitalId: record.id,
          });
          setHospitalModal(false);
        }}
        onClose={() => setHospitalModal(false)}
      />
      <Modal visible={visible} confirmLoading={confirmLoading} title={`병원 정보 ${isUpdated ? '수정' : '등록'}`} okText={isUpdated ? '수정' : '등록'} cancelText="취소" onOk={handleOk} onCancel={handleCancel}>
        {visible && <HospitalUserForm form={form} dataSource={dataSource} onRequestHospitalSelect={() => setHospitalModal(true)} />}
      </Modal>
    </>
  );
}
