import React from 'react';
import { Form, FormInstance, Input } from 'antd';

interface IProps {
  form: FormInstance;
  dataSource : any;
}

export default function MessageSendForm(props: IProps) {
  const { form,dataSource } = props; 

  return (
    <Form
      form={form}
      layout="vertical"
      name="form_message_send_modal"
      initialValues={{
        title: '',
        content: [],
      }}
    >
      <Form.Item name="title" rules={[{ required: true, message: '제목을 입력해주세요.' }]}>
        <Input placeholder='제목'/>
      </Form.Item>
      <Form.Item>
        to. {dataSource.name}
      </Form.Item>
      <Form.Item name="content" rules={[{ required: true, message: '내용을 입력해주세요.' }]}>
        <Input.TextArea placeholder='내용' rows={10}/>
      </Form.Item>
    </Form>
  );
}
