import axios from "axios";
import { STORAGE_KEYS } from "./constant";

export const setAuthToken = (token: string) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};
export const isAuthenticated = (): boolean =>
  localStorage.getItem(STORAGE_KEYS.TOKEN) ||
  sessionStorage.getItem(STORAGE_KEYS.TOKEN)
    ? true
    : false;

export const getToken = (): string =>
  localStorage.getItem(STORAGE_KEYS.TOKEN) ||
  sessionStorage.getItem(STORAGE_KEYS.TOKEN) ||
  "";

export const imageUrl = (image: string): string =>
  `${process.env.REACT_APP_API_BASE_IMAGE_URL}${image}`;

export const careerDateFormat = (count: number | string): string => {
  let year: number = 0;
  let month: number = 0;

  if (count >= 360) {
    year = Math.floor(+count / 360);
  }

  if (year >= 1) {
    month = Math.floor(+count / 30 - year * 12);
    return `${year}년 ${month}개월`;
  } else {
    month = Math.floor(+count / 30);
    return `${month}개월`;
  }
};
export const passWdRule = (str: string) => {
  var reg =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,15}$/;
  if (!reg.test(str)) {
    return true;
  }
};

export function numberWithCommas(x: any) {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
  return x;
}
