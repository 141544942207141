import * as React from 'react';
import { Space, DatePicker, Input, Button, Form, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
const { RangePicker } = DatePicker;

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
}
export default function MemberListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch } = props;

  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };
  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          nickName: '',
          email: '',
          phone: '',
          dates: ['', ''],
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="dates">
          <RangePicker locale={locale} />
        </Form.Item>
        <Form.Item name="nickName">
          <Input placeholder="닉네임" allowClear />
        </Form.Item>
        <Form.Item name="email">
          <Input placeholder="이메일" allowClear />
        </Form.Item>
        <Form.Item name="phone">
          <Input placeholder="연락처" allowClear />
        </Form.Item>
        <Form.Item name="authStatus">
          <Select placeholder="인증/미인증" allowClear>
            <Select.Option value={'R'}>인증신청</Select.Option>
            <Select.Option value={'Y'}>인증승인</Select.Option>
            <Select.Option value={'N'}>인증반려</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
