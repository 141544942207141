import api from '../../service/api';

import { ILoginPayload } from '../../service/type';

//TYPE
import { IAudition, IPagingResponse, IAuditionDetailResponse } from '../../service/type';
export const readAllHospitalHireOccupation = (): Promise<{ id: number; name: string }[]> => api.get('/app/hospitals/hires/occupations');
export const getAudtionList = (params: any): Promise<IPagingResponse<IAudition[]>> => api.get('/auditions', { params });
export const getAuditionDetail = (id: number): Promise<any> => api.get(`/auditions/${id}`);
export const login = (data: ILoginPayload): Promise<any> => api.post('/auth/login', data);

export const readAllAppHospitalHireOccupation = () => api.get('app/hospitals/hires/occupations');
export const readAllHospitalDepartment = () => api.get('/app/hospital/department');
export const readAllAddressSido = () => api.get('app/address/sido');
export const readAllAddressSidoSgguBySido = (sidoId: number) => api.get(`app/address/sido/${sidoId}/sggu`);
export const readAllAddressSidoSgguEmdongBySidoWithSggu = (sidoId: number, sgguId: number) => api.get(`app/address/sido/${sidoId}/sggu/${sgguId}/emdong`);
export const readAllSubway = () => api.get('/app/subway');
