import React, { useEffect } from 'react';
import { Form, Modal, message } from 'antd';
import { sendMessage } from '../../api';
import MessageSendForm from './MessageSendForm';

export type FormType = {
  memberId: number;
  name: string;
  title: string;
  content: string;
};
interface IProps {
  visible: boolean;
  data: any;
  onFinish: () => void;
  onRequestClose: () => void;
}

export default function MessageSendModal(props: IProps) {
  const { visible, data, onRequestClose, onFinish } = props;
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();

  const resetFormValue = () => {
    form.setFieldsValue({
      title: '',
      content: '',
    });
  };

  const handleOk = async () => {
    try {
      if (confirmLoading) {
        return;
      }
      setConfirmLoading(true);
      const values = await form.validateFields();
      await sendMessage({
        memberId: data.memberId,
        title: values.title,
        content: values.content,
      });
      setConfirmLoading(false);
      message.info(`전송 되었습니다.`);
      onRequestClose();
      onFinish();
    } catch (e: any) {
      Modal.error({
        title: '검증오류',
        content: e.message,
        onOk() {
          // onFinish();
        },
      });
      console.log(e);
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    onRequestClose();
    resetFormValue();
  };
  useEffect(() => {
    if (visible) {
      resetFormValue();
    }
  }, [visible]);
  return (
    <Modal visible={visible} confirmLoading={confirmLoading} title={`쪽지 보내기`} okText={'전송'} cancelText="취소" onOk={handleOk} onCancel={handleCancel} width={686}>
      {visible && <MessageSendForm form={form} dataSource={data} />}
    </Modal>
  );
}
