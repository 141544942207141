import * as React from 'react';
import { Table, Modal, Typography, Tooltip, Dropdown, Menu, Space } from 'antd';
import { getTableColumnNo } from '../../../../../components/Table';
import DrawerCommentListByYoutube from './DrawerCommentListByYoutube';
import DrawerReplyListByYoutube from './DrawerReplyListByYoutube';
import { deleteYoutube } from '../../../../../api';

const { Link } = Typography;
interface IProps {
  loading: boolean;
  dataSource: any;
  pagination: {
    showSizeChanger: boolean;
    hideOnSinglePage: boolean;
    total: number;
    pageSize: number;
    current: number;
    onChange: (page: number) => void;
  };
  onRequestRefresh: () => void;
  onRequestUpdate: (id: number) => void;
}
export default function YoutubeListTable(props: IProps) {
  const { loading, dataSource, pagination, onRequestRefresh, onRequestUpdate } = props;

  const [drawerCommentList, setDrawerCommentList] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [drawerReplyList, setDrawerReplyList] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [drawerReportList, setDrawerReportList] = React.useState<any>({
    visible: false,
    data: null,
  });

  const handleClickRequestUpdate = (id: any) => {
    onRequestUpdate(id);
  };

  const handleClickRequestDelete = async (id: any) => {
    try {
      await deleteYoutube(id);
      onRequestRefresh();
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickContent = (item: any) => {
    Modal.info({
      title: item.title,
      content: item.contents,
    });
  };

  return (
    <>
      <Table
        rowKey={'id'}
        size="small"
        loading={loading}
        bordered={true}
        dataSource={dataSource}
        pagination={pagination}
        columns={[
          getTableColumnNo(pagination.total, 20, pagination.current),
          {
            title: '영상ID',
            dataIndex: 'id',
            render(value: number, record: any) {
              return (
                <Tooltip placement="topLeft" title="삭제 또는 수정하실 수 있는 메뉴가 나오십니다.">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="update" onClick={() => handleClickRequestUpdate(record.id)}>
                          수정
                        </Menu.Item>
                        <Menu.Item key="delete" onClick={() => handleClickRequestDelete(record.id)}>
                          삭제
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>{value}</Space>
                    </a>
                  </Dropdown>
                </Tooltip>
              );
            },
          },
          {
            title: '생성일',
            dataIndex: 'createDate',
            width: 160,
            align: 'center',
          },
          {
            title: '제목',
            dataIndex: 'title',
            width: 200,
            ellipsis: true,
          },
          {
            title: 'URL',
            dataIndex: 'url'
          },
          {
            title: '조회',
            dataIndex: 'hitCount',
            align: 'center',
          },
          {
            title: '댓글',
            dataIndex: 'commentCount',
            align: 'center',
            render(value, record) {
              if (value < 1) {
                return 0;
              }
              return <Link onClick={() => setDrawerCommentList({ visible: true, data: record.id })}>{value}</Link>;
            },
          },
          {
            title: '답글',
            dataIndex: 'replyCount',
            align: 'center',
            render(value, record) {
              if (value < 1) {
                return 0;
              }
              return <Link onClick={() => setDrawerReplyList({ visible: true, data: record.id })}>{value}</Link>;
            },
          },
        ]}
      />
      <DrawerCommentListByYoutube {...drawerCommentList} onRequestClose={() => setDrawerCommentList({ visible: false, data: null })} />
      <DrawerReplyListByYoutube {...drawerReplyList} onRequestClose={() => setDrawerReplyList({ visible: false, data: null })} />
    </>
  );
}
