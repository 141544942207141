import * as React from 'react';
import { Space, DatePicker, Input, Select, Button, Form } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
}
export default function QuestionListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch } = props;

  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };
  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          title: '',
          dates: ['', ''],
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="dates">
          <RangePicker locale={locale} />
        </Form.Item>
        <Form.Item name="nickName">
          <Input placeholder="작성자" allowClear />
        </Form.Item>
        <Form.Item name="keyword">
          <Input placeholder="제목 또는 내용" allowClear />
        </Form.Item>
        <Form.Item name="isHot">
          <Select placeholder="인기글/여부" allowClear>
            <Select.Option value="Y">인기글/사용</Select.Option>
            <Select.Option value="N">인기글/미사용</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
