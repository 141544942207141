import React from 'react';
import { Form } from 'antd';
import moment from 'moment';
import Page from '../../../components/Page';
import CastingListForm from './CastingListForm';
import CastingListTable from './CastingListTable';
import CastingFormModal from './CastingFormModal';
import { readAllHospitalHire } from '../../../api';

const Casting: React.FC = () => {
  const [form] = Form.useForm();
  const [formModal, setFormModal] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<any>({
    page: 1,
    total: 0,
    items: [],
  });
  const onSearch = async (nextPage: number) => {
    try {
      setLoading(true);
      const nextFormValues = form.getFieldsValue();

      const startDate = moment(nextFormValues.dates[0]).format('YYYY-MM-DD');
      const endDate = moment(nextFormValues.dates[1]).format('YYYY-MM-DD');
      delete nextFormValues.dates;
      const nextResponse: any = await readAllHospitalHire({
        ...nextFormValues,
        startDate: startDate === 'Invalid date' ? '' : startDate,
        endDate: endDate === 'Invalid date' ? '' : endDate,
        page: nextPage,
      });
      setResponse({
        page: nextPage,
        total: nextResponse.total,
        items: nextResponse.items,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    onSearch(1);
  }, []);
  return (
    <>
      <Page title="공고관리" subTitle="공고관리 리스트">
        <CastingListForm form={form} searchLoading={loading} onRequestSearch={() => onSearch(response.page)} onRequestCreate={() => setFormModal({ visible: true, data: null })} />
        <CastingListTable
          loading={loading}
          dataSource={response.items}
          pagination={{
            position: ['bottomCenter'],
            current: response.page,
            total: response.total,
            pageSize: 20,
            hideOnSinglePage: true,
            showSizeChanger: false,
            onChange: onSearch,
          }}
          onRequestRefresh={() => onSearch(response.page)}
          onRequestUpdate={(hospitalId: number, id: number) => setFormModal({ visible: true, data: { hospitalId: hospitalId, id: id } })}
        />
        {formModal.visible && (
          <CastingFormModal
            {...formModal}
            onFinish={() => onSearch(response.page)}
            onRequestClose={() => {
              setFormModal({
                visible: false,
                data: null,
              });
            }}
          />
        )}
      </Page>
    </>
  );
};

export default Casting;
