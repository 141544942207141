import * as React from 'react';
import { Drawer, PageHeader, Table, Typography } from 'antd';
import { readAllQuestionCommentByQuestion } from '../../../../../../api';
import QuestionStatusSelect from '../../../../../../components/QuestionStatusSelect';
import DrawerReplyListByQuestionWithComment from '../../../../../../components/DrawerReplyListByQuestionWithComment';
import DrawerReportListByQuestionWithComment from '../../../../../../components/DrawerReportListByQuestionWithComment';
const { Link } = Typography;
export default function DrawerCommentListByQuestion(props: any) {
  const { visible, data, onRequestClose } = props;
  const [reportListByQuestionWithComment, setReportListByQuestionWithComment] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [replyListByQuestionWithComment, setReplyListByQuestionWithComment] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any>([]);
  const handleBack = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const bootstrap = async () => {
    try {
      setLoading(true);
      const res = await readAllQuestionCommentByQuestion(data);
      setItems(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible) {
      bootstrap();
    }
  }, [visible]);

  return (
    <>
      <Drawer visible={visible} getContainer={false} width={'100%'} closable={false} style={{ position: 'absolute' }}>
        <PageHeader ghost={false} onBack={handleBack} title={`질문의 댓글 목록`}>
          <Table
            size="small"
            bordered={true}
            dataSource={items}
            loading={loading}
            pagination={false}
            columns={[
              {
                title: 'No',
                render(_, __, index: number) {
                  return items.length - index;
                },
              },
              {
                title: '작성일자',
                dataIndex: 'createDate',
              },
              {
                title: '작성자',
                dataIndex: 'nickName',
              },
              {
                title: '내용',
                dataIndex: 'contents',
                ellipsis: true,
                width: 200,
              },
              {
                title: '신고',
                dataIndex: 'reportCount',
                render(value, record) {
                  if (value < 1) {
                    return 0;
                  }

                  return (
                    <a
                      onClick={() =>
                        setReportListByQuestionWithComment({
                          visible: true,
                          data: { questionId: record.questionId, commentId: record.id },
                        })
                      }
                    >
                      {value}
                    </a>
                  );
                },
              },
              {
                title: '블라인드',
                render(_, record) {
                  return (
                    <QuestionStatusSelect
                      type="comment"
                      dataSource={{
                        questionId: record.questionId,
                        commentId: record.id,
                        value: record.statusId,
                      }}
                      reportCount={record.reportCount}
                      onFinish={bootstrap}
                    />
                  );
                },
              },
              {
                title: '답글',
                dataIndex: 'replyCount',
                render(_, record) {
                  if (record.replyCount < 1) {
                    return record.replyCount;
                  }

                  return (
                    <Link
                      onClick={() =>
                        setReplyListByQuestionWithComment({
                          visible: true,
                          data: { questionId: record.questionId, commentId: record.id },
                        })
                      }
                    >
                      {record.replyCount}
                    </Link>
                  );
                },
              },
            ]}
          />
        </PageHeader>
      </Drawer>
      <DrawerReportListByQuestionWithComment
        {...reportListByQuestionWithComment}
        onRequestClose={() => {
          setReportListByQuestionWithComment({ visible: false, data: null });
          bootstrap();
        }}
      />
      <DrawerReplyListByQuestionWithComment {...replyListByQuestionWithComment} onRequestClose={() => setReplyListByQuestionWithComment({ visible: false, data: null })} />
    </>
  );
}
