import React, { useEffect } from 'react';
import { Col, Form, FormInstance, Input, Radio, Row, Select, Upload, message, DatePicker } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { readAllAppHospitalHireOccupation } from '../../../../../api';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';

const { Option } = Select;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt20M = file.size / 1024 / 1024 < 20;
  if (!isLt20M) {
    message.error('Image must smaller than 20MB!');
  }
  return isJpgOrPng && isLt20M;
};

interface IProps {
  form: FormInstance;
  dataSource: any;
}

export default function CastingForm(props: IProps) {
  const { form, dataSource } = props;
  const [logoUrl, setLogoUrl] = React.useState<any>(null);
  const [detailUrl, setDetailUrl] = React.useState<any>(null);
  const [adUrl, setAdUrl] = React.useState<any>(null);
  const [loading] = React.useState(false);
  const [isFormReady, formReady] = React.useState(false);
  const [fields, setFields] = React.useState<any>({
    occupation: [],
  });

  const formRecruitId = Form.useWatch('recruitId', form);
  const formCareerMarkId = Form.useWatch('careerMarkId', form);
  const formSalaryNegoId = Form.useWatch('salaryNegoId', form);
  const formUseAd = Form.useWatch('useAd', form);
  const formUseTalkPremiumAd = Form.useWatch('useTalkPremiumAd', form);
  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 100);
  };
  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const handleChangeLogo: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    console.log(info.file);
    if (info.file.status === 'uploading') {
      //setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        // setLoading(false);
        setLogoUrl(url);
      });
    }
  };

  const handleChangeDetail: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    console.log(info.file);
    if (info.file.status === 'uploading') {
      //setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        // setLoading(false);
        setDetailUrl(url);
      });
    }
  };

  const handleChangeAd: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    console.log(info.file);
    if (info.file.status === 'uploading') {
      //setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        // setLoading(false);
        setAdUrl(url);
      });
    }
  };

  useEffect(() => {
    const bootstrap = async () => {
      try {
        //
      } catch (e) {
        console.log(e);
      } finally {
        if (dataSource.id > 0) {
          form.setFieldsValue({
            id: dataSource.id,
            hospitalId: dataSource.hospitalId,
            hospitalName: dataSource.hospitalName,
            title: dataSource.title,
            occupationIds: dataSource.occupationIds,
            dates: dataSource.recruitId === '1' ? [moment(dataSource.startDate), moment(dataSource.endDate)] : [null, null],
            recruitId: Number(dataSource.recruitId),
            careerMarkId: Number(dataSource.careerMarkId) ? dataSource.careerMarkId : 1,
            career: dataSource.careerMarkId && dataSource.careerMarkId == 1 ? dataSource.career : '',
            salaryNegoId: Number(dataSource.salaryNegoId),
            salary: dataSource.salaryNegoId === '1' ? dataSource.salary : '',
            useAd: dataSource.useAd,
            useTalkPremiumAd: dataSource.useTalkPremiumAd
          });
        }

        if (dataSource.id > 0) {
          setLogoUrl(process.env.REACT_APP_API_BASE_IMAGE_URL + dataSource.logoImageUrl);
          setDetailUrl(process.env.REACT_APP_API_BASE_IMAGE_URL + dataSource.detailImageUrl);
          if (dataSource.useAd) {
            setAdUrl(process.env.REACT_APP_API_BASE_IMAGE_URL + dataSource.adImageUrl);
          }
        } else {
          setLogoUrl('');
          setDetailUrl('');
          setAdUrl('');
        }
      }
    };
    if (isFormReady) {
      bootstrap();
    }
  }, [isFormReady, dataSource]);
  useEffect(() => {
    const bootstrap = async () => {
      try {
        formReady(false);
        const occupation = await readAllAppHospitalHireOccupation();
        setFields({ occupation });
      } catch (e) {
        console.log(e);
      } finally {
        formReady(true);
      }
    };

    bootstrap();
  }, []);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Form
      form={form}
      layout="vertical"
      name="form_casting_modal"
      initialValues={{
        title: '',
        occupationIds: [],
        dates: [null, null],
        recruitId: 1,
        career: '',
        careerMarkId: 1,
        salary: '',
        salaryNegoId: 1,
        useAd: 'N',
        useTalkPremiumAd: 'N'
      }}
    >
      <Form.Item name="title" label="공고제목" rules={[{ required: true, message: '공고제목을 입력해주세요.' }]}>
        <Input />
      </Form.Item>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="dates" label="공고기간" rules={[{ required: formRecruitId === 1, message: '공고기간을 입력해주세요.' }]}>
            <DatePicker.RangePicker disabled={formRecruitId > 1} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="recruitId" label="모집기간방식" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={1}>기간설정</Radio>
              <Radio value={2}>상시모집</Radio>
              <Radio value={3}>채용시</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="occupationIds" label="모집직종" rules={[{ required: true, message: '모집직종을 선택해주세요.', type: 'array' }]}>
        <Select mode="multiple">
          {fields.occupation.map((item: any) => {
            return (
              <Option key={item.id.toString()} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="career" label="경력여부" rules={[{ required: formCareerMarkId === 1, message: '경력을 입력해주세요' }]}>
            <Select disabled={formCareerMarkId > 1}>
              <Select.Option value="1년이상">1년이상</Select.Option>
              <Select.Option value="2년이상">2년이상</Select.Option>
              <Select.Option value="3년이상">3년이상</Select.Option>
              <Select.Option value="4년이상">4년이상</Select.Option>
              <Select.Option value="5년이상">5년이상</Select.Option>
              <Select.Option value="6년이상">6년이상</Select.Option>
              <Select.Option value="7년이상">7년이상</Select.Option>
              <Select.Option value="8년이상">8년이상</Select.Option>
              <Select.Option value="9년이상">9년이상</Select.Option>
              <Select.Option value="10년이상">10년이상</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="careerMarkId" label="경력조건" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={1}>경력선택</Radio>
              <Radio value={2}>신입</Radio>
              <Radio value={3}>경력무관</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="salary" label="연봉" rules={[{ required: formSalaryNegoId === 1, message: '연봉을 입력해주세요' }]}>
            <Input disabled={formSalaryNegoId > 1} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="salaryNegoId" label="연봉방식" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={1}>연봉입력</Radio>
              <Radio value={2}>추후협의</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="mainImage"
        label="로고"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: dataSource.id < 1 ? true : false,
            message: '로고를 등록해주세요',
          },
        ]}
      >
        <Upload
          name="mainImage"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChangeLogo}
          maxCount={1}
          customRequest={dummyRequest}
          onPreview={function handlePreview(file: UploadFile) { }}
        >
          {logoUrl ? <img src={logoUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
      </Form.Item>
      <Form.Item
        name="detailImage"
        label="상세(랜딩)이미지"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: dataSource.id < 1 ? true : false,
            message: '로고를 등록해주세요',
          },
        ]}
      >
        <Upload
          name="detailImage"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChangeDetail}
          maxCount={1}
          customRequest={dummyRequest}
          onPreview={function handlePreview(file: UploadFile) { }}
        >
          {detailUrl ? <img src={detailUrl} alt="avatar" style={{ width: '100%', maxHeight: 104 }} /> : uploadButton}
        </Upload>
      </Form.Item>
      <Form.Item label="광고사용/미사용" name="useAd" rules={[{ required: true }]}>
        <Radio.Group>
          <Radio value={'N'}>미사용</Radio>
          <Radio value={'Y'}>사용</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="게시글 프리미엄 광고 사용/미사용" name="useTalkPremiumAd" rules={[{ required: true }]}>
        <Radio.Group>
          <Radio value={'N'}>미사용</Radio>
          <Radio value={'Y'}>사용</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="adImage" label="광고(메인)이미지" valuePropName="fileList" getValueFromEvent={normFile} hidden={formUseAd === 'N' && formUseTalkPremiumAd === 'N'}>
        <Upload name="adImage" listType="picture-card" showUploadList={false} beforeUpload={beforeUpload} onChange={handleChangeAd} maxCount={1} customRequest={dummyRequest}>
          {adUrl ? <img src={adUrl} alt="avatar" style={{ width: '100%', height: '100%' }} /> : uploadButton}
        </Upload>
      </Form.Item>
    </Form>
  );
}
