import * as React from 'react';
import Page from '../../../components/Page';
import QuestionListTable from './features/QuestionListTable';
import QuestionListForm from './features/QuestionListForm';
import { readAllQuestion, readAllQuestionCommentReply } from '../../../api';
import { Form } from 'antd';
import moment from 'moment';

interface IResponse {
  page: number;
  total: number;
  items: [];
}
export default function QuestionCommentReplyPage() {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<IResponse>({
    page: 1,
    total: 0,
    items: [],
  });

  const onSearch = async (nextPage: number) => {
    try {
      setLoading(true);
      const nextFormValues = form.getFieldsValue();
      const startDate = moment(nextFormValues.dates[0]).format('YYYY-MM-DD');
      const endDate = moment(nextFormValues.dates[1]).format('YYYY-MM-DD');
      delete nextFormValues.dates;
      const nextResponse: any = await readAllQuestionCommentReply({
        ...nextFormValues,
        startDate: startDate === 'Invalid date' ? '' : startDate,
        endDate: endDate === 'Invalid date' ? '' : endDate,
        page: nextPage,
      });
      setResponse({
        page: nextPage,
        total: nextResponse.total,
        items: nextResponse.items,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    onSearch(1);
  }, []);

  return (
    <Page title="게시글 관리" subTitle="전체 답글 목록">
      <QuestionListForm form={form} searchLoading={loading} onRequestSearch={onSearch} />
      <QuestionListTable
        loading={loading}
        dataSource={response.items}
        pagination={{
          current: response.page,
          total: response.total,
          pageSize: 20,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: onSearch,
        }}
        onRequestRefresh={() => onSearch(response.page)}
      />
    </Page>
  );
}
