import * as React from 'react';
import { Modal, Table, Tag } from 'antd';
import { getTableColumnNo } from '../../../../components/Table';
import ResumeViewModal from '../ResumeViewModal';
import DrawerMessageList from '../DrawerMessageList';
import { readAllHospitalMessageByMember } from '../../../../api';

export default function MemberResumeListTable(props: any) {
  const { loading, dataSource, pagination } = props;
  const [modal, setModal] = React.useState({ visible: false, id: 0 });
  const [drawerMessageList, setDrawerMessageList] = React.useState<any>({
    visible: false,
    dataList: [],
  });
  const handleClickRequestView = (item: any) => {
    if (item.auditionViewCheck === 'N') {
      Modal.confirm({
        title: '열람권을 사용하여 열람하시겠습니까?',
        onOk: () =>
          setModal({
            visible: true,
            id: item.id,
          }),
      });
    } else {
      setModal({
        visible: true,
        id: item.id,
      });
    }
  };

  const handleClickOfferCreate = () => {};
  const handleClickMessageCount = async (item: any) => {
    try {
      console.log(item);
      const newItems: any = await readAllHospitalMessageByMember(item.id);
      setDrawerMessageList({
        visible: true,
        data: {
          memberId: item.id,
          name: item.nickName,
        },
        dataList: newItems,
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Table
        size="small"
        bordered={true}
        scroll={{ x: true }}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        rowKey="id"
        columns={[
          getTableColumnNo(pagination.total, 20, pagination.current),
          {
            title: '이름',
            dataIndex: 'nickName',
            ellipsis: true,
          },
          {
            title: '구직/재직',
            dataIndex: 'jobStatusName',
            width: 80,
            align: 'center',
          },
          {
            title: '희망연봉',
            dataIndex: 'hopeSalary',
            render(_, record) {
              if (record.salaryNegoId === 1) {
                return record.hopeSalary + '만원';
              } else if (record.salaryNegoId === 2) {
                return '추후협의';
              } else {
                return null;
              }
            },
          },
          {
            title: '경력',
            dataIndex: 'careerDay',
            render(value) {
              let str = '';
              if (Number(value) > 0) {
                let careerMonth = Number(value) / 30;
                if (careerMonth / 12 >= 1) {
                  str += `${(Number(careerMonth) / 12).toFixed(0)}년`;
                }

                if (careerMonth % 12 >= 1) {
                  str += `${(Number(careerMonth) % 12).toFixed(0)}개월`;
                }

                if (str === '') {
                  str = value + '일';
                }
              } else {
                return '없음';
              }
              return str;
            },
          },
          {
            title: '희망직종',
            dataIndex: 'occupationNames',
            key: 'occupationNames',
            render: (departmentNames: string, item) => {
              const names = departmentNames.split(',');
              return (
                <>
                  {names.map((name: string, idx: number) => (
                    <Tag key={`tag-${item.id}-${idx}`} color="blue">
                      {name}
                    </Tag>
                  ))}
                </>
              );
            },
          },
          {
            title: '쪽지',
            dataIndex: 'messageCount',
            render(_, record: any) {
              if (Number(record.messageCount) < 1) {
                return null;
              }

              return (
                <a href="#" onClick={() => handleClickMessageCount(record)}>
                  {record.messageCount}
                </a>
              );
            },
          },
          {
            title: '제안여부',
            dataIndex: 'offerStatus',
            render(value) {
              switch (value) {
                case 1:
                  return '거절';
                case 0:
                  return '제안완료';
                default:
                  return '제안하기';
              }
            },
          },
          {
            title: '열람권',
            render(_, record: any) {
              if (record.auditionViewCheck === 'Y') {
                return <a onClick={() => handleClickRequestView(record)}>{record.auditionViewRemainingPeriod}일남음</a>;
              }
              return <a onClick={() => handleClickRequestView(record)}>열람하기</a>;
            },
          },
        ]}
      />
      <ResumeViewModal {...modal} onClose={() => setModal({ visible: false, id: 0 })} />
      <DrawerMessageList {...drawerMessageList} onRequestClose={() => setDrawerMessageList({ ...drawerMessageList, visible: false })} />
    </>
  );
}
