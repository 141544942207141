import * as React from "react";
import { Switch } from "antd";
import { updateQuestionHot } from "../../../../../api";

export default function HotSwitch({ data, onChange }: any) {
  const [loading, setLoading] = React.useState(true);
  const handleChange = async (tttt: any) => {
    try {
      setLoading(true);
      const nextValue = tttt ? "Y" : "N";
      await updateQuestionHot(data.id, nextValue);
      onChange(nextValue);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setLoading(false);
  }, []);

  if (data.deletedAt === "") {
    return null;
  }
  return (
    <Switch
      size="small"
      loading={loading}
      onChange={handleChange}
      checked={data.isHot === "Y" ? true : false}
    />
  );
}
