import React from 'react';
import { Form, Modal, message } from 'antd';
import YoutubeForm from './YoutubeForm';
import { createCompany, createHospital, createYoutube, readCompanyByPK, readHospitalByPK, readYoutube, updateCompanyByPK, updateHospitalByPK, updateYoutube } from '../../../../api';

export type FormType = {
  id?: number;
  title: string;
  url: string;
};
interface IProps {
  visible: boolean;
  data: number;
  onFinish: () => void;
  onRequestClose: () => void;
}

const initialValues = {
  id: 0,
  title: '',
  url: '',
} as FormType;
export default function YoutubeFormModal(props: IProps) {
  const { visible, data, onRequestClose, onFinish } = props;
  const isUpdated = data && data > 0 ? true : false;
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [dataSource, setDataSource] = React.useState<FormType>(initialValues);
  const [form] = Form.useForm();

  const resetFormValue = () => {
    setDataSource(initialValues);
    form.resetFields();
  };
  const handleLoad = async (id: number) => {
    try {
      setConfirmLoading(true);
      const res: any = await readYoutube(id);
      setDataSource(res);
    } catch (e) {
      console.log(e);
    } finally {
      setConfirmLoading(false);
    }
  };
  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (data > 0) {
        await updateYoutube(values, data);
      } else {
        await createYoutube(values);
      }
      setConfirmLoading(false);
      message.info(`${isUpdated ? '수정' : '등록'} 되었습니다.`);
      onRequestClose();
      onFinish();
    } catch (e: any) {
      Modal.error({
        title: '검증오류',
        content: e.message,
        onOk() {
          onFinish();
        },
      });
      console.log(e);
    }
  };

  const handleCancel = () => {
    onRequestClose();
    resetFormValue();
  };

  React.useEffect(() => {
    if (visible) {
      form.resetFields();
      if (data > 0) {
        handleLoad(data);
      } else {
        resetFormValue();
      }
    }
  }, [visible, data]);

  return (
    <Modal visible={visible} confirmLoading={confirmLoading} title={`영상 정보 ${isUpdated ? '수정' : '등록'}`} okText={isUpdated ? '수정' : '등록'} cancelText="취소" onOk={handleOk} onCancel={handleCancel}>
      {visible && <YoutubeForm form={form} dataSource={dataSource} />}
    </Modal>
  );
}
