import * as React from 'react';
import Page from '../../../components/Page';
import AdListTable from './AdListTable';
import AdListForm from './AdListForm';
import { readAllCompanyAd } from '../../../api';
import { Form } from 'antd';
import moment from 'moment';
import AdFormModal from './AdFormModal';

interface IResponse {
  page: number;
  total: number;
  items: [];
}
export default function AdvertisementPage() {
  const [form] = Form.useForm();
  const [formModal, setFormModal] = React.useState<any>({
    visible: false,
    data: null,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<IResponse>({
    page: 1,
    total: 0,
    items: [],
  });

  const onSearch = async (nextPage: number) => {
    try {
      setLoading(true);
      const nextFormValues = form.getFieldsValue();
      let startDate = '';
      let endDate = '';
      if (nextFormValues.dates) {
        startDate = moment(nextFormValues.dates[0]).format('YYYY-MM-DD');
        endDate = moment(nextFormValues.dates[1]).format('YYYY-MM-DD');
      }
      delete nextFormValues.dates;
      const nextResponse: any = await readAllCompanyAd({
        ...nextFormValues,
        startDate: startDate === 'Invalid date' ? '' : startDate,
        endDate: endDate === 'Invalid date' ? '' : endDate,
        page: nextPage,
      });
      setResponse({
        page: nextPage,
        total: nextResponse.total,
        items: nextResponse.items,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    onSearch(1);
  }, []);

  return (
    <Page title="광고관리" subTitle="광고관리 리스트">
      <AdListForm form={form} searchLoading={loading} onRequestSearch={onSearch} onRequestCreate={() => setFormModal({ visible: true, data: null })} />
      <AdListTable
        loading={loading}
        dataSource={response.items}
        pagination={{
          current: response.page,
          total: response.total,
          pageSize: 20,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: onSearch,
        }}
        onRequestUpdate={(companyId, id) => setFormModal({ visible: true, data: { companyId, id } })}
        onRequestRefresh={() => onSearch(response.page)}
      />
      {formModal.visible && <AdFormModal {...formModal} onFinish={() => onSearch(response.page)} onRequestClose={() => setFormModal({ visible: false, data: null })} />}
    </Page>
  );
}
