import * as React from 'react';
import { Space, Button, Form, Select, Input, DatePicker } from 'antd';
import { readAllAppHospitalHireOccupation, readAllHospitalDepartment } from '../../../../api';
import locale from 'antd/es/date-picker/locale/ko_KR';
interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
}
export default function ListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch } = props;
  const [fields, setFields] = React.useState<any>({
    department: [],
    occupation: [],
  });
  const useAudition = Form.useWatch('useAudition', form);
  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };
  React.useEffect(() => {
    const bootstrap = async () => {
      try {
        const department = await readAllHospitalDepartment();
        const occupation = await readAllAppHospitalHireOccupation();
        setFields({
          department,
          occupation,
        });
      } catch (e) {
        console.log(e);
      }
    };

    bootstrap();
  }, []);
  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          occupationId: null,
          dates: ['', ''],
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="dates">
          <DatePicker.RangePicker locale={locale} allowClear />
        </Form.Item>
        <Form.Item name="hospitalName">
          <Input placeholder="병원명" allowClear />
        </Form.Item>
        <Form.Item name="title">
          <Input placeholder="공고제목" allowClear />
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
