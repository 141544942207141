import * as React from 'react';
import { Space, DatePicker, Input, Select, Button, Form } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { AD_POSITION_ID } from '../../../../service';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
  onRequestCreate: () => void;
}
export default function AdListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch, onRequestCreate } = props;

  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };
  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          title: '',
          dates: ['', ''],
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="dates">
          <RangePicker locale={locale} />
        </Form.Item>
        <Form.Item name="companyName">
          <Input placeholder="업체명" allowClear />
        </Form.Item>
        <Form.Item name="title">
          <Input placeholder="광고제목" allowClear />
        </Form.Item>
        <Form.Item name="isActive">
          <Select placeholder="상태" allowClear style={{ width: 120 }}>
            <Option value="Y">운영중</Option>
            <Option value="N">종료</Option>
          </Select>
        </Form.Item>
        <Form.Item name="positionId">
          <Select placeholder="광고항목" allowClear style={{ width: 120 }}>
            <Option value="">전체</Option>
            <Option value={AD_POSITION_ID.TALK}>언니톡 상단 배너</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={onRequestCreate}>
            광고등록
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
