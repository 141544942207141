import React from "react";
import styled from "styled-components";
import type { IInputItem } from "../../service/type";

const InputItem: React.FC<IInputItem> = ({ label, item }) => {
  return (
    <ItemWrap>
      <ItemLabel>{label}</ItemLabel>
      {item}
    </ItemWrap>
  );
};

const ItemWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const ItemLabel = styled.span`
  flex: 0 0 100px;
`;

export default InputItem;
