import React, { useEffect } from 'react';
import { Form, FormInstance, Input, Select } from 'antd';

interface IProps {
  form: FormInstance;
  dataSource: any;
}

export default function YoutubeForm(props: IProps) {
  const { form, dataSource } = props;
  const [isFormReady, formReady] = React.useState(false);

  useEffect(() => {
    const bootstrap = async () => {
      try {
        //
      } catch (e) {
        console.log(e);
      } finally {
        form.setFieldsValue({
          title: dataSource.title,
          url: dataSource.url,
        });
      }
    };
    if (isFormReady) {
      bootstrap();
    }
  }, [isFormReady, dataSource]);
  useEffect(() => {
    const bootstrap = async () => {
      try {
        formReady(false);
      } catch (e) {
        console.log(e);
      } finally {
        formReady(true);
      }
    };

    bootstrap();
  }, []);

  return (
    <Form form={form} layout="vertical" name="form_youtube_modal">
      <Form.Item name="title" label="제목" rules={[{ required: true, message: '영상제목을 입력해주세요.' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="url" label="영상URL" rules={[{ required: true, message: '영상URL를 입력해주세요.' }]}>
        <Input />
      </Form.Item>
    </Form>
  );
}
