import * as React from 'react';

export default function Page(props: any) {
  const { title, subTitle, children } = props;
  return (
    <div style={{ margin: '0px 24px', padding: '0px 24px' }}>
      <div className="ant-page-header-heading">
        <div className="ant-page-header-heading-left">
          <span className="ant-page-header-heading-title" title="게시물 관리">
            {title}
          </span>
          <span className="ant-page-header-heading-sub-title" title="게시물 리스트">
            {subTitle}
          </span>
        </div>
      </div>
      {children}
    </div>
  );
}
