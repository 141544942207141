//CORE
import { Button, message } from 'antd';
import React, { useEffect, useState, useReducer } from 'react';

//STYLE
import styled from 'styled-components';

//API
import { getAuditionDetail, readHospitalAuditionViewCount } from '../../../api';
import { useQuery, useQueryClient } from 'react-query';

//COMPONENTS
import { Card, Tag } from 'antd';
import { Modal, ModalInputItem as InputItem } from '../../../components';

//HELPER
import { imageUrl } from '../../../service';

//TYPE
import type { IAuditionDetailModal, TCareerItem, IAuditionDetailResponse } from '../../../service/type';

//STATE
import { useGlobalState } from '../../../contexts';

type TError = {
  statusCode: number;
  message: string;
  error: string;
};
const AuditionDetailModal: React.FC<IAuditionDetailModal> = ({ visible, onClose, id }) => {
  const queryClient = useQueryClient();
  const { globalDispatch: dispatch } = useGlobalState();

  const { data, isLoading, refetch } = useQuery<IAuditionDetailResponse, TError>(['getAuditionDetail', id], () => getAuditionDetail(id), {
    enabled: visible,
    retry: 0,
    onSuccess: async () => {
      const { auditionViewCount } = await readHospitalAuditionViewCount();
      queryClient.invalidateQueries('getAuditionList');
      dispatch({
        type: 'AUDITION_VIEW_COUNT',
        data: auditionViewCount,
      });
    },
    onError: (error) => {
      const { statusCode } = error;
      if (statusCode === 403) {
        message.warn('사용 가능한 열람권이 없어요.');
        onClose();
      }
    },
  });

  if (!data || isLoading) {
    return null;
  }

  return (
    <Modal title="지원서 열람" onClose={onClose} visible={visible} bodyStyle={{ width: '800px' }} buttons={[{ onClick: onClose, label: '닫기' }]}>
      <InputItem label="이름" item={<span>{data.name}</span>} />
      <InputItem label="연락처" item={<span>{data.phone}</span>} />
      <InputItem
        label="관심직종"
        item={
          <div>
            {data.occupations.map((item: string) => (
              <Tag color="blue" key={`detail-category-${id}-${item}`}>
                {item}
              </Tag>
            ))}
          </div>
        }
      />
      <InputItem
        label="진료과목"
        item={
          <div>
            {data.departments.map((item: string, index: number) => (
              <Tag color="magenta" key={index}>
                {item}
              </Tag>
            ))}
          </div>
        }
      />
      <InputItem label="희망연봉" item={<span>{data.hopeSalary}</span>} />
      <InputItem label="재직유무" item={<span>{data.jobStatusName}</span>} />
      <InputItem label="자기소개" item={<span>{data.introduce}</span>} />
      <InputItem
        label="경력사항"
        item={
          <div style={{ flex: 1 }}>
            {data.careers.map((item: TCareerItem, idx: number) => (
              <div key={idx.toString()} style={{ marginBottom: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CompanyName>{item.companyName}</CompanyName>
                  <Career>
                    {item.startDate} ~ {item.endDate}
                  </Career>
                </div>
                <div>
                  <span>주요직무 : {item.task}</span>
                </div>
                <div>
                  <span>연봉 : {item.salary}</span>
                </div>
              </div>
              // <div >

              //   {item.startDate}
              //   {item.endDate}
              //   {item.task}
              //   {item.salary}
              // </div>
            ))}
          </div>
        }
      />
    </Modal>
  );
};

const CompanyName = styled.h3`
  font-size: 17px;
  margin-right: 10px;
`;
const Career = styled.span`
  font-size: 13px;
  color: #777;
  letter-spacing: -1px;
`;
export default AuditionDetailModal;
