type TPositionId = {
  TALK: number;
  [key: string]: number;
};
export const AD_POSITION_ID: TPositionId = {
  TALK: 1,
} as const;

export const STORAGE_KEYS = {
  TOKEN: "@accessToken",
} as const;
