import * as React from 'react';
import { Dropdown, Menu, Modal, Table, Tooltip, Typography, message, Space, Switch, Image } from 'antd';
import { getTableColumnNo } from '../../../../components/Table';
import { deleteAd, modifyAdActiveState } from '../../../../api';

const { Link } = Typography;
interface IProps {
  loading: boolean;
  dataSource: any;
  pagination: {
    showSizeChanger: boolean;
    hideOnSinglePage: boolean;
    total: number;
    pageSize: number;
    current: number;
    onChange: (page: number) => void;
  };
  onRequestUpdate: (companyId: number, id: number) => void;
  onRequestRefresh: () => void;
}
export default function TalkListTable(props: IProps) {
  const { loading, dataSource, pagination, onRequestUpdate, onRequestRefresh } = props;
  const [imagePreview, setImagePreview] = React.useState({
    visible: false,
    url: '',
  });
  const handleRequestPreviewImage = (url: string) => {
    setImagePreview({ visible: true, url: url });
  };
  const handleChangeActive = async (companyId: number, id: number, value: string) => {
    try {
      await modifyAdActiveState(companyId, id, value);
      onRequestRefresh();
    } catch (e: any) {
      message.error(e.message);
      console.log(e);
    }
  };
  const handleClickRequestUpdate = (item: any) => {
    if (item.isActive === 'Y') {
      message.warning('광고 활성상태를 비활성화로 변경 후 수정해주세요.');
      return;
    }
    if (onRequestUpdate) {
      onRequestUpdate(item.companyId, item.id);
    }
  };
  const handleClickRequestDelete = (item: any) => {
    Modal.confirm({
      content: `선택하신 "${item.title}" 공고를 삭제하시겠어요?`,
      type: 'info',
      onOk: async () => {
        try {
          await deleteAd(item.companyId, item.id);
          onRequestRefresh();
          message.success(`삭제 완료!`);
        } catch {
          message.error(`삭제 실패 :(`);
        } finally {
          console.log('LOADING END');
        }
      },
    });
  };
  return (
    <>
      <Table
        rowKey={'id'}
        size="small"
        loading={loading}
        bordered={true}
        dataSource={dataSource}
        pagination={pagination}
        columns={[
          getTableColumnNo(pagination.total, 20, pagination.current),
          {
            title: '광고ID',
            dataIndex: 'id',
            key: 'id',
            render(value: number, record: any) {
              return (
                <Tooltip placement="topLeft" title="삭제 또는 수정하실 수 있는 메뉴가 나오십니다.">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => handleClickRequestUpdate(record)}>수정</Menu.Item>
                        <Menu.Item onClick={() => handleClickRequestDelete(record)}>삭제</Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>{value}</Space>
                    </a>
                  </Dropdown>
                </Tooltip>
              );
            },
          },
          {
            title: '등록일',
            dataIndex: 'createDate',
          },
          {
            title: '상태',
            dataIndex: 'isActive',
            key: 'isActive',
            render(value, record) {
              return <Switch size="small" checked={value === 'Y' ? true : false} onChange={(checked) => handleChangeActive(record.companyId, record.id, checked ? 'Y' : 'N')} />;
            },
          },
          {
            title: '시작일',
            dataIndex: 'startDateTime',
            key: 'startDateTime',
          },
          {
            title: '종료일',
            dataIndex: 'endDateTime',
            key: 'endDateTime',
          },
          {
            title: '업체명',
            dataIndex: 'companyName',
            key: 'companyName',
          },
          {
            title: '광고제목',
            dataIndex: 'title',
            key: 'title',
          },
          {
            title: '노출위치',
            dataIndex: 'positionName',
            key: 'positionName',
          },
          {
            title: '로고',
            dataIndex: 'mainImageURL',
            align: 'center',
            render(value) {
              if (value === '') {
                return null;
              }
              return <a onClick={() => handleRequestPreviewImage(value)}>미리보기</a>;
            },
          },
          {
            title: '랜딩',
            dataIndex: 'landingImageURL',
            align: 'center',
            render(value) {
              if (value === '') {
                return null;
              }
              return <a onClick={() => handleRequestPreviewImage(value)}>미리보기</a>;
            },
          },
          {
            title: '노출수',
            dataIndex: 'viewCount',
            key: 'viewCount',
          },
          {
            title: '클릭수',
            dataIndex: 'hitCount',
            key: 'hitCount',
          },
          {
            title: '클릭율',
            dataIndex: 'clickPercentage',
            key: 'clickPercentage',
            render: (_, item) => {
              let { viewCount, hitCount } = item;
              const _viewCount = Number(viewCount);
              const _hitCount = Number(hitCount);

              if (_hitCount === 0) {
                return '0%';
              } else {
                return `${((_hitCount / _viewCount) * 100).toFixed(2)}%`;
              }
            },
          },
        ]}
      />
      <Image
        width={200}
        style={{ display: 'none' }}
        src={process.env.REACT_APP_API_BASE_IMAGE_URL + imagePreview.url}
        preview={{
          visible: imagePreview.visible,
          src: process.env.REACT_APP_API_BASE_IMAGE_URL + imagePreview.url,
          onVisibleChange: (value) => {
            setImagePreview({ visible: false, url: '' });
          },
        }}
      />
    </>
  );
}
