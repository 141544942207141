import * as React from 'react';
import { Drawer, Modal, PageHeader, Table } from 'antd';
import { readAllYoutubeCommentReplyByYoutube } from '../../../../../../api';
import YoutubeStatusSelect from '../../../../../../components/YoutubeStatusSelect';

export default function DrawerReplyListByYoutube(props: any) {
  const { visible, data, onRequestClose } = props;
  const [modal] = Modal.useModal();
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any>([]);
  const handleBack = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const bootstrap = async () => {
    try {
      setLoading(true);
      const res = await readAllYoutubeCommentReplyByYoutube(data);
      setItems(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible) {
      bootstrap();
    }
  }, [visible]);

  return (
    <Drawer visible={visible} getContainer={false} width={'100%'} closable={false} style={{ position: 'absolute' }}>
      <PageHeader ghost={false} onBack={handleBack} title={`영상의 댓글 목록`}>
        <Table
          size="small"
          bordered={true}
          dataSource={items}
          loading={loading}
          pagination={false}
          columns={[
            {
              title: 'No',
              render(_, __, index: number) {
                return items.length - index;
              },
            },
            {
              title: '작성일자',
              dataIndex: 'createDate',
            },
            {
              title: '작성자',
              dataIndex: 'nickName',
            },
            {
              title: '내용',
              dataIndex: 'contents',
              ellipsis: true,
              width: 200,
            },
            {
              title: '신고',
              dataIndex: 'reportCount',
            },
            {
              title: '블라인드',
              render(_, record) {
                return (
                  <YoutubeStatusSelect
                    type="reply"
                    dataSource={{
                      youtubeId: record.youtubeId,
                      commentId: record.commentId,
                      replyId: record.id,
                      value: record.statusId,
                    }}
                    reportCount={record.reportCount}
                    onFinish={bootstrap}
                  />
                );
              },
            },
          ]}
        />
      </PageHeader>
    </Drawer>
  );
}
