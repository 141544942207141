import * as React from 'react';
import { Button, Drawer, Modal, PageHeader, Table } from 'antd';
import { readAllHospitalMessageByMember } from '../../../../api';
import MessageSendModal from '../../../MessageSendModal';

interface Props {
  visible: boolean;
  memberId: number;
  memberName: string;
  onRequestClose: () => void;
}
export const DrawerMessageListByMember: React.FC<Props> = ({ visible, memberId, memberName, onRequestClose }): JSX.Element => {
  const [sendModal, setSendModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any>([]);
  const handleBack = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };

  const handleClickSend = () => {
    setSendModal(true);
  };
  const bootstrap = async () => {
    try {
      setLoading(true);
      const res = await readAllHospitalMessageByMember(memberId);
      setItems(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible) {
      bootstrap();
    }
  }, [visible]);

  return (
    <>
      <Drawer visible={visible} getContainer={false} width={'100%'} closable={false} style={{ position: 'absolute' }}>
        <PageHeader
          ghost={false}
          onBack={handleBack}
          title={`${memberName}님과의 주고받은 쪽지 목록`}
          extra={[
            <Button key="1" type="primary" onClick={handleClickSend}>
              쪽지보내기
            </Button>,
          ]}
        >
          <Table
            size="small"
            bordered={true}
            dataSource={items}
            loading={loading}
            pagination={false}
            columns={[
              {
                title: '메세지일자',
                dataIndex: 'messageDate',
                width: 160,
                align: 'center',
              },
              {
                title: '메세지종류',
                dataIndex: 'typeId',
                render(value) {
                  if (value == 2) {
                    return '보냄';
                  } else {
                    return '받음';
                  }
                },
              },
              {
                title: '내용',
                dataIndex: 'content',
                width: 'auto',
              },
            ]}
          />
        </PageHeader>
      </Drawer>
      <MessageSendModal
        visible={sendModal}
        data={{ memberId: memberId, name: memberName }}
        onRequestClose={() => setSendModal(false)}
        onFinish={() => {
          setSendModal(false);
          bootstrap();
        }}
      />
    </>
  );
};
