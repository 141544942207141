import React from "react";
import { Result, Button } from "antd";

const NotFound: React.FC = () => {
  return (
    <section style={{ flex: 1 }}>
      <Result
        status="404"
        title="404"
        subTitle="존재하지 않는 페이지입니다."
        extra={<Button type="primary">돌아가기</Button>}
      />
    </section>
  );
};

export default NotFound;
