import React from 'react';
import { Form, Modal, message } from 'antd';
import HospitalUserPasswordForm from './HospitalUserPasswordForm';
import { updateHospitalUserPasswordByHospitalWithPK } from '../../../../api';

export type FormType = {
  newAccountPasswd: string;
  reAccountPasswd: string;
};
interface IProps {
  visible: boolean;
  data: {
    hospitalId: number;
    id: number;
  };
  onFinish: () => void;
  onRequestClose: () => void;
}

const initialValues = {
  newAccountPasswd: '',
  reAccountPasswd: '',
} as FormType;
export default function HospitalUserPasswordFormModal(props: IProps) {
  const { visible, data, onRequestClose, onFinish } = props;

  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [dataSource, setDataSource] = React.useState<FormType>(initialValues);
  const [form] = Form.useForm();

  const resetFormValue = () => {
    setDataSource(initialValues);
    form.resetFields();
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      await updateHospitalUserPasswordByHospitalWithPK(values, data.hospitalId, data.id);
      setConfirmLoading(false);
      message.info(`변경 되었습니다.`);
      onRequestClose();
      onFinish();
    } catch (e: any) {
      message.error(e.message);
    }
  };

  const handleCancel = () => {
    onRequestClose();
    resetFormValue();
  };

  React.useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Modal visible={visible} confirmLoading={confirmLoading} title={`병원 계정 패스워드 정보 변경`} okText={'변경'} cancelText="취소" onOk={handleOk} onCancel={handleCancel}>
      {visible && <HospitalUserPasswordForm form={form} dataSource={dataSource} />}
    </Modal>
  );
}
