import * as React from 'react';
import { Space, DatePicker, Input, Button, Form, Select } from 'antd';
import { readAllAppHospitalHireOccupation, readAllHospitalDepartment } from '../../../../api';

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
}
export default function MemberResumeListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch } = props;
  const [fields, setFields] = React.useState<any>({
    department: [],
    occupation: [],
  });
  const useAudition = Form.useWatch('useAudition', form);
  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };
  React.useEffect(() => {
    const bootstrap = async () => {
      try {
        const department = await readAllHospitalDepartment();
        const occupation = await readAllAppHospitalHireOccupation();
        setFields({
          department,
          occupation,
        });
      } catch (e) {
        console.log(e);
      }
    };

    bootstrap();
  }, []);
  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          useAudition: null,
          departmentId: null,
          occupationId: null,
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="useAudition">
          <Select placeholder="스카웃 사용여부" allowClear>
            <Select.Option value={'Y'}>스카웃 사용</Select.Option>
            <Select.Option value="N">스카웃 미사용</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="departmentId" hidden={useAudition === 'Y' ? false : true}>
          <Select placeholder="지원과" allowClear>
            {fields.department.map((item: any) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="occupationId" hidden={useAudition === 'Y' ? false : true}>
          <Select placeholder="모집분야" allowClear>
            {fields.occupation.map((item: any) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
