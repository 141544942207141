import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, Outlet } from 'react-router-dom';
import { Advertisement, Casting, Hospital, Login, Company, Talk, NotFound, HireListPage, Fobidden, TalkComment, TalkCommentReply } from '../pages';
import type { IJWT } from '../service/type';
import { isAuthenticated } from '../service';
import jwt_decode from 'jwt-decode';
import { useGlobalState } from '../contexts';
import TalkNoticePage from '../pages/admin/TalkNotice';
import MemberResumeListPage from '../pages/admin/MemberResumeListPage';
import HospitalHireResumeListPage from '../pages/admin/HospitalHireResumeListPage';
import HireResumeListPage from '../pages/hospital/HireResumeListPage';
import HospitalPage from '../pages/hospital/HospitalPage';
import HospitalUserListPage from '../pages/admin/HospitalUserListPage';
import ScoutPage from '../pages/hospital/ScoutPage';
import MessageReceiveListPage from '../pages/hospital/MessageReceiveListPage';
import MessageSendListPage from '../pages/hospital/MessageSendListPage';
import AdminMemberListPage from '../components/pages/admin/MemberListPage';
import YoutubePage from '../pages/admin/Youtube';
import YoutubeCommentPage from '../pages/admin/YoutubeComment';
import YoutubeCommentReplyPage from '../pages/admin/YoutubeCommentReply';
import QuestionPage from '../pages/admin/Question';
import QuestionCommentPage from '../pages/admin/QuestionComment';
import QuestionCommentReplyPage from '../pages/admin/QuestionCommentReply';

const PrivateRoute: React.FC<any> = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
};

const Router = () => {
  const {
    globalState: { token, userRole },
    globalDispatch: dispatch,
  } = useGlobalState();

  const { pathname } = useLocation();
  //const [userRole, setUserRole] = useState<TUserRole | string>("");

  useEffect(() => {
    (() => {
      if (userRole === '') {
        if (token !== '') {
          const { role }: IJWT = jwt_decode(token);
          dispatch({
            type: 'LOGIN',
            data: {
              token,
              userRole: role,
            },
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route index element={userRole === 'hospital' ? <HireListPage /> : <AdminMemberListPage />} />
        <Route path="members" element={<AdminMemberListPage />} />
        <Route path="members/resumes" element={<MemberResumeListPage />} />
        <Route path="hospital" element={<HospitalPage />} />
        <Route path="hospitals" element={<Hospital />} />
        <Route path="hospitals/users" element={<HospitalUserListPage />} />
        <Route path="hospitals/hires" element={<Casting />} />
        <Route path="hospitals/hires/resumes" element={<HospitalHireResumeListPage />} />
        <Route path="ads" element={<Advertisement />} />
        <Route path="companies" element={<Company />} />
        <Route path="talks/notices" element={<TalkNoticePage />} />
        <Route path="hires" element={<HireListPage />} />
        <Route path="hires/resumes" element={<HireResumeListPage />} />
        <Route path="scount" element={<ScoutPage />} />
        <Route path="talks" element={<Talk />} />
        <Route path="talks/comments" element={<TalkComment />} />
        <Route path="talks/comments/replies" element={<TalkCommentReply />} />
        <Route path="questions" element={<QuestionPage />} />
        <Route path="questions/comments" element={<QuestionCommentPage />} />
        <Route path="questions/comments/replies" element={<QuestionCommentReplyPage />} />
        <Route path="messages/receive" element={<MessageReceiveListPage />} />
        <Route path="messages/send" element={<MessageSendListPage />} />
        <Route path="youtubes" element={<YoutubePage />} />
        <Route path="youtubes/comments" element={<YoutubeCommentPage />} />
        <Route path="youtubes/comments/replies" element={<YoutubeCommentReplyPage />} />
      </Route>

      <Route path="login" element={<Login />} />
      <Route path="fobidden" element={<Fobidden />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
