import React, { useCallback, useEffect, useState } from 'react';
import { Layout as Frame, Menu, Button, Modal, MenuProps } from 'antd';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router';
import { MenuInfo } from 'rc-menu/lib/interface';
import { LogoutOutlined } from '@ant-design/icons';

//ASSETS
import logo from '../../assets/images/logo.png';

//API
import { readHospital, readHospitalAuditionViewCount } from '../../api';

//STATE
import { useGlobalState } from '../../contexts';

const { Header, Sider, Content } = Frame;

const adminItems: MenuProps['items'] = [
  {
    key: '/',
    label: '처음화면',
  },
  {
    type: 'group',
    key: 'members',
    label: '회원',
    children: [
      {
        key: '/members',
        label: '회원정보관리',
      },
      {
        key: '/members/resumes',
        label: '회원이력서관리',
      },
    ],
  },
  {
    type: 'group',
    key: 'hospitals',
    label: '병원',
    children: [
      {
        key: '/hospitals',
        label: '병원정보관리',
      },
      {
        key: '/hospitals/users',
        label: '병원계정정보관리',
      },
      {
        key: '/hospitals/hires',
        label: '병원별공고관리',
      },
      {
        key: '/hospitals/hires/resumes',
        label: '공고별이력서관리',
      },
    ],
  },
  {
    type: 'group',
    key: 'talks',
    label: '게시글',
    children: [
      {
        key: '/talks/notices',
        label: '공지사항관리',
      },
      {
        key: '/talks',
        label: '게시글정보관리',
      },
      {
        key: '/talks/comments',
        label: '게시글별댓글관리',
      },
      {
        key: '/talks/comments/replies',
        label: '댓글별답글관리',
      },
    ],
  },
  {
    type: 'group',
    key: 'questions',
    label: '언니! 나 질문',
    children: [
      {
        key: '/questions',
        label: '질문정보관리',
      },
      {
        key: '/questions/comments',
        label: '질문별댓글관리',
      },
      {
        key: '/questions/comments/replies',
        label: '댓글별답글관리',
      },
    ],
  },
  {
    type: 'group',
    key: 'ads',
    label: '광고관리',
    children: [
      {
        key: '/ads',
        label: '광고정보관리',
      },
      {
        key: '/companies',
        label: '광고업체관리',
      },
    ],
  },
  {
    type: 'group',
    key: 'video',
    label: '영상관리',
    children: [
      {
        key: '/youtubes',
        label: '영상정보관리',
      },
      {
        key: '/youtubes/comments',
        label: '영상별댓글관리',
      },
      {
        key: '/youtubes/comments/replies',
        label: '영상별답글관리',
      },
    ],
  },
];

const hospitalItems: MenuProps['items'] = [
  {
    key: '/',
    label: '처음화면',
  },
  {
    type: 'group',
    key: 'hospital',
    label: 'General',
    children: [
      {
        key: '/hospital',
        label: '병원정보',
      },
    ],
  },
  {
    type: 'group',
    key: 'hires',
    label: '채용공고',
    children: [
      {
        key: '/hires',
        label: '채용공고관리',
      },
      {
        key: '/hires/resumes',
        label: '신청한 이력서 관리',
      },
      {
        key: '/scount',
        label: '스카웃',
      },
    ],
  },
  {
    type: 'group',
    key: 'messages',
    label: '쪽지함',
    children: [
      {
        key: '/messages/receive',
        label: '받은 쪽지함'
      },
      {
        key: '/messages/send',
        label: '보낸 쪽지함'
      }
    ]
  }
];
const Layout: React.FC = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isLayoutless, setIsLayoutless] = useState<boolean>(false);
  const [openKeys, setOpenkeys] = useState<Array<string>>([]);
  const [selectedKey, setSelectedKey] = React.useState<string>('');
  const {
    globalState: { hospitalName, userRole, remainingViewCount },
    globalDispatch: dispatch,
  } = useGlobalState();

  const location = useLocation();
  const navigate = useNavigate();

  const isAdmin = userRole === 'admin';
  const isHospital = userRole === 'hospital';

  const handleLogout = () =>
    Modal.confirm({
      content: '로그아웃 하시겠습니까?',
      type: 'info',
      onOk() {
        localStorage.clear();
        sessionStorage.clear();
        dispatch({
          type: 'RESET',
        });
        navigate('/login');
      },
    });

  const handleClick = ({ key }: MenuInfo) => {
    if (selectedKey !== key) {
      navigate(key);
    }
  };

  const getUserData = useCallback(async () => {
    if (userRole === 'hospital') {
      if (!hospitalName || hospitalName === '') {
        const { name }: any = await readHospital();
        dispatch({
          type: 'HOSPITAL_NAME',
          data: name,
        });
      }
      if (!remainingViewCount) {
        const { auditionViewCount } = await readHospitalAuditionViewCount();
        dispatch({
          type: 'AUDITION_VIEW_COUNT',
          data: auditionViewCount,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  const getMenuItems = () => {
    if (isAdmin) {
      return adminItems;
    }

    if (isHospital) {
      return hospitalItems;
    }

    return [];
  };
  useEffect(() => {
    const { pathname } = location;
    const splitPathName = pathname.split('/');
    splitPathName.splice(0, 1);
    setOpenkeys(splitPathName);
    setSelectedKey(pathname);

    if (pathname === '/login') {
      setIsLayoutless(true);
    } else {
      setIsLayoutless(false);
    }
  }, [location]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  if (isLayoutless) {
    return <>{children}</>;
  }

  return (
    <Frame>
      <Sider width={240}>
        <Logo>
          {!isCollapsed && <LogoImage src={logo} alt="언니네 병원 로고" title="언니네병원" style={{ width: 32, height: 32, marginRight: 16 }} />}
          <span style={{ color: '#000' }}>언니네 병원</span>
        </Logo>
        <div style={{ height: 'calc(100vh - 64px)', overflowY: 'auto', backgroundColor: '#FFF' }}>
          <Menu className="aside-container" theme="light" mode="inline" openKeys={openKeys} selectedKeys={[selectedKey]} onOpenChange={setOpenkeys} items={getMenuItems()} onClick={handleClick} />
        </div>
      </Sider>
      <Frame>
        <Header id="header">
          <HeaderRight>
            {userRole === 'hospital' && (
              <UserInfoWrap>
                <span>
                  {hospitalName} 관리자님 / 남은 열람권 : {remainingViewCount}
                </span>
              </UserInfoWrap>
            )}
            <Button type="text" onClick={handleLogout}>
              <LogoutOutlined style={{ color: '#000' }} />
            </Button>
          </HeaderRight>
        </Header>
        <Content className="main-wrapper">{children}</Content>
      </Frame>
    </Frame>
  );
};

const Logo = styled.h1`
  color: #fff;
  padding-left: 24px;
  height: 64px;
  line-height: 64px;
  margin-bottom: 0;
  background-color: #fff;
`;

const LogoImage = styled.img`
  width: 120px;
`;
const UserInfoWrap = styled.div`
  padding-right: 10px;
  color: #000;
`;

const HeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default Layout;
